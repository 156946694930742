import { useState, useEffect } from 'react';
import { Drawer, Box, Typography, Checkbox, Button } from '@mui/material';
import DataTable from '../Tables/DataTable';
import { useContactos } from '../../hooks';
const CheckboxStyles = {
    '&.Mui-checked': {
        color: '#c4af6c',
    },
    '& .MuiSvgIcon-root': {
        color: '#33383b',
        fontSize: 28
    },
    '&:hover': {
        backgroundColor: 'rgba(196, 175, 108, 0.04)',
    },
};
const DrawerContactos = ({ open, setOpen, setContacto, contacto, toSave,setColaborador, setPrescriptor, sucursal }) => {
    const { loading, data, fetchData } = useContactos();
    const [currentPage, setCurrentPage] = useState(1);
    const [filtros, setFiltros] = useState({id_sucursal:sucursal?.id});
    
    useEffect(()=> {
        if (sucursal?.id) {
            setFiltros({ sucursal_id: sucursal?.id });
        }
    },[sucursal?.id]);
    const headers = [
        {
            checkCli: 'checkCli',
            numeric: false,
            disabledPadding: false,
            label: '',
            overrideFunc: (data, row) => (
                <>
                    {console.log(contacto)}
                    <Checkbox
                        sx={CheckboxStyles}
                        onChange={(e) => addContacto(e, row)}
                        checked={contacto?.id === row?.id}
                    />
                </>
            ),
        },
        {
            numero_identificacion: 'numero_identificacion',
            numeric: false,
            disabledPadding: false,
            label: 'Nif',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.numero_identificacion}
                </Typography>
            )
        },
        {
            nombre: 'nombre',
            numeric: false,
            disabledPadding: false,
            label: 'Nombre',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.nombre}
                </Typography>
            )
        },
        {
            apellidos: 'apellidos',
            numeric: false,
            disabledPadding: false,
            label: 'Apellidos',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.apellidos}
                </Typography>
            )
        },
        {
            telefono: 'telefono',
            numeric: false,
            disabledPadding: false,
            label: 'Teléfono',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.telefono}
                </Typography>
            )
        },
        {
            email: 'email',
            numeric: false,
            disabledPadding: false,
            label: 'Email',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.email}
                </Typography>
            )
        },
    ];
    const addContacto = (e, con) => {
        const { checked } = e.target;
        console.log(toSave)
        console.log(con);

        setContacto(prevState => {
            if(checked){
                return {
                    ...prevState,
                    [toSave]:[con]
                };
            } else{
                return {
                    ...prevState,
                    [`${toSave}`]: (prevState[`${toSave}`] || []).filter(item => item !== con) 
                }
            }
        });
    };
    return (
        <>
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)} sx={{zIndex:2000}}>
                <div className='container'>
                    <Box component="section" sx={{ p: 3 }}>
                        <h2>Contactos</h2>
                        <p>Debes Seleccionar un Contacto</p>
                    </Box>
                </div>
                <div className='middleline'>
                    <DataTable
                        headers={headers}
                        data={data}
                        isLoading={loading}
                        filtros={filtros}
                        fetcher={fetchData}
                        mensaje={"No hay contactos disponibles"}
                    />
                </div>
            </Drawer>
        </>
    );
}

export default DrawerContactos;