//Obtiene una url lista para visualizar la imagen desde la url de la base de datos
export const getUrlFromString = (imgStringPath,responseHost) => {
    const { baseURL } = responseHost.config;
    const urlServer = baseURL.replace(/\/api\/?$/, '');
    const urlImg = imgStringPath && urlServer+imgStringPath;
    return urlImg;

}

//Obtiene una url lista para visualizar la imagen desde una File Image
export const getUrlFromFile = (FileImg) => {
    return URL.createObjectURL(FileImg)
}