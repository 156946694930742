import { useState } from "react";
import {
    TextField,
    InputAdornment,
    IconButton,
    Button,
    Box
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const HojaPropiedadForm = ({ propiedad, handleChange, handleBlur, inputStyles, index, formatNumbers, iconButtonStyles, cleanProps }) => {
    const [formattedImporte, setFormattedImporte] = useState(propiedad.importe ?? propiedad.precioinmo ?? '');

    const handleChangeWrapper = (e) => {
        handleChange(e, propiedad, index);
    };
    const handleBlurWrapper = () => {
        handleBlur(propiedad, index);

        const importe = !isNaN(parseFloat(propiedad.importe)) ? parseFloat(propiedad.importe) : null;
        const precioInmo = !isNaN(parseFloat(propiedad.precioinmo)) ? parseFloat(propiedad.precioinmo) : null;

        // Usa el primer valor numérico disponible
        const valueToFormat = importe !== null ? importe : precioInmo;
        
        if (valueToFormat !== null) {
            const formattedValue = valueToFormat.toLocaleString('de-DE');
            
            setFormattedImporte(formattedValue);
        } else {
            setFormattedImporte(''); // Limpia el estado si no hay un número válido
        }
        
    };
    const handleImporteFocus = () => {
        setFormattedImporte('');
    };
    return (
        <Box display="flex" alignItems="center" sx={{ margin: '5px 0' }}>
            <Box sx={{ flexGrow: 1 }}>
                <TextField
                    onChange={handleChangeWrapper}
                    label="Dirección"
                    size="small"
                    name={`direccion_propiedad_${index}`}
                    value={propiedad.direccion ?? propiedad.rHojafPropiedad?.direccion}
                    sx={{ ...inputStyles, maxWidth: '82%', margin: '5px 5px' }}
                    fullWidth
                />
                <TextField
                    onChange={handleChangeWrapper}
                    label="Población"
                    size="small"
                    name={`municipio_propiedad_${index}`}
                    value={propiedad.municipio}
                    sx={{ ...inputStyles, maxWidth: '30%', margin: '5px 5px' }}
                    fullWidth
                />
                <TextField
                    onChange={handleChangeWrapper}
                    label="CP"
                    size="small"
                    name={`cp_propiedad_${index}`}
                    value={propiedad.cp ?? propiedad.rHojafPropiedad?.cp}
                    sx={{ ...inputStyles, maxWidth: '15%', margin: '5px 5px' }}
                    fullWidth
                />
                <TextField
                    onChange={handleChangeWrapper}
                    label="Promoción"
                    size="small"
                    name={`promocion_propiedad_${index}`}
                    value={propiedad.promocion ?? propiedad.rHojafPropiedad?.promocion}
                    sx={{ ...inputStyles, maxWidth: '34%', margin: '5px 5px' }}
                    fullWidth
                />
                <TextField
                    onChange={handleChangeWrapper}
                    onBlur={handleBlurWrapper}
                    onFocus={handleImporteFocus}
                    label="Importe"
                    size="small"
                    name={`importe_propiedad_${index}`}
                    value={formattedImporte || propiedad.importe || propiedad.precioinmo}
                    sx={{ ...inputStyles, maxWidth: '34%', margin: '5px 5px' }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment sx={{ paddingLeft: '1%' }} position="end">€</InputAdornment>
                        )
                    }}
                    fullWidth
                />
            </Box>
            <Box sx={{marginLeft: '5px', marginBottom:'17%'}}>
                <Button sx={{ ...iconButtonStyles}} onClick={() => {cleanProps(index)}} size="small">
                    <DeleteIcon />
                </Button>
            </Box>
        </Box>
    );
}

export default HojaPropiedadForm;