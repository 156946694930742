import axios from "axios";
const urlPath = "trabajadores";

export const listTrabajadores = async (data) => {
    try {
        const response = await axios.post(`${urlPath}/list`, data.filtros, {
            params: data.params,
        });
        // console.log(response.data);
        return response.data;
    } catch (error) {

        console.log(error);
    }
};

export const getTrabajador = async ( id ) => {
    try {
        const response = await axios.get(`${urlPath}/${id}`);
        console.log(response);
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
};
export const createTrabajador = async ( data ) => {
    try {
    
        const response = await axios.post(`${urlPath}`, data,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        console.log(response)
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
};
export const updateTrabajador = async (id, data) => {
    try {
        console.log("join updateTrabaador");
        const response = await axios.put(`${urlPath}/${id}`, data,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        console.log(response);
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
};
export const updateTrabajadorProperty = async (id, data) => {
    try {

        const response = await axios.patch(`${urlPath}/${id}`, data);
        console.log(response);
        return response.status;
    } catch (error) {
        console.log(error);
        return error;
    }
};
export const deleteTrabajador = async (id) => {
    try {
        const response = await axios.delete(`${urlPath}/${id}`);
        // console.log(response);
        return response.status;
    } catch (error) {
        console.log(error);
        return error;
    }
};

export const resendMailVerification = async (data) =>{
    try {
        const response = await axios.post(`auth/reenviarVerificacion`, data);
        console.log(response)
        return response.status;
    } catch (error) {
        console.log(error);
        return error;
    }
}
export const sendMailRecoverPass = async (data) =>{
    try {
        const response = await axios.post(`auth/recoverPass`, data);
        console.log(response)
        console.log(response.status)
        return response.status;
    } catch (error) {
        console.log(error);
        return error;
    }
}