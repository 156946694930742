import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/navbar/Breadcrumb";
import {
  articleCreate,
  articleDetails,
  listDestinations,
  listLanguages,
  listsCategories,
  articleImage,
  articleUpdate
} from "../../services";
import { Accordion, Button, Form, InputGroup } from "react-bootstrap";
import TrendsForm from "../../components/Forms/TrendsForm";
import DragNDrop from "../../components/DragNDrop/DragNDrop";
import { isEmpty, toNumber } from "lodash";
import { TrashFill } from "react-bootstrap-icons";
import { SnackbarUtilities } from "../../utilities";
import { useParams } from "react-router-dom";


function CreateTrend() {
  
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedDestination, setSelectedDestination] = useState("");
  const [translationsLoaded, setTranslations ] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [articleToEdit, setArticleToEdit] = useState({});
  const [baseURL,setBaseUrl] = useState(null);
  // const URL_API = 'https://api2.dev.laehomes.com'
  // const URL_API = 'http://localhost:4001'


  const fetchCategories = async () => {
    try {
      const response = await listsCategories({ offset: 1, limit: 1000 });
      console.log(response)
      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLanguages = async () => {
    try {
      const response = await listLanguages({ offset: 1, limit: 1000 });
      console.log(response.data);
      setLanguages(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDestinations = async () => {
    try {
      const response = await listDestinations({ offset: 1, limit: 1000 });

      setDestinations(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchArticle = async () => {
    const response = await articleDetails(id);
    const { title, img, translations, category, destination, destination_id } = response.data.data;
    // console.log(translations);
    const { baseURL } = response.config;
    // console.log(response);
    // console.log(response.data);
    // console.log(baseURL);
    fetchComposeTranslation(translations);
    // const urlServer = baseURL.replace(/\/api$/, '');
    // const urlServer = baseURL.replace("/api/", "");
    const urlServer = baseURL.replace(/\/api\/?$/, '');

    // let newUrl = url.replace("/api/", "/");

    console.log(urlServer);
    setBaseUrl(urlServer)

    const imgURL = `${img}`;
    // const imgURL = `${urlServer}`;
    // console.log(img)
    // const image = await articleImage(urlServer);
    // console.log(image);
    // console.log(trimmedBaseURL);
    // console.log(translations);

    setFormData({
      title : title,
      img : imgURL,
      translations: translations,
      category : category,
      destination : destination,
      destination_id : destination_id
    });
    if(img!=='' || img !== null){
      // console.log("entro al iffffffff");
      setImageFile(imgURL);
    }
    setSelectedCategory(category.id);
    setSelectedDestination(destination_id);
    
  };

  
  // console.log(imageFile);
  const fetchComposeTranslation = async (translations) => {
    const translationsToJSON = {};
  
    await Promise.all(
      translations.map(async (t) => {
        const translationData = {
          title: t.title,
          id_idioma: t.id_idioma,
          content: t.content,
          slug: t.slug,
          id: t.id,
        };
    
        translationsToJSON[t.id_idioma] = translationData;
      })
    );
    setTranslations(translationsToJSON);
  //  console.log(translationsToJSON);
  };
  
  const handlePostTrend = async () => {
    const { title, ...rest } = formData;
    // console.log(formData);
    const translations = [];

    Object.keys(rest).forEach((key) => {
      translations.push({
        id_idioma: toNumber(key),
        title: rest[key]?.title,
        slug: rest[key]?.slug,
        content: rest[key]?.content,
      });
    });

    const dataToSend = new FormData();
   
    dataToSend.append("title", title);
    dataToSend.append("category_id", selectedCategory);
    dataToSend.append("destination_id", toNumber(selectedDestination));
    dataToSend.append("img", imageFile);
    dataToSend.append("translations", JSON.stringify(translations));
  //   for (let pair of dataToSend.entries()) {
  //     console.log(pair[0] + ': ' + pair[1]);
  // }
    
    try {
      const response = await articleCreate(dataToSend);
      // console.log(response.status)
      if(response.status == 201){
        SnackbarUtilities.success("Artículo creado con éxito");
        setTimeout(() => {
          window.location.href = '/Articulos';
        }, 2000);
      }
      
    } catch (error) {
      console.log(error);
      SnackbarUtilities.error("Error al crear el artículo");
    }
  };
  
  const handlePutTrend = async () => {
    // console.log(formData);
    // formData.translations = Object.values(articleToEdit);
    // console.log(articleToEdit);
    const { title, ...rest} = formData;
    // console.log(formData);
    // const modifiedArraytranslations = formData.translations.map(({ language, creator, editor, ...rest }) => rest);

    // console.log(rest);
    const dataToSend = new FormData();
    // console.log(dataToSend);
    dataToSend.append("title", title);
    dataToSend.append("category_id", selectedCategory);
    dataToSend.append("destination_id", toNumber(selectedDestination));
    dataToSend.append("img", imageFile);
    // if (typeof(imageFile)=="object") {
    //   console.log("no es null, la imagen no se modifico");
    //   dataToSend.append("img", imageFile);
    // }

    dataToSend.append("translations", JSON.stringify([articleToEdit?.ES,articleToEdit?.GB]));
    
  //   for (let pair of dataToSend.entries()) {
  //     console.log(pair[0] + ': ' + pair[1]);
  // }
    
  
  try {
    // console.log(dataToSend);
      const response = await articleUpdate(id, dataToSend);
      if( response == 200)
        SnackbarUtilities.success("Artículo editado con éxito");
        setTimeout(() => {
          window.location.href = '/Articulos';
        }, 2000);
    } catch (error) {
      console.log(error);
      SnackbarUtilities.error("Error al Editar el artículo");
    }

  };

  useEffect(() => {
    fetchLanguages();
    fetchDestinations();
    fetchCategories();
    if(id){
      fetchArticle();
    }
   
  }, []);

  useEffect(()=>{
    if (typeof(imageFile)=="object") {
      console.log(imageFile);

    }
  },[imageFile])

  return (
    <div className="container">
      {/* <BreadCrumb /> */}

      <div className="top-line">
        <div style={{ textAlign: "start" }}>
          <h3>{id !== undefined ? 'Editar' : 'Crear'} Artículo</h3>
        </div>
      </div>
      {/* <img src={`http://localhost:4001${formData.img}`}/> */}
      <div className="middleline">
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <div className="d-flex gap-2">
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Titulo del Artículo"
                defaultValue={formData.title}
                onChange={({ target }) =>
                  setFormData({ ...formData, title: target.value })
                }
              />
            </InputGroup>
            <Form.Select
              placeholder="Categoría"
              size="sm"
              style={{ maxHeight: "38px" }}
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Selecciona una categoría</option>
              {categories?.length &&
                categories.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.name}
                  </option>
                ))}
            </Form.Select>

            <Form.Select
              placeholder="Destino"
              size="sm"
              style={{ maxHeight: "38px" }}
              value={selectedDestination}
              onChange={(e) => setSelectedDestination(e.target.value)}
            >
              <option value="">Selecciona un destino</option>
              {destinations.length &&
                destinations.map((dest) => (
                  <option key={dest.id} value={dest.id}>
                    {dest.destination}
                  </option>
                ))}
            </Form.Select>
          </div>

          {imageFile ? (
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100px",
                marginBottom: "80px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  right: 10,
                  top: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setImageFile(null);
                  setFormData({ ...formData, img: null });
                }}
              >
                <TrashFill color="red" height={24} width={24} />
              </div>
              <img
                src={typeof imageFile === 'string' ? baseURL+imageFile : URL.createObjectURL(imageFile)}
                alt="imagen"
                style={{
                  objectFit: "cover",
                  height: "160px",
                  width: "100%",
                }}
              />
            </div>
          ) : (
            
            <DragNDrop
              onUpload={(files) => setImageFile(files)}
              count={1}
              formats={["jpg", "png", "svg", "jpeg"]}
              label={<p variant="caption">Imagen Artículo</p>}
              title='"Suelta un archivo aqui..."'
              openDialogOnClick={true} 
              style={{ marginBottom: "20px" }}
            />
          )}

          <Accordion>
            {languages.length &&
              languages.map((lang, index) => (
                <TrendsForm
                  key={lang.id}
                  eventKey={index}
                  langId={lang.id}
                  title={lang.name}
                  languageCode={lang.iso_code}
                  articleData={translationsLoaded}
                  onChange={(data) =>{
                    if(id){
                      setArticleToEdit((prev) => ({...prev, [lang.iso_code]: data}))
                    }else{
                      setFormData((prev) => ({ ...prev, [lang.id]: data }))}
                    }
                  }
                />
              ))}
          </Accordion>
          <Button onClick={id ? handlePutTrend : handlePostTrend}>Enviar</Button>
        </div>
      </div>
    </div>
  );
}

export default CreateTrend;
