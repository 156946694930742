import React from 'react'
import { BarChart } from '@mui/x-charts/BarChart';


export default function BarrasGraficoMultiColor({ series, xLabels }) {
    //console.log(series, xLabels);

    return (
        <BarChart
            width={580}
            height={400}
            series={series}
            margin={{ top: 10, bottom: 30, left: 100, right: 10 }}
            xAxis={[{
                data: xLabels, scaleType: 'band'
            }]}
            slotProps={{
                legend: {
                    direction: 'column',
                    position: { vertical: 'top', horizontal: 'middle' },
                    padding: 0,
                    labelStyle: {
                    },
                    hidden: true
                },
            }}
        />
    )
}
