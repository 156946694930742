import React, { useContext, useState, useEffect } from "react";
import ButtonPermission from "../../utilities/ButtonPermission.jsx";
import { Edit, Close, Check, Delete, NoteAdd } from '@mui/icons-material/';
import { SnackbarUtilities } from "../../utilities";
import { useFormValidation } from "../../utilities/formValidator";
import * as Yup from 'yup';
import { listSucursales } from "../../services/sucursales.service.js";
import { DataTable } from "../../components";
import { dynamicTableHeader } from "../../utilities/transformHeaders.js";
import useComisiones from "../../hooks/useComisiones.jsx";
import {
    Button, TextField, Box, MenuItem
} from "@mui/material";
import { listPerfiles } from "../../services/perfiles.service.js";
import { FaSearch } from "react-icons/fa";
import { MdCleaningServices } from "react-icons/md";
import ExtraTableData from "./ExtraTableData.jsx";
import { useUser } from "../../hooks/UserContextLogin.js";
import ResumenComisiones from "./ResumenComisiones.jsx";


export default function Comisiones() {

    const [sucursalMap, setSucursalMap] = useState(null);
    const [perfilesList, setPerfilesList] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const { loading, limite, data, fetchData, dataCustomNames } = useComisiones();
    const { user, setUser } = useUser();
    const [filtro, setFiltro] = useState({ sucursal_id: user?.rol?.permisos?.customViews['Comisiones']?.includes('View Oficinas') ? user?.usuarioDatos?.sucursal_id : null, comision: true });

    const initialValues = {
        fechaDesde: '',
        fechaHasta: '',
        sucursal_id: user?.rol?.permisos?.customViews['Comisiones']?.includes('View Oficinas') ? user?.usuarioDatos?.sucursal_id : '',
    };

    const validationSchema = Yup.object().shape({
        fechaDesde: Yup.string()
            .nullable(),
        fechaHasta: Yup.string()
            .nullable(),
        sucursal_id: Yup.string().nullable(),
    });

    // Usa la función de validación personalizada
    const { values, errors, touched, handleChange, handleReset, handleBlur, handleSubmit, setCustomFieldValue, setValues } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        nullConverter: true,
        onSubmit: async (values) => {
            console.log(values);
            let { fechaDesde, fechaHasta, ...newValues } = values;
            if (fechaDesde && fechaHasta) {
                newValues.fecha = {
                    fechaDesde,
                    fechaHasta,
                };
            }
            newValues = { ...filtro, ...newValues }

            if (newValues) {
                fetchData({ offset: currentPage, limit: 1000, filtros: newValues });
            } else {
                SnackbarUtilities.error("Error en algun validador del campo");
            }
        },
    });

    const fetchSucursales = async () => {
        const response = await listSucursales({ offset: 1, limit: 1000 });
        setSucursalMap(response.data || []);
    };

    useEffect(() => {
        fetchSucursales();
    }, []);

    const accordiondata = (item) => {
        return (<ExtraTableData UserInfo={item.hoja_formalizacion_data}></ExtraTableData>)
    }

    return (
        <div className="container">
            {/* <BreadCrumb /> */}
            <div className="topline">
                <div>
                    <h3>Comisiones</h3>
                </div>
                {
                    (process.env.NODE_ENV == "development" && Object.keys(errors).length > 0) &&
                    <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                        {JSON.stringify(errors)}
                    </div>
                }
            </div>
            <form onSubmit={handleSubmit}>

                <div className="middleline">
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: { sm: '1fr 1fr 1fr' },
                                gap: 2,
                            }}
                        >
                            <div >
                                <TextField
                                    id="demo-simple-select-label"
                                    select
                                    disabled={user?.rol?.permisos?.customViews['Comisiones']?.includes('View Oficinas') ? true : false}
                                    label="Oficina"
                                    name="sucursal_id"
                                    margin="normal"
                                    sx={inputStyles}
                                    value={values.sucursal_id ?? ''}
                                    size="small"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.sucursal_id && Boolean(errors.sucursal_id)}
                                    helperText={touched.sucursal_id && errors.sucursal_id}
                                >
                                    {sucursalMap ? sucursalMap.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.nombre_sucursal ?? "LAE HOMES - " + option.municipio}
                                        </MenuItem>
                                    )) : <MenuItem disabled>No hay oficinas disponibles</MenuItem>}

                                </TextField>

                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", gap: 6 }}>
                                <TextField
                                    sx={inputStyles}
                                    label="Inicio"
                                    name="fechaDesde"
                                    type="date"
                                    size="small"
                                    margin="normal"
                                    value={values.fechaDesde}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.fechaDesde && Boolean(errors.fechaDesde)}
                                    helperText={touched.fechaDesde && errors.fechaDesde}
                                />
                                <TextField
                                    sx={inputStyles}
                                    label="Fin"
                                    name="fechaHasta"
                                    type="date"
                                    size="small"
                                    margin="normal"
                                    value={values.fechaHasta}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.fechaHasta && Boolean(errors.fechaHasta)}
                                    helperText={touched.fechaHasta && errors.fechaHasta}
                                />
                            </div>
                            <div style={{ display: "flex", flex: 0.5, justifyContent: "start", gap: '10px' }}>

                                <Button
                                    type="submit"
                                    variant="outlined"
                                    sx={{
                                        color: '#c4af6c',
                                        backgroundColor: '#33383b',
                                        height: 40,
                                        borderColor: 'transparent',
                                        '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                                    }}
                                >
                                    <FaSearch style={{ color: '#c4af6c', '&:hover': { color: '#33383b' } }} />
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        color: '#33383b',
                                        backgroundColor: '#c4af6c',
                                        borderColor: 'transparent',
                                        '&:hover': { color: '#c4af6c', backgroundColor: '#fff', borderColor: '#33383b', }
                                    }}
                                    onClick={() => {
                                        // setFiltros({});
                                        fetchData({ offset: currentPage, limit: 1000, filtros: filtro });
                                        handleReset();
                                    }}                        >
                                    <MdCleaningServices style={{ color: '#33383b', '&:hover': { color: '#c4af6c' } }} />
                                </Button>
                            </div>
                        </Box>

                    </div>

                </div>

            </form>
            <DataTable
                data={data}
                headers={dynamicTableHeader({
                    headerArrayData: dataCustomNames,
                    showJustThis: ['Nombre', 'Apellidos', 'Total Comisión', 'Número Hojas Formalización'],
                    // addNewCustomHeader: iconColumn
                })}
                accordionData={accordiondata}
                isLoading={loading}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                fetcher={fetchData}
                mensaje={"No hay administradores de comisiones disponibles"}
                filtros={filtro}
                height="100%"
            />
            <ResumenComisiones data={data}></ResumenComisiones>

        </div>

    )
}

const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
    width: "100%",
    marginBottom: 0,
    marginTop: 0
};