import axios from "axios";

// const urlPath = "laehome-properties/crm";
const urlPath = "propiedades/crm";

export const properties = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/filter`, data.filtros, {
      params: data.params,
    });
    // console.log(response.data);
    // console.log(response);
    if (!response.data) {
      console.log("entro a vacio");
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getPropertie = async (ref) => {
  try {
    const response = await axios.get(`${urlPath}/${ref}`);
    console.log(response.data);
    
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createTextsPropertie = async (ref, data) => {
  try {
    const response = await axios.post(`${urlPath}/texts/${ref}`, data);

    return response.status;
  } catch (error) {
    console.log(error);
  }
};

export const editTextProperties = async (ref, data) => {
  try {
    
    const response = await axios.put(`${urlPath}/texts/${ref}/edit`, data);

    return response.status;
  } catch (error) {
    return error.response.data.error;
  }
};

export const addFilesProperties = async (ref, data) => {
  const { nombre, id_idioma, archivo, seccion } = data;
  const dataSend = new FormData();

  dataSend.append("nombre", nombre);
  dataSend.append("seccion", seccion);
  dataSend.append("id_idioma", id_idioma);
  dataSend.append(`file`, archivo);

  try {
    const response = await axios.post(`${urlPath}/docs/${ref}`, dataSend);

    return response.status;
  } catch (error) {
    return error.response.data.error;
  }
};

export const updateFilesProperties = async (ref, data) => {
  const { id, nombre, id_idioma, archivo, seccion } = data;
  const dataSend = new FormData();
  dataSend.append("id", id);
  dataSend.append("nombre", nombre);
  dataSend.append("seccion", seccion);
  dataSend.append("id_idioma", id_idioma);
  dataSend.append(`file`, archivo);

  if (archivo) {
    dataSend.append(`file`, archivo);
  }

  try {
    const response = await axios.put(`${urlPath}/docs/${ref}`, dataSend);

    return response.status;
  } catch (error) {
    return error.response.data.error;
  }
};

export const deleteFilePropertie = async (id_doc) => {
  try {
    const response = await axios.delete(`${urlPath}/docs/${id_doc}`);
    return response.status;
  } catch (error) {
    return error.response.data.error;
  }
};

export const downloadFilePropertie = async (id_doc, nombre) => {
  try {
    // console.log(id_doc)
    const response = await axios.get(`${urlPath}/docs/download/${id_doc}`, {
      responseType: "arraybuffer",
    });

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: "application/pdf" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${nombre}.pdf`);
    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);

    return response;
  } catch (error) {
    return error.response.data.error;
  }
};

export const publishPropertie = async (obj) => {
  try {

    const response = await axios.get(`${urlPath}/publish/propertie`,{params:obj});
    return response.status;
  } catch (error) {
    return error.response.data.error;
  }
};

export const noPublishPropertie = async (id) => {
  try {
    const response = await axios.delete(`${urlPath}/no-publish/${id}`);
    return response.status;
  } catch (error) {
    return error.response.data.error;
  }
};
