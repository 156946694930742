import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import { Tabs, Tab, Col } from 'react-bootstrap';
import { GetLanguageFlags, SnackbarUtilities } from "../../utilities";

const ObraNuevaForm = ({idiomas, textos, setDatosIdioma}) => {
    const [proyecto, setProyecto] = useState({});
    const [entorno, setEntorno] = useState({});
    const [disenyo, setDisenyo] = useState({});
    const [ubicacion, setUbicacion] = useState({});
    const param = useParams();

    const handleProyectoChange = (index, value) => {
        setProyecto({ ...proyecto, [index]: value });
        // updateDatosIdioma();
    };

    const handleEntornoChange = (index, value) => {
        setEntorno({ ...entorno, [index]: value });
        // updateDatosIdioma();
    };

    const handleDisenyoChange = (index, value) => {
        setDisenyo({ ...disenyo, [index]: value });
        // updateDatosIdioma();
    };
    const handleUbicacionChange = (index, value) => {
        setUbicacion({ ...ubicacion, [index]: value });
        // updateDatosIdioma();
    };

    useEffect(() => {
        const datosIdioma = idiomas.map((idioma, index) => ({
            ref: param.ref,
            id:textos[index]?.id,
            id_idioma: idioma.id,
            proyecto: proyecto[index] || (textos[index]?.proyecto !== undefined ? textos[index].proyecto : ''),
            entorno: entorno[index] || (textos[index]?.entorno !== undefined ? textos[index]?.entorno : ''),
            disenyo: disenyo[index] || (textos[index]?.disenyo !== undefined ? textos[index]?.disenyo : ''),
            ubicacion: ubicacion[index] || (textos[index]?.ubicacion !== undefined ? textos[index]?.ubicacion : ''),
        }));

        setDatosIdioma(datosIdioma);
    },[proyecto, entorno, disenyo, ubicacion ,setDatosIdioma, idiomas, textos]);

    return(
        <>
            <Tabs style={{marginLeft:'15px', width:'97.3%' }} defaultActiveKey={0} id="dynamic-tabs">
                {idiomas.map((idioma, index) => {
                    return (
                    
                        <Tab eventKey={index} title={ 
                            <Col lg={12}>
                                <GetLanguageFlags
                                    countryCode={idioma?.iso_code}
                                    key={idioma.id}
                                />
                            </Col>
                            } 
                            key={index}
                        >
                            <Col lg={12}>
                                <div className="header">
                                    <h4 className="text-align-left">Proyecto</h4>
                                </div>
                                <div className="contenido">
                                    <textarea
                                        onChange={(e) => handleProyectoChange(index, e.target.value)}
                                        className="form-control form-control-lg"
                                        id={`floatingProyecto-${index}`}
                                        type="Text"
                                        name={`proyecto_${idioma.id}`}
                                        rows={8}
                                        value={proyecto[index] !== undefined ? proyecto[index] : textos[index]?.proyecto !== undefined ? textos[index].proyecto : ''}
                                    />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="header">
                                    <h4 className="text-align-left">Entorno</h4>
                                </div>
                                <div className="contenido">
                                    <textarea
                                        onChange={(e) => handleEntornoChange(index, e.target.value)}
                                        className="form-control form-control-lg"
                                        id={`floatingEntorno-${index}`}
                                        type="Text"
                                        name={`entorno_${idioma.id}`}
                                        rows={8}
                                        value={entorno[index] !== undefined ? entorno[index] : textos[index]?.entorno !== undefined ? textos[index]?.entorno : ''}
                                    />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="header">
                                    <h4 className="text-align-left">Diseño</h4>
                                </div>
                                <div className="contenido">
                                    <textarea
                                        onChange={(e) => handleDisenyoChange(index, e.target.value)}
                                        className="form-control form-control-lg"
                                        id={`floatingDisenyo-${index}`}
                                        type="Text"
                                        name={`disenyo_${idioma.id}`}
                                        rows={8}
                                        value={disenyo[index] !== undefined ? disenyo[index] : textos[index]?.disenyo !== undefined ? textos[index]?.disenyo : ''}
                                    />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="header">
                                    <h4 className="text-align-left">Ubicación</h4>
                                </div>
                                <div className="contenido">
                                    <textarea
                                        onChange={(e) => handleUbicacionChange(index, e.target.value)}
                                        className="form-control form-control-lg"
                                        id={`floatingDisenyo-${index}`}
                                        type="Text"
                                        name={`ubicacion_${idioma.id}`}
                                        rows={8}
                                        value={ubicacion[index] !== undefined ? ubicacion[index] : textos[index]?.ubicacion !== undefined ? textos[index]?.ubicacion : ''}
                                    />
                                </div>
                            </Col>
                        </Tab>
                    )
                })}
            </Tabs>
        </>
    );
};

export default ObraNuevaForm;