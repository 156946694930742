import axios from "axios";
const urlPath = "contactos";

export const listAdminFincas = async (data) => {
    try {
        const response = await axios.post(`${urlPath}/filter`, data.filtros, {
            params: data.params,
        });
        return response.data;
    } catch (error) {

        console.log(error);
    }
};
export const getAAFF = async (id) => {
    try {
        const response = await axios.get(`${urlPath}/${id}`);
        return response;
    } catch (error) {
        console.log(error);
    }
};
export const createAAFF = async ( data ) => {
    try {
    
        const response = await axios.post(`${urlPath}`, data,{
            headers: {
                'Content-Type': 'application/json',
            }
        });
        console.log(response)
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
};
export const updateAAFF = async (id, data) => {
    try {
        const response = await axios.put(`${urlPath}/${id}`, data,{
            headers: {
                'Content-Type': 'application/json',
            }
        });
        console.log(response);
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
};

export const deleteAAFF = async (id) => {
    try {
        const response = await axios.delete(`${urlPath}/${id}`);
        // console.log(response);
        return response.status;
    } catch (error) {
        console.log(error);
        return error;
    }
};

