import React, { useState, useEffect,useMemo  } from "react";
import BreadCrumb from "../../components/navbar/Breadcrumb";
import { Tabs, Tab } from "react-bootstrap-tabs";
import { useParams } from "react-router-dom";
import { saveSucursal, updateSucursal, sucursalDetails, JsonCustomTexts } from "../../services/sucursales.service";
import { Table, TableBody, TableRow, TableCell, tableCellClasses, styled } from '@mui/material';
import { Row, Col } from "react-bootstrap";
import { DataTable, FiltrosPropiedades, DrawerSucursal } from "../../components";
import { useSucursales, useTrabajadores } from '../../hooks';
import { jsonRenameItems, transforHeader } from "../../utilities/transformHeaders";


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
export default function FichaSucursal() {
  const [property, setProperty] = useState({});
  const [tab, setTab] = useState(0);
  const { id } = useParams();
  const [sucursal, setSucursal] = useState([]);


  const { loading, data, fetchData } = useSucursales();
  const { data: dataTrabajadores, fetchData: fetchDataTrabajadores, loading: loadingTrabajadores, limite: limiteTrabajadores } = useTrabajadores();

  const [currentPage, setCurrentPage] = useState(1);
  const [filtros, setFiltros] = useState({});
  const [sideOpen, setSideOpen] = useState(false);
  const [propiedad, setPropiedad] = useState({});
  const [contactos, setContactos] = useState({});
  const [clientes, setClientes] = useState({});
  const [trabajadores, setTrabajadores] = useState({});
  const [customHeaders, setCustomHeaders] = useState(null);

  let titulosPersonalizadosTrabajadores;
  let titulosPersonalizadosContactos;
  let titulosPersonalizadosClientes;



  useEffect(() => {
    if (id) fetchDataSucursal();
  }, []);

  const fetchDataSucursal = async () => {
    const response = await sucursalDetails(id);
    // console.log(response);
    delete response.id;
    delete response.createdAt;
    delete response.updatedAt;
    delete response.status;
    delete response.longitud;
    delete response.latitud;

    titulosPersonalizadosTrabajadores = JsonCustomTexts(response.sucursal_trabajadores, "Trabajadores", "titulos_tabla");
    titulosPersonalizadosContactos = JsonCustomTexts(response.sucursal_contactos, "Contactos", "titulos_tabla");
    // setContactos(titulosPersonalizadosContactos);
    setTrabajadores(titulosPersonalizadosTrabajadores);

    if (response.estado === 1) {
      response.estado = 'Publicado';
    } else {
      response.estado = 'No Publicado';
    }
    delete response.sucursal_contactos;
    // delete response.sucursal_clientes;
    delete response.sucursal_trabajadores;
    delete response.coordinador;
    delete response.responsable;
    // console.log(response);
    const titulosPersonalizadosSucursales = JsonCustomTexts([response], "Sucursales", "titulos", ["id_ext"]);
    // console.log(titulosPersonalizadosSucursales[0]);
    setSucursal(titulosPersonalizadosSucursales[0]);

  }
  // console.log(loadingTrabajadores);
  const filtrosMemorizados = useMemo(() => ({
    sucursal_id: id
  }), [id]);

  return (
    <div className="container">
      {/* <BreadCrumb data={property?.referencia} /> */}
      <div className="top-line">
        <div style={{ textAlign: "start" }}>

          <div className="contenido">
            <div className="header">
              <h2>Datos generales</h2>
            </div>
            <hr />
            <div className="info-cliente">
              <Table>
                <TableBody>
                  {Object.entries(sucursal).map(([key, value]) =>
                    <StyledTableRow key={key}>
                      <StyledTableCell component="th" scope="row">
                        <b>{key}</b>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {value}
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>

          <div className="contenido">

            <Tabs /*onSelect={(index, label) => setTab(index)}*/>
              <Tab label="TRABAJADORES">
                {Array.isArray(trabajadores) && trabajadores.length > 0 ? (
                  // console.log("hiii"),
                  <DataTable
                    data={{ rows: jsonRenameItems(dataTrabajadores.rows, "Trabajadores", "titulos_tabla"), meta: dataTrabajadores.meta }}
                    fetcher={fetchDataTrabajadores}
                    filtros={ filtrosMemorizados}
                    // defaultFilterValue={ { sucursal_id: id }}
                    // isLoading={loadingTrabajadores}
                    // currentPage={currentPage}
                    // setCurrentPage={setCurrentPage}
                    mensaje={"No hay trabajadores disponibles"}
                    headers={transforHeader(jsonRenameItems(dataTrabajadores.rows, "Trabajadores", "titulos_tabla"), ["id", "DNI", "Estado", "sucursal_id", "Verificado", "user_trabajador", "fechaNac", "User ID", "status", "Sucursal", "Fecha de Nacimiento", "web", "url_profile_img",], null, "Trabajadores", "titulos_tabla")}
                  />
                ) : (<><br></br><h3 style={{ textAlign: "center" }}>No hay registros</h3></>)}
              </Tab>
            
            </Tabs>
          </div>

        </div>
      </div>
    </div >
  )
}
