import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function LHModal({
  title,
  closeButtomTitle,
  onButtonClick,
  children,
  show,
  ...props
}) {
  return (
    <Modal
      show={show}
      {...props}
      scrollable
      size={props.size ?? "xl"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            typeof onButtonClick === "function" && onButtonClick();
          }}
          style={{
            backgroundColor: "#c4af6c",
            border: "1px solid #c4af6c",
            color: "#33383b",
            fontWeight: "400",
          }}
        >
          {closeButtomTitle ?? "Close"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LHModal;
