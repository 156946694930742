import { useState } from "react";
import { listpropiedadesPrecio } from "../services/graficos.service";

function useGraficos() {
    const [limite, setLimite] = useState(0);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchData = async ({ offset, limit, filtros, orderBy, order }) => {
        setLimite(limit);
        let payload;
        if (orderBy && order) {
            payload = {
                params: { page: parseInt(offset), limit: parseInt(limit), orderBy: `${orderBy},${order}` },
                filtros
            };
        } else {
            payload = {
                params: { page: parseInt(offset), limit: parseInt(limit) },
                filtros
            };
        }

        try {
            // setLoading(true);
            let response = await listpropiedadesPrecio(payload);
            setData(response);
            setLoading(false);
        } catch (error) {
            console.log(error);
        } 
    };

    return {
        data,
        fetchData,
        loading,
        limite,
    };
}

export default useGraficos;
