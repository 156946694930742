import React, { useState, useEffect } from "react";
import {
    Paper
} from '@mui/material';
import BasicCard from "../../components/cards/BasicCard";
const styledButton = {
    backgroundColor: '#33383b',
    color: '#c4af6c',
    '&:hover': {
        backgroundColor: '#c4af6c',
        color: '#33383b',
    },
};
const ExtraTableData = ({ UserInfo }) => {

    return (
        <Paper sx={{display:"flex",justifyContent:"center",p:2,backgroundColor:"#fff"}} elevation={0}>
            <BasicCard data={UserInfo}></BasicCard>
        </Paper>
    );
};

export default ExtraTableData;