import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Accordion, Form, InputGroup } from "react-bootstrap";
import { GetLanguageFlags } from "../../utilities";

const TrendsForm = ({ onChange, langId, title, articleData, languageCode, eventKey }) => {
  const [data, setData] = useState({});
  
  const [dataLoaded, setDataLoaded] = useState(false);
  const fetchDataLoaded = async () => {
    try {
        setData(articleData?.[langId] || {}); 
        setDataLoaded(true);
    } catch (error) {
      console.error("Error al cargar datos:", error);
    }
   
  }
  useEffect(()=> {
    fetchDataLoaded();
  },[articleData]); 

  if (!dataLoaded) {
    return <div>Cargando datos...</div>; // Muestra un mensaje de carga
  }
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            alignItems: "center",
          }}
        >
          <GetLanguageFlags countryCode={languageCode} /> {title}
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div className="d-flex gap-2">
          <InputGroup className="mb-3">
            <Form.Control
              defaultValue={data.title}
              placeholder="Titulo del Artículo"
              onChange={({ target }) =>
                setData((prev) => ({ ...prev, title: target.value }))
              }
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <Form.Control
              defaultValue={data.slug}
              placeholder="Slug"
              onChange={({ target }) =>
                setData((prev) => ({ ...prev, slug: target.value }))
              }
            />
          </InputGroup>
        </div>

        <h4 style={{ alignSelf: "start", textAlign: "left" }}> Contenido</h4>

        <CKEditor
          editor={ClassicEditor}
          data={data.content}
          config={{
            language: languageCode ? languageCode.toLowerCase() : "es",
            toolbar: [
              "heading",
              "|",
              "bold",
              "italic",
              "link",
              "bulletedList",
              "numberedList",
              "|",
              "outdent",
              "indent",
              "|",
              "blockQuote",
              "mediaEmbed",
              "undo",
              "redo",
            ],
          }}
          /* onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            console.log("Editor is ready to use!", editor);
          }} */
          onChange={(event, editor) => {
            const text = editor.getData();
            setData((prevData) => ({ ...prevData, content: text }));
            typeof onChange === "function" &&
              onChange({ ...data, content: text });
          }}
          /* onBlur={(event, editor) => {
            console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            console.log("Focus.", editor);
          }} */
        />
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default TrendsForm;
