import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronRight';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Home, Person, Business, Settings, Store, People, Language, TravelExplore, Description, Apartment, Security, Newspaper } from '@mui/icons-material';
import Logo from "../../images/logo_laehomes_dorado.svg";
import LogoMini from "../../images/favicon_laehomes.svg";
import axios from 'axios';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import * as MuiIcons from '@mui/icons-material';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { useUser } from '../../hooks/UserContextLogin';
import { logout } from '../../services/auth.service';
import CircularProgress from '@mui/material/CircularProgress';
import { alpha } from '@mui/material/styles';
import BreadCrumb from "../../components/navbar/Breadcrumb";

const drawerWidth = 280;
const selectedBgColor = 'rgba(255, 255, 255, 0.08)'; // Background color for selected item
const selectedTextColor = '#A69150'; // Text color for selected item
const defaultTextColor = '#A69150';
const widthCloseDrawer = 13.2;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(widthCloseDrawer)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        backgroundColor: '#33383b',
        color: '#A69150',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': {
                ...openedMixin(theme),
                backgroundColor: '#33383b',
                color: '#A69150',
            },
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': {
                ...closedMixin(theme),
                backgroundColor: '#33383b',
                color: '#A69150',
            },
        }),
    }),
);

const MiniDrawer = () => {
    const muiTheme = useTheme();
    const [open, setOpen] = useState(true);
    const [selectedParent, setSelectedParent] = useState("user");
    const [selectedIndexChild, setSelectedIndexChild] = useState(0);
    const [items, setItems] = useState([]);
    const navigate = useNavigate();
    const { user: userInfo } = useUser();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    
    const LoadingScreen = () => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress style={{ color: "#c4af6c" }} />
        </div>
    );

    //Obtener ultima navegacion desde el localstorage y aplicarlo si ha echo login o si carga el inicio por primera vez
    useEffect(() => {
        if (location.state && location.state.fromLogin || location.pathname === "/") {
            const navPadreHistorial = localStorage.getItem("navPadreHistorial");
            const navHijoHistorial = localStorage.getItem("navHijoHistorial");
            const navComponentHistorial = localStorage.getItem("navComponentHistorial");

            if (navPadreHistorial && navHijoHistorial && navComponentHistorial) {
                setSelectedParent(navPadreHistorial);
                setSelectedIndexChild(parseInt(navHijoHistorial));
                navigate(`/${navComponentHistorial}`);
            }
        }
    }, [])

    useEffect(() => {
        if (userInfo) {
            // console.log(userInfo.rol.permisos.navegación);
            // console.log(location.pathname.toLowerCase());
            // console.log(location.pathname.toLowerCase().split('/').slice(0, 2).join('/'));

            if (location.pathname.toLowerCase().split('/').slice(0, 2).join('/') == "/") {
                setItems(userInfo.rol.permisos.navegación);
                setInitialSelection(userInfo.rol.permisos.navegación, "/graficoscomponente");
                setLoading(false);
            }else {
                setItems(userInfo.rol.permisos.navegación);
                setInitialSelection(userInfo.rol.permisos.navegación, location.pathname.toLowerCase().split('/').slice(0, 2).join('/'));
                setLoading(false);
            }

        }
    }, [userInfo, location.pathname]);

    const setInitialSelection = (navigationItems, pathname) => {
        const selectedItem = navigationItems.find(item => `/${item.navComponentHistorial}` === pathname);
        //Control de que si solo hay un elemento el enfoque se ponga a 0 para que salga seleccionado
        if (selectedItem) {
            // let countBusinessParentId = userInfo.rol.permisos.navegación.filter(item => item.parentId == selectedItem.navPadreHistorial).length;
            // console.log(countBusinessParentId);
            
            // if (countBusinessParentId == 1) {
            //     selectedItem.navHijoHistorial = 0;
            // }
            // console.log(selectedItem.navPadreHistorial);
            // console.log(selectedItem.navHijoHistorial);
            
            setSelectedParent(selectedItem.navPadreHistorial);
            setSelectedIndexChild(selectedItem.navHijoHistorial);
        }
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleParentClick = (parent) => {
        setSelectedParent(parent === selectedParent ? parent : parent);
        setSelectedIndexChild(null);
        localStorage.setItem("navPadreHistorial", parent === selectedParent ? parent : parent);
        localStorage.setItem("navHijoHistorial", null);
    };

    const handleChildItemClick = (event, index, componentName) => {
        const selectedItem = items.find(item => item.component === componentName);
        if (selectedItem) {
            const path = `/${selectedItem.component}`;
            navigate(path);
            setSelectedIndexChild(index);
            // console.log(path);
            // console.log(index);
            
            localStorage.setItem("navHijoHistorial", index);
            localStorage.setItem("navComponentHistorial", selectedItem.component);
        }
    };

    const renderChildItems = () => {
        if (!selectedParent) return null;
        const childItems = items.filter(item => item.parentId === selectedParent);
        childItems.sort((a, b) => a.position - b.position);
        return (
            <List sx={{ width: "100%", color: '#A69150' }}>
                {childItems.map((item, index) => {
                    const IconComponent = MuiIcons[item.icon];
                    return (
                        <ListItemButton
                            key={item.id}
                            selected={selectedIndexChild === index}
                            onClick={(event) => handleChildItemClick(event, index, item.component)}
                            sx={{
                                paddingBottom: 1.09, paddingTop: 1,
                                '&.Mui-selected': {
                                    backgroundColor: selectedBgColor,
                                    color: selectedTextColor,
                                    '&:hover': {
                                        backgroundColor: selectedBgColor,
                                    },
                                }
                                ,
                            }}
                        >
                            <ListItemIcon style={{ minWidth: 35, width: 25 }}>
                                <IconComponent style={{ color: '#A69150' }} />
                            </ListItemIcon>
                            <ListItemText primary={item.text} sx={{ color: '#A69150' }} />
                        </ListItemButton>
                    );
                })}
            </List>
        );
    };

    const mainIconsNav = () => {
        const parentItems = items.filter(item => !item.parentId).sort((a, b) => a.position - b.position);
        return (
            <List sx={{ width: 55, textAlign: "center" }}>
                {parentItems.map((item) => {
                    const IconComponent = MuiIcons[item.icon];
                    return (
                        <ListItemButton
                            key={item.id}
                            sx={{
                                paddingBottom: 1.6,
                                paddingTop: 1.5,
                                '&.Mui-selected': {
                                    backgroundColor: selectedBgColor,
                                    color: selectedTextColor,
                                    '&:hover': {
                                        backgroundColor: selectedBgColor,
                                    },
                                }
                            }}
                            selected={selectedParent === item.id}
                            onClick={() => handleParentClick(item.id)}
                        >
                            <ListItemIcon>
                                <IconComponent style={{ color: '#A69150' }} />
                            </ListItemIcon>
                        </ListItemButton>
                    );
                })}
            </List>
        );
    }

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <Box sx={{ display: 'flex' }}>
            {/* <CssBaseline /> */}
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <IconButton aria-label="image-button" sx={{ paddingLeft: "15px", paddingRight: "15px" }} disableRipple={true}>
                            <img src={LogoMini} alt="icon" style={{ width: 32, height: 32 }} />
                        </IconButton>
                    </IconButton>

                    <Box sx={{ display: 'flex', justifyContent: "end", width: "100%" }}>
                        <Typography variant="body1" gutterBottom mt={1} mr={1}>
                            {userInfo.usuarioDatos.nombre}
                        </Typography>
                        <IconButton aria-label="settings" onClick={() => navigate('/change-password')}>
                            <SettingsIcon />
                        </IconButton>
                        <IconButton aria-label="logout" onClick={() => logout()}>
                            <LogoutIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                        <img src={Logo} alt="Logo" style={{ maxWidth: '100%', height: 'auto', width: "90%", margin: 20, cursor: "pointer" }} onClick={() => navigate("/")} />
                    </Box>
                    <IconButton onClick={handleDrawerClose}>
                        {muiTheme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <Box sx={{ display: 'flex' }}>
                    {mainIconsNav()}
                    <Divider orientation="vertical" flexItem />
                    {renderChildItems()}
                </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, px: 1 }}>
                <DrawerHeader />
                <BreadCrumb />
                <Outlet />
            </Box>
        </Box>
    );
}

export default MiniDrawer;
