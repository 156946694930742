import { Typography, Button } from "@mui/material";
const jsonTitulos = require('../titulos_personalizados.json'); // Cargar el archivo JSON de títulos

export const jsonRenameItems = (arrayObjetos, elemento, seccion, delete_element = []) => {
  const titulosPersonalizados = [];
  arrayObjetos = arrayObjetos ?? []
  // console.log(arrayObjetos);
  // console.log(delete_element);
  // Verificar si el elemento y la sección existen en el JSON de títulos
  if (jsonTitulos[elemento] && jsonTitulos[elemento][seccion]) {
    // console.log(arrayObjetos);
    // Obtener el orden de las claves de la sección especificada
    const ordenClaves = jsonTitulos[elemento][seccion].map(titulo => titulo.titulo_personalizado);

    // Iterar sobre cada objeto en el array
    arrayObjetos.forEach((objeto) => {
      const objetoModificado = {}; // Objeto para almacenar las claves modificadas

      // Iterar sobre cada clave del objeto
      Object.keys(objeto).forEach((key) => {
        if (!delete_element.includes(key)) {
          // Buscar la clave en la sección especificada del JSON de títulos
          const titulosSeccion = jsonTitulos[elemento][seccion];
          const tituloEncontrado = titulosSeccion.find((titulo) => titulo.titulo_original_db === key);
          if (tituloEncontrado) {
            // Si se encuentra el título correspondiente, modificar la clave del objeto
            objetoModificado[tituloEncontrado.titulo_personalizado] = objeto[key];
          } else {
            // Si no se encuentra el título correspondiente, mantener la clave original
            objetoModificado[key] = objeto[key];
          }
        }
      });
      // Reordenar las claves del objeto modificado de acuerdo con el orden en el JSON de títulos
      const objetoReordenado = {};
      // console.log(objetoModificado);
      // console.log(objetoModificado);

      ordenClaves.forEach((clave) => {
        if (objetoModificado.hasOwnProperty(clave)) {
          // console.log(clave);
          objetoReordenado[clave] = objetoModificado[clave];
        }
      });
      //console.log(objetoModificado);
      // console.log(objetoReordenado);
      // Almacenar el objeto reordenado en el array de salida
      titulosPersonalizados.push(objetoReordenado);
      // console.log(titulosPersonalizados);
      // console.log(titulosPersonalizados);
    });
  } else {
    console.error(`No se encontró la sección '${seccion}' en el elemento '${elemento}' del JSON de títulos.`);
  }
  return titulosPersonalizados;
}


// Función para renombrar propiedades de personalizado a original
export const jsonRenameItemsToOriginal = (arrayObjetos, elemento, seccion, delete_element = []) => {
  const titulosOriginales = [];

  // Verificar si el elemento y la sección existen en el JSON de títulos
  if (jsonTitulos[elemento] && jsonTitulos[elemento][seccion]) {
    // Iterar sobre cada objeto en el array
    arrayObjetos.forEach((objeto) => {
      const objetoModificado = {}; // Objeto para almacenar las claves modificadas

      // Iterar sobre cada clave del objeto
      Object.keys(objeto).forEach((key) => {
        if (!delete_element.includes(key)) {
          // Buscar la clave en la sección especificada del JSON de títulos
          const titulosSeccion = jsonTitulos[elemento][seccion];
          const tituloEncontrado = titulosSeccion.find((titulo) => titulo.titulo_personalizado === key);
          if (tituloEncontrado) {
            // Si se encuentra el título correspondiente, modificar la clave del objeto
            objetoModificado[tituloEncontrado.titulo_original_db] = objeto[key];
          } else {
            // Si no se encuentra el título correspondiente, mantener la clave original
            objetoModificado[key] = objeto[key];
          }
        }
      });

      // Almacenar el objeto modificado en el array de salida
      titulosOriginales.push(objetoModificado);
    });
  } else {
    console.error(`No se encontró la sección '${seccion}' en el elemento '${elemento}' del JSON de títulos.`);
  }

  return titulosOriginales;
};

const jsonRenameStringsToOriginal = (arrayStrings, elemento, seccion) => {
  const titulosOriginales = [];

  // Verificar si el elemento y la sección existen en el JSON de títulos
  if (jsonTitulos[elemento] && jsonTitulos[elemento][seccion]) {
    // Iterar sobre cada string en el array
    arrayStrings.forEach((string) => {
      // Buscar el string en la sección especificada del JSON de títulos
      const titulosSeccion = jsonTitulos[elemento][seccion];
      const tituloEncontrado = titulosSeccion.find((titulo) => titulo.titulo_personalizado === string);
      if (tituloEncontrado) {
        // Si se encuentra el título correspondiente, usar el nombre original
        titulosOriginales.push(tituloEncontrado.titulo_original_db);
      } else {
        // Si no se encuentra el título correspondiente, mantener el string original
        titulosOriginales.push(string);
      }
    });
  } else {
    console.error(`No se encontró la sección '${seccion}' en el elemento '${elemento}' del JSON de títulos.`);
  }

  return titulosOriginales;
};


export const transforHeader = (headerArray, filterHeader = [], newItem = null, originalTable = null, originalTitle = null, userPermision = null) => {
  // console.log(newItem);
  // console.log(userPermision);
  // console.log(userPermision.rol.permisos.tablas.columnasQuitar);
  // console.log(headerArray);

  // const filteredArray = newItem.filter(item => !userPermision.includes(item.titleHeader));
  // console.log(filteredArray);

  //Extraer las key del objeto en un array
  const clavesUnicas = headerArray.reduce((acc, objeto) => {
    Object.keys(objeto).forEach(key => {
      //si dentro de las keys hay una propiedad tipo objeto la ignora
      if (!acc.includes(key) && (objeto[key] === null || typeof objeto[key] !== 'object')) {
        acc.push(key);
      }
    });
    return acc;
  }, []);

  // console.log(headerArray);
  console.log(clavesUnicas);

  //Le quita las key que no queramos al array que pasamos
  const arrayFiltrado = clavesUnicas.filter(elemento => !filterHeader.includes(elemento));
  // console.log(arrayFiltrado);

  //cogemos los titulos originales para usarlos como name en las columnas para el order asc and desc
  const originalHeaders = jsonRenameStringsToOriginal(arrayFiltrado, originalTable, originalTitle);
  if (originalHeaders.length == arrayFiltrado.length) {
    //Recorre los headers y los plasma en columnas
    let headers = arrayFiltrado.map((key, index) => ({
      name: originalHeaders[index],
      numeric: false,
      disablePadding: false,
      label: key,
      overrideFunc: (data, row) => (
        <Typography
          sx={{
            color: "#33383b !important",
            "&:hover": { color: "#33383b" },
            "&:active": { color: "#33383b" },
            fontSize: "0.9rem",
            fontWeight: "1rem"
          }}
        >
          {row[key]}
        </Typography>
      )
    }));

    //Añade un icono o valor personalizado al array y a una posicion concreta
    if (newItem) {
      newItem.forEach(element => {
        headers.splice(element.position, 0, {
          name: element.nameColumn,
          numeric: false,
          disablePadding: false,
          label: element.titleHeader,
          // label: "Ciudad",
          overrideFunc: (data, row) => (
            console.log(row),
            element.item(row)),
        });
      });

    }
    // console.log(headers);

    // console.log(userPermision?.rol?.permisos?.tablas?.hasOwnProperty('Sucursales'));
    console.log(userPermision?.rol?.permisos?.tablas);
    if (userPermision?.rol?.permisos?.tablas?.hasOwnProperty(originalTable)) {
      const sucursalesValues = userPermision?.rol?.permisos?.tablas[originalTable];
      console.log(sucursalesValues);
      headers = headers.filter(item => !userPermision.rol.permisos.tablas[originalTable].includes(item.label));
    }
    // console.log(headers);
    // console.log(userPermision);

    // if (userPermision?.rol?.permisos?.tablas?.columnasQuitar) {
    //    headers = headers.filter(item => !userPermision.rol.permisos.tablas.columnasQuitar.includes(item.label));
    // }
    // console.log(headers);

    return headers;

  } else {
    return <div>la longitud de los array no son iguales</div>;
  }

};

export const dynamicTableHeader = ({headerArrayData,showJustThis,deleteItems,addNewCustomHeader,userPermision,permissionResourceName}) => {
  try {
    let headers = [];
    // console.log(headerArrayData);
    
    // Verifica si `headerArrayData` es válido
    if (!headerArrayData || headerArrayData.length === 0) {
      // console.log('headerArrayData es null o vacío.');
      return [
        {
          name: 'error',
          numeric: false,
          disablePadding: false,
          label: '',
          overrideFunc: (data, row) => <Typography>Error en los datos</Typography>,
        },
      ];
    }
    // Quitar elementos de los datos recibidos generales (si deleteItems está presente)
    if (deleteItems?.length > 0) {
      headerArrayData = headerArrayData.filter(
        (obj) => !deleteItems.includes(obj.label)
      );
    }

    // Mostrar solo los elementos indicados (si showJustThis está presente)
    if (showJustThis?.length > 0) {
      const notFound = [];
      headerArrayData = showJustThis.map((str) => {
        // console.log(headerArrayData);
        
        const foundObj = headerArrayData.find((obj) => obj.label === str);
        if (!foundObj) {
          notFound.push(str); // Si no encuentra el item, lo añade a notFound
        }
        return foundObj;
      }).filter((obj) => obj !== undefined); // Filtramos undefined

      if (notFound.length > 0) {
        // console.log(
        //   'Items no encontrados o nombres incorrectos:'+
        //   JSON.stringify(notFound)
        // );
      }
    }

    // Crear los headers a partir de headerArrayData
    headers = headerArrayData.map((key) => ({
      name: key.key,
      numeric: false,
      disablePadding: false,
      label: key.label,
      overrideFunc: (data, row) => (
        <Typography
          sx={{
            color: '#33383b !important',
            '&:hover': { color: '#33383b' },
            '&:active': { color: '#33383b' },
            fontSize: '0.9rem',
            fontWeight: '1rem',
          }}
        >
          {row[key.key]}
        </Typography>
      ),
    }));

    // Añadir headers personalizados si existe addNewCustomHeader
    if (addNewCustomHeader?.length > 0) {
      addNewCustomHeader.forEach((element) => {
        // Formatear la posición para empezar desde 1, y -1 para añadir al final
        let position =
          element.position >= 0
            ? element.position - 1
            : headers.length + element.position + 1;

        headers.splice(position, 0, {
          name: element.nameColumn,
          numeric: false,
          disablePadding: false,
          label: element.titleHeader,
          custom:true,
          overrideFunc: (data, row) => element.item(row),
        });
      });
    }

    //Verificar si existen permisos para ocultar o ver 
    if (userPermision?.rol?.permisos?.tablas?.hasOwnProperty(permissionResourceName)) {
      const sucursalesValues = userPermision?.rol?.permisos?.tablas[permissionResourceName];
      // console.log(sucursalesValues);
      headers = headers.filter(item => !userPermision.rol.permisos.tablas[permissionResourceName].includes(item.label));
    }
    // console.log(headers);
    
    return headers;
  } catch (error) {
    console.log('Error en dynamicTableHeader:', error);

    // En caso de error, retornar un header por defecto
    return [
      {
        name: 'error',
        numeric: false,
        disablePadding: false,
        label: 'Error en los datos',
        overrideFunc: (data, row) => (
          <Typography>Error en los datos</Typography>
        ),
      },
    ];
  }
};