import axios from "axios";
import { getCookie } from "../utilities";
// import { jwtDecode } from "jwt-decode";
// import { jwtDecode } from "jwt-decode";
import jwt_decode from "jwt-decode";

export const AUTH_TOKEN = {
  token: function (mtoken) {
    return mtoken;
  },
};

export const signIn = async (login) => {
  const data = { email: login.email, password: login.password };
  try {
    const response = await axios.post(`/auth/login/CRM`, data)
    // console.log(response);
    
    if (response.status === 200) {
      const dataUser = jwt_decode(response.data);
      localStorage.setItem("expires", new Date(dataUser.exp * 1000).toLocaleString());
      localStorage.setItem("token", response.data);
      localStorage.setItem("state", true);

      dataUser.token = true;
      // console.log(dataUser);
      return dataUser;
    }else {
      console.log(response);
      return response;
    }   
  } catch (err) {
    console.log(err);
    return err.request;
  }
};

export const logout = async () => {
  localStorage.removeItem("token");
  localStorage.removeItem("state");
  localStorage.removeItem("expires");

  window.location.replace("/login");
};

export const passwordEmail = async ({ email }) => {
  const data = { email };
  try {
    const response = await axios.post(`/auth/recover-password`, data);

    if (response.status === 200) {
      localStorage.setItem("estado", false);
    } else {
      localStorage.setItem("estado", true);
    }
    //return response.status
  } catch (err) {
    console.log(err);
    localStorage.setItem("estado", true);
  }
};

export const verifyAccount = async ( token ) => {
  try {
    const response = await axios.post('/auth/verify', token);
    console.log(response);
    return response.status;
    
  } catch (error) {
    console.log(error);
    
    return error;
  }
}

export const refreshToken = async () => {
  const data = getCookie("LAECRM");
  
  try {
    // // const response = await axios.post(`${URL}auth/token?refresh_token=${data}`).then(response => {
    // //   // localStorage.setItem("token", response.data.token);
    // //   return response.data.token;
    // // }).catch(res=>{
    // //   capturaError(res);
    // // });
    // // localStorage.setItem("token",response);
    // // usuarioContext._currentValue.token = response;
    // // // document.cookie ="LAE="+response.data.refreshToken+";expires="+fecha.toUTCString()+";path=/;";
    // // // localStorage.setItem("refreshToken", response.data.refreshToken)
    // return response;
  } catch (err) {
    console.log(err);
  }
};

export const verifyToken = async (data) => {  
  try {
    // console.log(data);
     await axios.post(`/auth/verifyToken`, {token:data});
    // console.log(response);
    
  } catch (err) {
    logout();
    console.log(err);
    logout();
    return err;
  }
};

