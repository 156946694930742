export const validateTextosIdioma = (textos) => {
    let alert = {
        proyecto: {es:'',en:''},
        entorno: {es:'',en:''},
        disenyo: {es:'',en:''},
        ubicacion: {es:'',en:''}
    };
    console.log(textos);
    textos.forEach(element => {
        
        if (element.proyecto == null || element.proyecto.length <= 0) {
            if(element.id_idioma == 1){
                alert.proyecto['es'] =  `El proyecto en Español debe tener más de 30 caracteres y no puede estar vacío`;
            }else{
                alert.proyecto['en'] =  `El proyecto en Inglés debe tener más de 30 caracteres y no puede estar vacío`;
            }
            
        }
        if (element.entorno == null || element.entorno.length <= 0) {
            if(element.id_idioma == 1){
                alert.entorno['es'] =  `El entorno en Español debe tener más de 30 caracteres y no puede estar vacío`;
            }else{
                alert.entorno['en'] =  `El entorno en Inglés debe tener más de 30 caracteres y no puede estar vacío`;
            }
            
        }
        if (element.disenyo == null || element.disenyo.length <= 0) {
            if(element.id_idioma == 1){
                alert.disenyo['es'] =  `El diseño en Español debe tener más de 30 caracteres y no puede estar vacío`;
            }else{
                alert.disenyo['en'] =  `El diseño en Inglés debe tener más de 30 caracteres y no puede estar vacío`;
            }
        }
        if (element.ubicacion == null || element.ubicacion.length <= 0) {
            if(element.id_idioma == 1){
                alert.ubicacion['es'] =  `El ubicación en Español debe tener más de 30 caracteres y no puede estar vacío`;
            }else{
                alert.ubicacion['en'] =  `El ubicación en Inglés debe tener más de 30 caracteres y no puede estar vacío`;
            }
        }
    });
    return alert;
};
