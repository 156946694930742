import React, { useCallback, useState, useEffect } from 'react';
import { TextField } from '@mui/material';

const AutoFillAwareTextField = ({ value, onChange, inputProps, InputLabelProps, ...rest }) => {
    const [fieldHasValue, setFieldHasValue] = useState(!!value);

    const makeAnimationStartHandler = (stateSetter) => (e) => {
        const autofilled = !!e.target?.matches("*:-webkit-autofill");
        console.log(autofilled);
        
        if (e.animationName === "mui-auto-fill" || e.animationName === "mui-auto-fill-cancel") {
            stateSetter(autofilled || e.target.value !== "");
            
        }
    };

    const _onChange = useCallback((e) => {
        onChange(e);
        setFieldHasValue(e.target.value !== "");
        // console.log(e.target.value);

    }, [onChange]);

    useEffect(() => {
        setFieldHasValue(!!value);
        // console.log(value);

    }, [value]);

    return (
        <TextField
            value={value}
            inputProps={{
                onAnimationStart: makeAnimationStartHandler(setFieldHasValue),
                ...inputProps
            }}
            InputLabelProps={{
                shrink: fieldHasValue,
                ...InputLabelProps
            }}
            onChange={_onChange}
            {...rest}
        />
    );
};

export default AutoFillAwareTextField;
