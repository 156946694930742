import { useState } from "react";
import { listTrabajadores } from "../services";

function useComisiones() {
    const [limite, setLimite] = useState(0);
    const [data, setData] = useState({
        meta: {},
        rows: []
    });
    const [loading, setLoading] = useState(true);
    const [dataCustomNames, setDataCustomNames] = useState(null);
    const customNames = [
        'ID', 'Número Hojas Formalización', 'Total Comisión', 'Nombre', 'Apellidos', 'hoja_formalizacion_data'
    ];

    // const formatData = (data) => {
    //     try {
    //         return data.map((row) => {
    //             // Crear un Set con IDs únicos de ambas relaciones
    //             const uniqueHojaIds = new Set([
    //                 ...row.trabajador_hoja_vendedor.map(hoja => hoja.id),
    //                 ...row.trabajador_hoja_captador.map(hoja => hoja.id),
    //                 ...row.trabajador_hoja_coordinador.map(hoja => hoja.id),
    //                 ...row.trabajador_hoja_responsable.map(hoja => hoja.id)
    //             ]);
    //             // Cálculo de la comisión total agrupado por cada ID de hoja
    //             const total_comision_por_hoja = Array.from(uniqueHojaIds).map((hojaId) => {
    //                 let totalCaptador = 0;
    //                 let totalVendedor = 0;
    //                 let totalRespo = 0;
    //                 let totalCoor = 0;

    //                 // Sumar totalCaptador si el id_captador coincide con el ID del trabajador en ambas relaciones
    //                 row.trabajador_hoja_captador.forEach((objeto) => {
    //                     if (objeto.id === hojaId && objeto.id_captador === row.id) {
    //                         totalCaptador += Number(objeto.totalCaptador) || 0;
    //                     }
    //                 });

    //                 // Sumar totalVendedor si el id_agVendedor coincide con el ID del trabajador en ambas relaciones
    //                 row.trabajador_hoja_vendedor.forEach((objeto) => {
    //                     if (objeto.id === hojaId && objeto.id_agVendedor === row.id) {
    //                         totalVendedor += Number(objeto.totalVendedor) || 0;
    //                     }
    //                 });
    //                 // Sumar totalRespo si el id_respo coincide con el ID del trabajador en ambas relaciones
    //                 row.trabajador_hoja_responsable.forEach((objeto) => {
    //                     if (objeto.id === hojaId && objeto.id_respo === row.id) {
    //                         totalRespo += Number(objeto.totalRespo) || 0;
    //                     }
    //                 });
    //                 // Sumar totalCoor si el id_coor coincide con el ID del trabajador en ambas relaciones
    //                 row.trabajador_hoja_coordinador.forEach((objeto) => {
    //                     if (objeto.id === hojaId && objeto.id_coor === row.id) {
    //                         totalCoor += Number(objeto.totalCoor) || 0;
    //                     }
    //                 });

    //                 // Retornar la suma total para este ID de hoja
    //                 return totalCaptador + totalVendedor + totalRespo + totalCoor;
    //             });

    //             // Suma de todas las comisiones por hoja y formato de moneda
    //             const total_comision = total_comision_por_hoja
    //                 .reduce((acc, val) => acc + val, 0)
    //                 .toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });


    //             // Combina ambas relaciones y filtra por IDs únicos
    //             // Crear una lista de relaciones únicas y ajustar valores basados en condiciones
    //             const hoja_formalizacion_data = [
    //                 ...row.trabajador_hoja_vendedor,
    //                 ...row.trabajador_hoja_captador,
    //                 ...row.trabajador_hoja_responsable,
    //                 ...row.trabajador_hoja_coordinador,
    //             ].reduce((acc, hoja) => {
    //                 // Verificar si el ID ya está en la lista para evitar duplicados
    //                 if (!acc.some((item) => item.id === hoja.id)) {
    //                     // Clonar el objeto hoja para no modificar el original
    //                     const hojaModificada = { ...hoja };

    //                     // Validar el id_captador y id_agVendedor y asignar 0 si no coinciden con el ID del trabajador
    //                     if (hoja.id_captador !== row.id) {
    //                         hojaModificada.totalCaptador = 0;
    //                     }
    //                     if (hoja.id_agVendedor !== row.id) {
    //                         hojaModificada.totalVendedor = 0;
    //                     }
    //                     if (hoja.id_respo !== row.id) {
    //                         hojaModificada.totalRespo = 0;
    //                     }
    //                     if (hoja.id_coor !== row.id) {
    //                         hojaModificada.totalCoor = 0;
    //                     }

    //                     acc.push(hojaModificada); // Agregar la hoja modificada
    //                 }
    //                 return acc;
    //             }, []);

    //             return {
    //                 id: row.id,
    //                 numero_hoja: uniqueHojaIds.size, // Usar el tamaño del Set para contar los IDs únicos
    //                 total_comision: total_comision,
    //                 nombre: row.nombre,
    //                 apellidos: row.apellidos,
    //                 hoja_formalizacion_data,
    //                 total:row.total
    //             };
    //         });
    //     } catch (error) {
    //         console.log(error);
    //     }

    // };
    const formatData = (data) => {
        try {
            return data
                .map((row) => {
                    // Crear un Set con IDs únicos de ambas relaciones
                    const uniqueHojaIds = new Set([
                        ...row.trabajador_hoja_vendedor.map(hoja => hoja.id),
                        ...row.trabajador_hoja_captador.map(hoja => hoja.id),
                        ...row.trabajador_hoja_coordinador.map(hoja => hoja.id),
                        ...row.trabajador_hoja_responsable.map(hoja => hoja.id)
                    ]);
    
                    // Cálculo de la comisión total agrupado por cada ID de hoja
                    const total_comision_por_hoja = Array.from(uniqueHojaIds).map((hojaId) => {
                        let totalCaptador = 0;
                        let totalVendedor = 0;
                        let totalRespo = 0;
                        let totalCoor = 0;
    
                        // Sumar totalCaptador si el id_captador coincide con el ID del trabajador en ambas relaciones
                        row.trabajador_hoja_captador.forEach((objeto) => {
                            if (objeto.id === hojaId && objeto.id_captador === row.id) {
                                totalCaptador += Number(objeto.totalCaptador) || 0;
                            }
                        });
    
                        // Sumar totalVendedor si el id_agVendedor coincide con el ID del trabajador en ambas relaciones
                        row.trabajador_hoja_vendedor.forEach((objeto) => {
                            if (objeto.id === hojaId && objeto.id_agVendedor === row.id) {
                                totalVendedor += Number(objeto.totalVendedor) || 0;
                            }
                        });
    
                        // Sumar totalRespo si el id_respo coincide con el ID del trabajador en ambas relaciones
                        row.trabajador_hoja_responsable.forEach((objeto) => {
                            if (objeto.id === hojaId && objeto.id_respo === row.id) {
                                totalRespo += Number(objeto.totalRespo) || 0;
                            }
                        });
    
                        // Sumar totalCoor si el id_coor coincide con el ID del trabajador en ambas relaciones
                        row.trabajador_hoja_coordinador.forEach((objeto) => {
                            if (objeto.id === hojaId && objeto.id_coor === row.id) {
                                totalCoor += Number(objeto.totalCoor) || 0;
                            }
                        });
    
                        // Retornar la suma total para este ID de hoja
                        return totalCaptador + totalVendedor + totalRespo + totalCoor;
                    });
    
                    // Suma de todas las comisiones por hoja
                    const total_comision_sum = total_comision_por_hoja.reduce((acc, val) => acc + val, 0);
    
                    // Filtrar objetos con `total_comision` cero
                    if (total_comision_sum === 0) {
                        return null; // Eliminar el objeto si total_comision es cero
                    }
    
                    // Formateo de total_comision en moneda
                    const total_comision = total_comision_sum.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
    
                    // Combina ambas relaciones y filtra por IDs únicos
                    const hoja_formalizacion_data = [
                        ...row.trabajador_hoja_vendedor,
                        ...row.trabajador_hoja_captador,
                        ...row.trabajador_hoja_responsable,
                        ...row.trabajador_hoja_coordinador,
                    ].reduce((acc, hoja) => {
                        if (!acc.some((item) => item.id === hoja.id)) {
                            const hojaModificada = { ...hoja };
    
                            // Validar el id_captador y id_agVendedor y asignar 0 si no coinciden con el ID del trabajador
                            if (hoja.id_captador !== row.id) hojaModificada.totalCaptador = 0;
                            if (hoja.id_agVendedor !== row.id) hojaModificada.totalVendedor = 0;
                            if (hoja.id_respo !== row.id) hojaModificada.totalRespo = 0;
                            if (hoja.id_coor !== row.id) hojaModificada.totalCoor = 0;
    
                            acc.push(hojaModificada);
                        }
                        return acc;
                    }, []);
    
                    return {
                        id: row.id,
                        numero_hoja: uniqueHojaIds.size,
                        total_comision,
                        nombre: row.nombre,
                        apellidos: row.apellidos,
                        hoja_formalizacion_data,
                        total: row.total
                    };
                })
                .filter(item => item !== null); // Filtrar objetos que fueron eliminados al ser total_comision cero
        } catch (error) {
            console.log(error);
        }
    };
    
    const customNameDataTable = (formattedData) => {
        // Obtener las claves del primer objeto para asignar key y label
        const keys = Object.keys(formattedData[0] || {});

        // Retornar un array de objetos con key y label
        return keys.map((key, index) => ({
            key,  // La clave original
            label: customNames[index] || key  // El nombre personalizado o la clave si no hay un nombre en customNames
        }));
    }

    const fetchData = async ({ offset, limit, filtros, orderBy, order }) => {
        setLimite(limit);
        let payload;
        if (orderBy && order) {
            payload = {
                params: { page: parseInt(offset), limit: parseInt(limit), orderBy: `${orderBy},${order}` },
                filtros
            };
        } else {
            payload = {
                params: { page: parseInt(offset), limit: parseInt(limit) },
                filtros
            };
        }

        try {
            setLoading(true);
            // console.log("Fetching data with payload:", payload);
            let response = await listTrabajadores(payload);
            // console.log("Response received:", response);
            const { meta, data } = response;
            console.log(data);

            const formattedData = formatData(data);  // Formatear los datos
            // console.log(formattedData);

            const customData = customNameDataTable(formattedData);  // Personalizar los nombres

            // console.log(formatData(data));
            setData({
                meta,
                rows: formatData(data),
            })
            setDataCustomNames(customData);

            // console.log(customData);

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    return {
        data,
        fetchData,
        loading,
        limite,
        dataCustomNames,
    };


}

export default useComisiones;