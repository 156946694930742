import React from "react";
import { Accordion, Form, InputGroup } from "react-bootstrap";
import { GetLanguageFlags } from "../../utilities";

const CategoryForm = ({
  onChange,
  title,
  languageCode,
  eventKey,
  initialValue,
}) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            alignItems: "center",
          }}
        >
          <GetLanguageFlags countryCode={languageCode} /> {title}
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div className="d-flex gap-2">
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Nombre de la categoría"
              onChange={({ target }) => onChange(target.value)}
              defaultValue={initialValue}
            />
          </InputGroup>
        </div>

        {/* <h4 style={{ alignSelf: "start", textAlign: "left" }}> Contenido</h4> */}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default CategoryForm;
