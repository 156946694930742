import { useFormik } from 'formik';
import { SnackbarUtilities } from "../utilities";
import { useState } from 'react';

// Función que encapsula la lógica del formulario
// Función para validar el formulario usando Yup
const validateForm = async (formData, validationSchema) => {
  try {
    // Validar el formulario con el esquema de Yup
    await validationSchema.validate(formData, { abortEarly: false });
    return {}; // No hay errores si la validación es exitosa
  } catch (error) {
    console.log(error);
    const errors = {};
    if (error.inner) {
      error.inner?.forEach((err) => {
        errors[err.path] = err.message;
      });
    } else {
      errors["error"] = error;
    }

    console.log('Validation errors:', errors); // Log de errores de validación
    console.log(errors);

    // SnackbarUtilities.error('Validation errors:'+ JSON.stringify(errors));
    return errors;
  }
};

// Función que encapsula la lógica del formulario
export const useFormValidation = ({ initialValues, validationSchema, onSubmit, nullConverter = false }) => {
  const [modifiedFields, setModifiedFields] = useState({});

  const validate = async (values) => {
    const errors = await validateForm(values, validationSchema);
    console.log(errors);
    // alert(JSON.stringify(errors))// Actualiza el estado de logError
    // SnackbarUtilities.error('Validation errors: '+ JSON.stringify(errors));
    return errors;
  };

  const formik = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: async (values, { setSubmitting, setErrors, setStatus }) => {
      const errors = await validate(values);
      console.log(errors);

      if (Object.keys(errors).length === 0) {
        try {
          // Si nullConverter es verdadero, convertir valores vacíos a null
          const transformedValues = nullConverter
            ? Object.fromEntries(Object.entries(values).map(([key, value]) => [key, value === '' ? null : value]))
            : values;
          const transforModifiedFields = nullConverter
            ? Object.fromEntries(Object.entries(modifiedFields).map(([key, value]) => [key, value === '' ? null : value]))
            : modifiedFields;
          console.log('Submitting values:', values); // Log de valores al enviar
          // setStatus({ success: 'Formulario enviado con éxito' }); // Seteamos un mensaje de éxito
          // await onSubmit(transformedValues); // Llama a la función de envío del formulario
          await onSubmit(transformedValues, transforModifiedFields);
        } catch (error) {
          console.error('Form submission error:', error);
          setStatus({ error: 'Ocurrió un error al enviar el formulario' }); // Seteamos un mensaje de error
        }
      } else {
        setErrors(errors); // Actualiza los errores en Formik
        setStatus({ error: 'Existen errores en el formulario' }); // Seteamos un mensaje de error general
        console.log('Formik errors:', errors);
      }
      setSubmitting(false);
    },
  });

  // Modificación del handleChange para detectar los campos cambiados
  const handleChange = (e) => {
    const { name, value } = e.target;
    setModifiedFields((prev) => ({ ...prev, [name]: value }));
    formik.handleChange(e);
  };

  return {
    handleChange,
    handleBlur: formik.handleBlur,
    handleSubmit: formik.handleSubmit,
    values: formik.values,
    errors: formik.errors,
    touched: formik.touched,
    setCustomFieldValue: formik.setFieldValue,
    setValues: formik.setValues,
    setValues: formik.setValues,
    handleReset: formik.handleReset,
    setStatus: formik.setStatus, // Devuelve el status del formulario
    status: formik.status, // Devuelve el status del formulario
    modifiedFields,
  };
};

// export const formatNumbers = (value) => {
//   if (value === null || value === undefined || value === '') return '';

//   const cleanValue = String(value).replace(/\./g, '').replace(',', '.');
//   const numberValue = parseFloat(cleanValue);

//   if (isNaN(numberValue)) return '';

//   const hasDecimals = !Number.isInteger(numberValue);

//   const options = hasDecimals
//     ? { minimumFractionDigits: 2, maximumFractionDigits: 2 }
//     : { minimumFractionDigits: 0 };

//   return numberValue.toLocaleString('de-DE', options);
//   // if (!value) return '';

//   // const cleanValue = String(value).replace(/\./g, '');
//   // const numberValue = parseFloat(cleanValue);
//   // if (isNaN(numberValue)) return '';
//   // const numberString = numberValue.toLocaleString('de-DE', { maximumFractionDigits: 2 });

//   // return numberString;

// };
export const formatNumbers = (value) => {
  if (value === null || value === undefined || value === '') return '';

  // Primero limpiamos el valor, eliminando puntos de miles y asegurándonos de que el valor sea válido
  const cleanValue = String(value).replace(/\./g, '').replace(',', '.');
  const numberValue = parseFloat(cleanValue);

  if (isNaN(numberValue)) return '';  // Si el valor no es un número válido, devolvemos vacío.

  const hasDecimals = !Number.isInteger(numberValue); // Comprobamos si el número tiene decimales

  // Definimos las opciones para los decimales
  const options = hasDecimals
    ? { minimumFractionDigits: 2, maximumFractionDigits: 2 } // Si tiene decimales, dejamos 2 decimales
    : { minimumFractionDigits: 0 }; // Si es entero, no dejamos decimales.

  // Usamos el formato de 'de-DE' para que ponga el punto de miles
  return numberValue.toLocaleString('de-DE', options);
};
