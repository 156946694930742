import React from 'react';
import { Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';


const Resumen = ({dataQueso,dataBarras}) => {
    // console.log(dataQueso,dataBarras);
    
    const sortedDates = dataBarras?.xLabels?.sort((a, b) => new Date(a) - new Date(b));
    // console.log(sortedDates);
    // console.log(dataBarras);
    if (dataBarras) {
        return (
            <Box sx={{ padding: 4, backgroundColor: '#f5f5f5' }}>
                <Paper sx={{ padding: 3, marginBottom: 4, backgroundColor: '#ffffff' }}>
                    <Typography variant="h4" gutterBottom>Resumen General</Typography>
                    <Typography variant="h5" sx={{ marginTop: 2 }}>Total Precios</Typography>
                    <Typography variant="h6" sx={{ marginTop: 2 }}>€{dataQueso.totalPrecios.toLocaleString()}</Typography>
                </Paper>
    
                <Paper sx={{ padding: 3, backgroundColor: '#ffffff' }}>
                    <Typography variant="h5" gutterBottom>Resumen por Sucursal</Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Color</TableCell>
                                    <TableCell>Sucursal</TableCell>
                                    <TableCell>Total Sucursal (€)</TableCell>
                                    <TableCell>Porcentaje (%)</TableCell>
                                    {sortedDates.map((label, index) => (
                                        <TableCell key={index}>{label}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.entries(dataQueso.groupedPrices).map(([sucursal, info]) => {
                                    const serie = dataBarras.series.find(serie => serie.label === sucursal);
                                    return (
                                        <TableRow key={sucursal}>
                                            <TableCell>
                                                <Box
                                                    sx={{
                                                        width: 20,
                                                        height: 20,
                                                        backgroundColor: serie?.color || '#000'
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>{sucursal}</TableCell>
                                            <TableCell>{info.totalSucursal.toLocaleString()}</TableCell>
                                            <TableCell>{info.porcentaje.toFixed(2)}%</TableCell>
                                            {serie?.data.map((value, index) => (
                                                <TableCell key={index}>{value.toLocaleString()}</TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
        );
    }else {
        return (
            <div>No hay datos disponibles</div>
        )
    }

};

export default Resumen;
