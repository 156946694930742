import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AxiosInterceptor } from "./interceptors/axios.interceptor";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilitiesConfigurator } from "./utilities";
import "./index.css";
import { UserProvider } from "./hooks/UserContext";
import { UserProviderLogin } from "./hooks/UserContextLogin";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Forum from '../src/fonts/Forum-Regular.ttf';
import Spartan from '../src/fonts/LeagueSpartan-VariableFont_wght.ttf';
import Login from "./components/login/Login";
import Password from "./components/password/Password";
import Verify from "./components/verify/Verify";
import ResetPassword from "./components/password/resetPassword";
import RecoverPass from "./components/password/RecoverPass";
// import EnviarMail from "./components/password/enviarMail";
AxiosInterceptor();

let state = localStorage.getItem("state");
const theme = createTheme({
  typography: {
    fontFamily: 'Spartan, Forum',
    fontSize: 16,
    h1: {
      fontFamily: 'Forum',
    },
    h2: {
      fontFamily: 'Forum',
    },
    h3: {
      fontFamily: 'Forum',
    },
    h4: {
      fontFamily: 'Forum',
    },
    h5: {
      fontFamily: 'Forum',
    },
    h6: {
      fontFamily: 'Forum',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Spartan';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Spartan'), local('Spartan-Regular'), url(${Spartan}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Forum';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Forum'), local('Forum-Regular'), url(${Forum}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

ReactDOM.render(
  <>
  {/* <ThemeProvider theme={theme}> */}
    <UserProvider>
      <UserProviderLogin>
      <SnackbarProvider
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <SnackbarUtilitiesConfigurator />
        <Router>
          <Routes>
            <Route path="/verified/:token" element={<Verify/>} />
            <Route path="/login" element={<Login />} />
            <Route path="/change-password" element={<Password />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/recover-password" element={<RecoverPass />} />
            <Route path="/*" element={<App />} />
          </Routes>
        </Router>
      </SnackbarProvider>
      </UserProviderLogin>
  </UserProvider>
  {/* </ThemeProvider> */}
  </>  ,
  document.getElementById("root")
);
serviceWorker.unregister();
