import React from "react";
const Paginacion = ({
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  pageIndex,
  pageOptions,
}) => {
  return (
    <div style={{ textAlign: "center" }}>
      <button
        className="btn btn-outline-dark btn-surrounded"
        onClick={previousPage}
        disabled={!canPreviousPage}
      >
        &laquo;
      </button>
      <span style={{ margin: "0 10px" }}>
        Página{" "}
        <strong>
          {pageIndex + 1} de {pageOptions.length}
        </strong>
      </span>
      <button
        className="btn btn-outline-dark"
        onClick={nextPage}
        disabled={!canNextPage}
      >
        &raquo;
      </button>
    </div>
  );
};

export default Paginacion;
