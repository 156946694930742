import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { TextField, Button, Select, FormControl, InputLabel, MenuItem, CircularProgress } from '@mui/material';
import { FaSearch } from "react-icons/fa";
import { MdCleaningServices } from "react-icons/md";
import * as Yup from 'yup';
import provinces from "@doncicuto/es-provinces";
import axios from 'axios';
import { useSucursales } from '../../hooks';
import { useFormValidation } from '../../utilities/formValidator';
import municipalitiesWithProvinces from "../../utilities/municipalitiesWithProvinces.json";
import { listSucursales, sucursalDetails } from '../../services';
import {  SnackbarUtilities } from "../../utilities";


const FiltrosPropiedades = ({fetchData,resetPagination,setResetPagination}) => {
    // const { setLoading, data: { rows: sucursal } = {}, fetchData } = useSucursales();
    const [provinceSelected, setProvinceSelected] = useState(null);
    const [municipios, setMunicipios] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sucursalMap, setSucursalMap] = useState(null);

    const initialValues = {
        referencia: '',
        agente: '',
        provincia: '',
        municipio: '',
        from_mls: '',
        sucursal: '',
        conservacion: '',
        published: '',
    };

    const validationSchema = Yup.object().shape({
        referencia: Yup.string().nullable(),
        agente: Yup.string().nullable(),
        provincia: Yup.string().nullable(),
        municipio: Yup.string().nullable(),
        from_mls: Yup.string().nullable(),
        sucursal: Yup.string().nullable(),
        conservacion: Yup.string().nullable(),
        published: Yup.string().nullable(),
    });

    // Usa la función de validación personalizada
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setCustomFieldValue, setValues, handleReset } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        nullConverter: true,
        onSubmit: async (values) => {
            if (values) {
                console.log(values);
                fetchData({ offset: 1, limit: 1000, filtros: values });
            } else {
                SnackbarUtilities.error("Error en algun validador del campo");
            }
        },
    });

    const handleCustomChange = (event) => {
        const { name, value, type, checked } = event.target;
        // console.log(name, value, type, checked);

        if (name == "provincia") {
            const selectedProvince = provinces.filter((p) => p.name == value);
            if (selectedProvince) {
                // console.log(selectedProvince[0].code);
                setProvinceSelected(selectedProvince[0]?.code);
                handleChange(event)
            }
        }
    }
    useEffect(() => {
        if (provinceSelected) {
            setLoading(true);

            // Filtrar municipios basados en la provincia seleccionada
            const filteredMunicipalities = municipalitiesWithProvinces.filter(
                (m) => m.provCode == provinceSelected
            );

            setMunicipios(filteredMunicipalities);
            setLoading(false);
        } else {
            setMunicipios([]);
        }
    }, [provinceSelected]);

    const fetchSucursales = async () => {
        const response = await listSucursales({ offset: 1, limit: 1000 });
        // console.log(response);
        
        setSucursalMap(response.data || []);
    };

    useEffect(() => {
        fetchSucursales();
    }, [])

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <form onSubmit={handleSubmit}>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: { sm: '1fr 1fr 1fr 1fr 1fr' },
                        gap: 2,
                    }}>
                        {
                            (process.env.NODE_ENV == "development" && Object.keys(errors).length > 0) &&
                            <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                                {JSON.stringify(errors)}
                            </div>
                        }
                        <div>
                            <TextField
                                id="input-with-icon-textfield"
                                label="Referencia"
                                margin="normal"
                                name="referencia"
                                sx={inputStyles}
                                size="small"
                                value={values.referencia}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.referencia && Boolean(errors.referencia)}
                                helperText={touched.referencia && errors.referencia}
                            />
                        </div>
                        <div>
                            <TextField
                                id="input-with-icon-textfield"
                                label="Agente"
                                margin="normal"
                                name="agente"
                                sx={inputStyles}
                                size="small"
                                value={values.agente}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.agente && Boolean(errors.agente)}
                                helperText={touched.agente && errors.agente}
                            />
                        </div>
                        <div >
                            <TextField
                                id="demo-simple-select-label"
                                select
                                label="Provincia"
                                name="provincia"
                                margin="normal"
                                sx={inputStyles}
                                value={values.provincia}
                                size="small"
                                onChange={handleCustomChange}
                                onBlur={handleBlur}
                                error={touched.provincia && Boolean(errors.provincia)}
                                helperText={touched.provincia && errors.provincia}
                            >
                                {provinces.map((item, index) => (
                                    <MenuItem key={index} value={item.name}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div >
                            <TextField
                                id="demo-simple-select-label"
                                select
                                disabled={provinceSelected ? false : true}
                                label="Municipio"
                                name="municipio"
                                margin="normal"
                                sx={inputStyles}
                                value={values.municipio}
                                size="small"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.municipio && Boolean(errors.municipio)}
                                helperText={touched.municipio && errors.municipio}
                            >
                                {loading ? (
                                    <MenuItem disabled>
                                        <CircularProgress size={24} />
                                    </MenuItem>
                                ) : (
                                    municipios.map((item, index) => (
                                        <MenuItem key={index} value={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    ))
                                )}
                            </TextField>
                        </div>
                        <div >
                            <TextField
                                id="demo-simple-select-label"
                                select
                                label="Colaborador"
                                name="from_mls"
                                margin="normal"
                                sx={inputStyles}
                                value={values.from_mls}
                                size="small"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.from_mls && Boolean(errors.from_mls)}
                                helperText={touched.from_mls && errors.from_mls}
                            >
                                {["INMOVILLA", "ASICVAL", "RESALES"].map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div >
                            <TextField
                                id="demo-simple-select-label"
                                select
                                label="Sucursales"
                                name="sucursal"
                                margin="normal"
                                sx={inputStyles}
                                value={values.sucursal}
                                size="small"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.sucursal && Boolean(errors.sucursal)}
                                helperText={touched.sucursal && errors.sucursal}
                            >
                                {sucursalMap ? sucursalMap.map((option) => (
                                    <MenuItem key={option.id} value={option.id_ext}>
                                        {option.nombre_sucursal ?? "LAE HOMES - " + option.municipio}
                                    </MenuItem>
                                )) : <MenuItem disabled>No hay oficinas disponibles</MenuItem>}

                            </TextField>

                        </div>
                        <div >
                            <TextField
                                id="demo-simple-select-label"
                                select
                                label="Estado"
                                name="conservacion"
                                margin="normal"
                                sx={inputStyles}
                                value={values.conservacion}
                                size="small"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.conservacion && Boolean(errors.conservacion)}
                                helperText={touched.conservacion && errors.conservacion}
                            >
                                {['Para reformar', 'De origen', 'Reformar Parcialmente', 'Entrar a vivir', 'Buen estado', 'Semireformado', 'Reformado', 'Seminuevo', 'Nuevo', 'Obra Nueva', 'En construcción', 'En proyecto'].map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div >
                            <TextField
                                id="demo-simple-select-label"
                                select
                                label="Publicado"
                                name="published"
                                margin="normal"
                                sx={inputStyles}
                                value={values.published}
                                size="small"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.published && Boolean(errors.published)}
                                helperText={touched.published && errors.published}
                            >
                                {[{name:'Sí',value:true}, {name:'No',value:false}].map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div style={{ display: "flex", flex: 0.5, justifyContent: "start", gap: '10px' }}>

                            <Button
                                type="submit"
                                variant="outlined"
                                sx={{
                                    color: '#c4af6c',
                                    backgroundColor: '#33383b',
                                    height: 40,
                                    borderColor: 'transparent',
                                    '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                                }}
                            >
                                <FaSearch style={{ color: '#c4af6c', '&:hover': { color: '#33383b' } }} />
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{
                                    color: '#33383b',
                                    backgroundColor: '#c4af6c',
                                    borderColor: 'transparent',
                                    '&:hover': { color: '#c4af6c', backgroundColor: '#fff', borderColor: '#33383b', }
                                }}
                                onClick={() => {
                                    setResetPagination(true); 
                                    handleReset();
                                }}
                            >
                                <MdCleaningServices style={{ color: '#33383b', '&:hover': { color: '#c4af6c' } }} />
                            </Button>
                        </div>
                    </Box>
                </form>
            </Box>
        </>
    );
};

const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
    width: "100%",
    marginBottom: 0,
    marginTop: 0
};

export default FiltrosPropiedades;