import React, { useState } from "react";
import  Select  from "react-select";
import { FaFloppyDisk, FaX } from "react-icons/fa6";
import ReactFlagsSelect, {Gb, Es} from "react-flags-select";
import { Row, Col } from "react-bootstrap";
import { GetLanguageFlags, SnackbarUtilities } from "../utilities";
// import "react-flags-select/css/react-flags-select.css";
const TrFormulario = ({ onSave, idiomas, check, onCancel, isEditing, initialValues }) => {
    const [nombre, setNombre] = useState(initialValues?.nombre || "");
    const [seccion, setSeccion] = useState('');
    const [archivo, setArchivo] = useState(null);
    const [id_idioma, setIdiomaSeleccionado] =  useState(initialValues?.id_idioma || "");;
    const secciones = [
        'proyecto',
        'ubicacion',
        'entorno',
        'disenyo'
    ]
    const handleFileChange = (file) => {
        setArchivo(file);
    }
    const handleSave = () => {
    
        onSave({seccion, nombre, archivo, id_idioma });
        // setSeccion('');
        // setNombre("");
        // setArchivo(null);
        // setIdiomaSeleccionado("");
    };
    const handleEdit = () => {
        onSave({ id:initialValues.id, seccion, nombre, archivo, id_idioma });
        setSeccion('');
        setNombre("");
        setArchivo(null);
        setIdiomaSeleccionado("");
    };
    const options = idiomas.map((idi, i) => {
        return {
            value: idi.id,
            label: <GetLanguageFlags
            countryCode={idi?.iso_code}
            key={idi.id}/>
        };
    });
    return (
    <>
        <tr>
            <td>
                <input type="checkbox" className="form-check-input" /*name={`${i}`} id={`seleccionar${i}`}*/ onChange={check} />
            </td>
            <td>
                <Row>
                    <Col lg={12}>
                        <select className="form-control" type="text" defaultValue={seccion} onChange={(e) => setSeccion(e.target.value)}>
                            <option value={""}>Elije la sección</option>
                            {secciones.map(s => (
                                <option value={s}>{s == "disenyo" ? "diseño" :s}</option>
                            ))}
                        </select> 
                    </Col>
                </Row>
            </td>
            <td> 
                <Row>
                    
                    <Col lg={4}>
                        <input className="form-control" placeholder="Nombre" type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} />       
                    </Col>
                    <Col lg={6}>
                        <div className="custom-file-input">
                            <input
                                type="file"
                                id="fileInput"
                                name={nombre}
                                onChange={(e) => handleFileChange(e.target.files[0])}
                            />
                            <label htmlFor="fileInput">Añadir archivo</label>
                            <span id="fileName" data-file-name={archivo?.name}>
                                {archivo?.name}
                            </span>
                        </div>
                        {/* <input type="file" onChange={(e) => setArchivo(e.target.files[0])} /> */}
                    </Col>
                </Row> 
            </td>
            <td>
                <Select
                    placeholder="Selecciona idioma"
                    value={options.find((option) => option.value === id_idioma)}
                    options={options}
                    onChange={(selectedOption) => setIdiomaSeleccionado(selectedOption.value)}
                />
            </td>
            <td>
                <>
                </>
                {isEditing ? (
                    <>
                        <button className="btn btn-outline-success" onClick={handleEdit}>
                            <FaFloppyDisk/>
                        </button>
                        <button className="btn btn-outline-secondary" onClick={onCancel}>
                            <FaX />
                        </button>
                    </>
                ) : (
                    <button className="btn btn-outline-success" onClick={handleSave}>
                        <FaFloppyDisk/>
                    </button>
                )}
               
            </td>
        </tr>
        <style jsx="true">{`
    
            .custom-file-input {
                position: relative;
                display: inline-block;
            }
            
            .custom-file-input input[type="file"] {
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
            
            .custom-file-input label {
                display: inline-block;
                padding: 8px 12px;
                background-color: #33383b;
                color: #fff;
                cursor: pointer;
                border-radius: 4px;
            }
            
            #fileName {
                margin-left: 8px; /* Ajusta el valor según el espacio que desees entre el label y el span */
            }
        
            `}
            
        </style>
    </>);
}

export default TrFormulario;