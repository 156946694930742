import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/navbar/Breadcrumb";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useSucursales } from '../../hooks';
import {
    getHojaForm,
    generateRef,
    createHojaForm,
    updateHojaForm,
    verifyHojaForm
} from '../../services';
import {
    Button,
    IconButton,
    Box,
    Paper,
    TextField,
    InputAdornment,
    Autocomplete,
    Divider,
    Radio,
    RadioGroup,
    FormControlLabel,
    Typography,
    Checkbox,
    Grid,
    Popover,
    Select,
    FormControl,
    MenuItem
} from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import {
    Person,
    Save
} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { FaSearch } from "react-icons/fa";
import { formatDateToHF, getCurrentDate, SnackbarUtilities } from "../../utilities";
import {
    DrawerPropiedades,
    DrawerClientes,
    DrawerContactos,
    DrawerAaff,
    DrawerTrabajadores
} from "../../components";

import {
    HojaClienteForm,
    HojaImporteForm,
    HojaPropiedadForm,
    HojaRepartosForm,
    HojaPrescriptorForm,
} from "../../components/Forms";
import { useHojasForm, useUser } from "../../hooks";

import DeleteIcon from '@mui/icons-material/Delete';
import {formatNumbers} from '../../utilities/formValidator';

const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
};
const styledButton = {
    backgroundColor: '#33383b',
    color: '#c4af6c',
    '&:hover': {
        backgroundColor: '#c4af6c',
        color: '#33383b',
    },
};
const paperStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginBottom: '10px',
    paddingLeft: '10px'
};
const CheckboxStyles = {
    '&.Mui-checked': {
        color: '#c4af6c',
    },
    '& .MuiSvgIcon-root': {
        color: '#33383b',
        fontSize: 28
    },
    '&:hover': {
        backgroundColor: 'rgba(196, 175, 108, 0.04)',
    },
};
const iconButtonStyles = {
    // backgroundColor: 'transparent',
    // color: 'red',
    // '&:hover': {
    //     color: 'darkred'
    // }
    backgroundColor: '#33383b',
    color: '#c4af6c',
    '&:hover': {
        backgroundColor: '#c4af6c',
        color: '#33383b',
    },
};

const HojaFormalizacion = () => {
    const user = useUser();
    const { rol } = user?.user;

    const { id } = useParams();
    const [formData, setFormData] = useState({
        propiedades: [],
        aaff: [],
        colaboradores: [],
    });
    const location = useLocation();
    const { sucursal = null } = location?.state ?? {};
    // console.log(sucursal);

    // const sucursal = null
    const { formatUnicData } = useHojasForm();
    const fechaOperacion = getCurrentDate();
    const [load, setLoad] = useState(true)
    const { setLoading, data, loading, fetchData } = useSucursales();
    const [haya, setHaya] = useState(false);
    const [isCompartida, setIsCompartida] = useState(false);
    const [sucursalChoosed, setSucursalChoosed] = useState(null);
    const [propiedades, setPropiedades] = useState([]);
    const [colaborador, setColaborador] = useState([]);
    const [coordinador, setCoordinador] = useState({});
    const [responsable, setResponsable] = useState({});
    const [importe, setImporte] = useState(0);
    const [comision, setComision] = useState(0);
    const [totalComision, setTotalComision] = useState(0);
    const [sidePropOpen, setSidePropOpen] = useState(false);
    const [sideCompradorOpen, setSideCompradorOpen] = useState(false);
    const [sideVendedorOpen, setSideVendedorOpen] = useState(false);
    const [sideColaboradorOpen, setColaboradores] = useState(false);
    const [sideAaffOpen, setSideAaffOpen] = useState(false);
    const [sideCaptadorOpen, setSideCaptadorOpen] = useState(false);
    const [sideAgenteVendedorOpen, setSideAgenteVendedorOpen] = useState(false);
    const [vinculo, setVinculo] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const ide = open ? 'simple-popover' : undefined;
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const chooseSucursal = async (value) => {

        const id_sucursal = value?.id;
        const { responsable, coordinador } = value;
        setSucursalChoosed(sucursal || value);
        const ref = await generateRef(sucursal || id_sucursal);
        setResponsable(responsable);
        setCoordinador(coordinador);
        setFormData(prevState => ({
            ...prevState,
            ["referencia"]: ref,
            ["id_coor"]: coordinador?.id,
            ["id_respo"]: responsable?.id,
            ["id_sucursal"]: sucursal || id_sucursal
        }));
    };
    const calculaImmporte = (propiedad, index, isAdding) => {
        let totalImporte = parseFloat(propiedad?.precioinmo) || parseFloat(propiedad?.importe) || 0;
        let updatedImporte =  0 ;
       
        if(id){
            if(isAdding){
                setImporte(prevImporte =>  prevImporte + totalImporte);
                setFormData(prevState => ({
                    ...prevState,
                    ["total"]: prevState.total + totalImporte
                }));
            } else {
                console.log(totalImporte);
                setImporte(prevImporte =>  prevImporte - totalImporte);
                setFormData(prevState => ({
                    ...prevState,
                    ["total"]: prevState.total - totalImporte
                }));
            }
           
            setFormData(prevState => ({
                ...prevState,
                total: prevState.total || 0
            }));
        }else{
            
            updatedImporte = isAdding ? importe + totalImporte : importe - totalImporte;
            setImporte(updatedImporte);
            setFormData(prevState => ({
                ...prevState,
                ["total"]: updatedImporte
            }));
        }
    };
    const handleBlur = (propiedad, index) => {
        const nuevasPropiedades = propiedades.map((prop, idx) =>
            idx === index ? { ...prop, importe: propiedad.importe } : prop
        );
        const total = nuevasPropiedades.reduce((acc, prop, idx) => {
            const importeStr = typeof prop?.importe === 'string' ? prop.importe.replace(/\./g, '') : prop.importe;
            const precioStr = typeof prop?.precioinmo === 'string' ? prop.precioinmo.replace(/\./g, '') : prop.precioinmo;
            
            const valor = parseFloat(importeStr) || parseFloat(precioStr) || 0;
            
            if (idx === index) {
               setFormData(prevState => ({
                    ...prevState,
                    propiedades: Array.isArray(prevState.propiedades) ?
                    prevState.propiedades.map((prop, idx) =>
                        idx === index ? { ...prop, ['importe']: valor } : prop
                    ) : []
                }));
            }
            return acc + valor;
        }, 0);
        setImporte(total);
        setFormData(prevState => ({
            ...prevState,
            total, 
        }));
    };
    const handleChange = (e, propiedad, index) => {
        const { name, value } = e.target;
        let tipoCom = formData?.tipoCom /*parseFloat(formData.tipoCom) / 100 ||  0*/;
        let porLae = parseFloat(formData.porLae) / 100 || 0;
        let porAaff = Array.isArray(formData.aaff) ? formData.aaff[0]?.porAaff || '' : formData?.aaff?.porAaff || '';
        let porCola = Array.isArray(formData.colaboradores) ? formData.colaboradores[0]?.porCola || 0 : formData?.colaboradores?.porCola || 0;
        let porCap = parseFloat(formData.porCaptador) / 100 || 0;
        let porVend = parseFloat(formData.porVendedor) / 100 || 0;
        let porCoor = parseFloat(formData.porCoor) / 100 || 0;
        let porRespo = parseFloat(formData.porRespo) / 100 || 0;
        // console.log(vinculo);
       
        if(name === 'fechaOperacion'){
            setFormData(prevState => ({
                ...prevState,
                [name]: value || getCurrentDate()
            }));
        }
        setFormData(prevState => ({
            ...prevState,
            ["total"]: importe,
        }));

        if (name === 'accion') {
            setFormData(prevState => ({ ...prevState, [name]: value }));
        };
        if (name === 'haya') {
            setHaya(!haya);
            setFormData(prevState => ({ ...prevState, [name]: e.target?.checked }));
        };
        if (name === 'opCompartida') {
            const isCompartida = e.target?.checked;
            setIsCompartida(isCompartida);
            setFormData(prevState => ({
                ...prevState,
                [name]: isCompartida,
                porLae: !isCompartida ? 0 : prevState.porLae
            }));
            let updatedPorLae = 0;
            if (isCompartida === true) {
                updatedPorLae = formData.porLae / 100;
            }else{
                updatedPorLae = 0;
                setFormData(prevState => ({
                    ...prevState,
                    porLae: 0
                }));
            }
            recalcularValores(isCompartida, tipoCom, updatedPorLae, porCoor, porRespo, porAaff, porCap, porVend, porCola);

        };
        if(name === 'comision'){
            const parsedValue = parseFloat(value);
            if (!importe || importe === 0 || isNaN(parsedValue)) {
                setComision(0);
                return;
            }
            setComision(parsedValue);
            
            let el_porcentaje = (parsedValue / importe) * 100;
            el_porcentaje = parseFloat(el_porcentaje.toFixed(2)); 
            
            setFormData(prevState => ({
                ...prevState,
                tipoCom: el_porcentaje,
                totalComisionNeta: parsedValue,  
            }));
            setTotalComision(parsedValue);
            console.log(parsedValue)
            recalcularValores(isCompartida, el_porcentaje, porLae, porCoor, porRespo, porAaff, porCap, porVend, porCola);
        }
        if ([`direccion_propiedad_${index}`, `municipio_propiedad_${index}`, `cp_propiedad_${index}`, `promocion_propiedad_${index}`,`importe_propiedad_${index}` ].includes(name)) {
            const campoMatch = name.match(/(.+)_propiedad_\d+$/);
            const campo = campoMatch ? campoMatch[1] : null;
            console.log(`index: ${index} name: ${name}, value: ${value}`);
            if (campo) { 
                setFormData(prevState => ({
                    ...prevState,
                    propiedades: Array.isArray(prevState.propiedades) ?
                        prevState.propiedades.map((prop, idx) =>
                            idx === index ? { ...prop, [campo]: value } : prop
                        ) : []
                }));
                if(campo == 'importe'){
                    setFormData(prevState => ({
                        ...prevState,
                        propiedades: Array.isArray(prevState.propiedades) ?
                            prevState.propiedades.map((prop, idx) =>
                                idx === index ? { ...prop, [campo]: value } : prop
                            ) : []
                    }));
                }
                setPropiedades(prevState =>
                    prevState.map((prop, idx) =>
                        idx === index ? { ...prop, [campo]: value } : prop
                    )
                );
            };
               
        };
        if (['tipoCom', 'porLae','porCoor', 'porRespo', 'porAaff', 'porCola', 'porCaptador', 'porVendedor'].includes(name)) {
            const parsedValue = parseFloat(value) / 100;
          
            if (name === 'porCoor') {
                porCoor = parsedValue; 
            }
            if (name === 'porRespo') {
                porRespo = parsedValue;
            }
            if (name === 'porCaptador') {
                porCap = parsedValue;
            }
            if (name === 'porAaff') {
                setFormData(prevState => {
                    const newFormData = { ...prevState };
                    if (Array.isArray(newFormData.aaff)) {
                        if (newFormData.aaff.length > 0) {
                            newFormData.aaff[0].porAaff = value;
                        } else {
                            newFormData.aaff.push({ porAaff: value });
                        }
                    } else if (typeof newFormData.aaff === 'object' && newFormData.aaff !== null) {
                        newFormData.aaff.porAaff = value;
                    } else {
                        newFormData.aaff = { porAaff: value };
                    }

                    return newFormData;
                });
                porAaff = value;
                recalcularValores(isCompartida, tipoCom, porLae, porCoor, porRespo, porAaff, porCap, porVend, porCola);
            } 
            if (name === 'porCola') {
                setFormData(prevState => {
                    const newFormData = { ...prevState };
                    if (Array.isArray(newFormData.colaboradores)) {
                        if (newFormData.colaboradores.length > 0) {
                            newFormData.colaboradores[0].porCola = parseFloat(value);
                        } else {
                            newFormData.colaboradores.push({ porCola: parseFloat(value) });
                        }
                    } else if (typeof newFormData.colaboradores === 'object' && newFormData.colaboradores !== null) {
                        newFormData.colaboradores.porCola = parseFloat(value);
                    } else {
                        newFormData.colaboradores = { porCola: parseFloat(value) };
                    }

                    return newFormData;
                });
                porCola = value;

                recalcularValores(isCompartida, tipoCom, porLae, porCoor, porRespo, porAaff, porCap, porVend, porCola);
            }
            if (name === 'porVendedor') {
                porVend = parsedValue;
            }
            if (name === 'tipoCom') {
                tipoCom = value; 
                const nuevaComision = importe * (tipoCom / 100); 
                setComision(parseInt(nuevaComision));
                setTotalComision(parsedValue);

            }
            if (name === 'porLae') {
                porLae = parsedValue;
            }
            setFormData(prevState => ({ ...prevState, [name]: value }));
            recalcularValores(isCompartida, tipoCom, porLae, porCoor, porRespo, porAaff, porCap, porVend, porCola);
        }
        if (name === 'vinculo') {
            setVinculo(value);
            setFormData(prevState => ({ ...prevState, [name]: value }));
            recalcularValores(isCompartida, tipoCom, porLae, porCoor, porRespo, porAaff, porCap, porVend, porCola);
        }
        const UpdateedProps = propiedades?.map(prop => {
            return {
                id_prop: prop?.id,
                cp: prop?.cp,
                direccion: prop?.direccion,
                promocion: prop?.promocion,
                municipio: prop?.municipio,
                importe: prop?.precioinmo || prop?.importe,
            }
        });
        setFormData(prevState => ({
            ...prevState,
            ["id_captador"]: formData.captador?.id,
            ["id_comprador"]: formData.comprador?.id,
            ["id_agVendedor"]: formData?.agenteVendedor?.id,
            ["id_vendedor"]: formData.vendedor?.id,
            ["propiedades"]: UpdateedProps

        }));
    };
    const recalcularValores = (
        isCompartida = false,
        tipoCom = 0,
        porLae = 0,
        porCoor = 0,
        porRespo = 0,
        porAaff = 0,
        porCap = 0,
        porVend = 0,
        porCola = 0,
    ) => {
        const importeNeto = comision * (1 - porLae);
        const totalComision = comision;
        const totalComisonNeta = isCompartida && porLae > 0 ? comision - importeNeto : comision;
        const importeCoordinador = parseFloat((totalComisonNeta * porCoor).toFixed(2));
        const importeResponsable = parseFloat((totalComisonNeta * porRespo).toFixed(2));
        const importeCaptador = parseFloat((totalComisonNeta * porCap).toFixed(2));
        const importeVendedor = parseFloat((totalComisonNeta * porVend).toFixed(2));
        const importeAaff = parseFloat((totalComisonNeta * porAaff).toFixed(2)) / 100 ;
        let sumRestos = 0;
       
        const importeColaborador = parseFloat((
            vinculo === 'AAFF' ? importeAaff
            :
            vinculo === 'VENDEDOR' ? importeVendedor
            :
            vinculo === 'CAPTADOR' ? importeCaptador
            :
            totalComisonNeta) * parseFloat(porCola) / 100).toFixed(2);
        
        sumRestos = importeCoordinador + importeResponsable + importeAaff + importeCaptador + importeVendedor;
        if (formData.aaff.length > 0) {
            formData.aaff = formData.aaff.map(aaffItem => {
                return {
                    ...aaffItem,
                    porAaff: porAaff,
                    totalAaff: vinculo === "AAFF" ? importeAaff - importeColaborador : importeAaff
                };
            });
            setFormData(prevState => ({
                ...prevState,
                ["aaff"]:  formData.aaff,
            }));
        }
        if (formData.colaboradores.length > 0) {
            formData.colaboradores = formData.colaboradores.map(aaffItem => {
                return {
                    ...aaffItem,
                    porCola: porCola,
                    totalCol: importeColaborador
                };
            });

            setFormData(prevState => ({
                ...prevState,
                ["colaboradores"]:  formData.colaboradores,
            }));
        }
        setFormData(prevState => ({
            ...prevState,
            ["total"]: importe,
            ["totalCoor"]: importeCoordinador,
            ["totalRespo"]: importeResponsable,
            ["totalCaptador"]: vinculo === 'CAPTADOR' ? importeCaptador - importeColaborador : importeCaptador,
            ["totalVendedor"]: vinculo === 'VENDEDOR' ? importeVendedor - importeColaborador : importeVendedor,
            ["comisionTotal"]: totalComision,
            ["comisionLae"]: totalComisonNeta,
            ["totalComisionNeta"]: isCompartida ? totalComisonNeta - sumRestos : totalComision - sumRestos,
        }));
    };
    const handeEditChange = (e , propiedad, index) => {
        const { name, value } = e.target || {};

        let tipoCom = formData?.tipoCom /*parseFloat(formData.tipoCom) / 100 ||  0*/;
        let porLae = parseFloat(formData.porLae) / 100 || 0;
        let porAaff = Array.isArray(formData.aaff) ? formData.aaff[0]?.porAaff || '' : formData?.aaff?.porAaff || '';
        let porCola = Array.isArray(formData.colaboradores) ? formData.colaboradores[0]?.porCola || 0 : formData?.colaboradores?.porCola || 0;
        let porCap = parseFloat(formData.porCaptador) / 100 || 0;
        let porVend = parseFloat(formData.porVendedor) / 100 || 0;
        let porCoor = parseFloat(formData.porCoor) / 100 || 0;
        let porRespo = parseFloat(formData.porRespo) / 100 || 0;
       
        if (name === 'total') {
            setFormData(prevState => ({
                ...prevState,
                ["total"]: value.trim() === '' ? '' : parseInt(value),
            }));
            setImporte(value.trim() === '' ? '' : parseInt(value))
            recalcularValoresToEdit(
                isCompartida,
                tipoCom,
                porLae, porCoor, porRespo, porAaff, porCap, porVend, porCola
            );
        }
        if (name === 'accion') {
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }
        if (name === 'haya') {
            setHaya(!haya);
            setFormData(prevState => ({ ...prevState, [name]: e.target?.checked }));
        }
        if (name === 'opCompartida') {
            const isCompartida = e.target?.checked;
            setIsCompartida(isCompartida);
            setFormData(prevState => ({
                ...prevState,
                [name]: isCompartida,
                porLae: !isCompartida ? 0 : prevState.porLae
            }));
            let updatedPorLae = 0;
            if (isCompartida === true) {
                updatedPorLae = formData.porLae / 100;
            }else{
                updatedPorLae = 0;
                setFormData(prevState => ({
                    ...prevState,
                    porLae: 0
                }));
            }
            recalcularValoresToEdit(isCompartida, tipoCom, updatedPorLae, porCoor, porRespo, porAaff, porCap, porVend, porCola);
        }
        if(name === 'comision'){
            const parsedValue = parseFloat(value);
            if (/*!importe || importe === 0 || */isNaN(parsedValue)) {
                setComision(0);
                return;
            }
            setComision(parsedValue);
            
            let el_porcentaje = (parsedValue / importe) * 100;
            el_porcentaje = parseFloat(el_porcentaje.toFixed(2)); 
            
            setFormData(prevState => ({
                ...prevState,
                tipoCom: el_porcentaje,
                totalComisionNeta: parsedValue,  
            }));
            setTotalComision(parsedValue);
            recalcularValoresToEdit(isCompartida, el_porcentaje, porLae, porCoor, porRespo, porAaff, porCap, porVend, porCola);
        }
        if ([`direccion_propiedad_${index}`, `municipio_propiedad_${index}`, `cp_propiedad_${index}`, `promocion_propiedad_${index}`,`importe_propiedad_${index}` ].includes(name)) {
            const campoMatch = name.match(/(.+)_propiedad_\d+$/);
            const campo = campoMatch ? campoMatch[1] : null;
            console.log('Campo:', campo);
            console.log('Valor:', value);
            if (campo) {
                setFormData(prevState => ({
                    ...prevState,
                    propiedades: Array.isArray(prevState.propiedades) ?
                        prevState.propiedades.map((prop, idx) =>
                            idx === index ? { ...prop, [campo]: value } : prop
                        ) : []
                }));
                console.log('FormData.propiedades actualizadas:', formData.propiedades);
                setPropiedades(prevState =>
                    prevState.map((prop, idx) =>
                        idx === index ? { ...prop, [campo]: value } : prop
                    )
                );
            };
           
        };
        if (['tipoCom', 'porLae','porCoor', 'porRespo', 'porAaff', 'porCola', 'porCaptador', 'porVendedor'].includes(name)) {
            const parsedValue = parseFloat(value) / 100;
            if (name === 'porCoor') {
                porCoor = parsedValue;
                setFormData(prevState => ({ ...prevState, [name]: value })); 
            }
            if (name === 'porRespo') {
                porRespo = parsedValue;
                setFormData(prevState => ({ ...prevState, [name]: value }));
            }
            if (name === 'porCaptador') {
                porCap = parsedValue;
                setFormData(prevState => ({ ...prevState, [name]: value }));
            }
            if (name === 'porCola') {

                setFormData(prevState => {
                    const newFormData = { ...prevState };
                    if (Array.isArray(newFormData.colaboradores)) {
                        if (newFormData.colaboradores.length > 0) {
                            newFormData.colaboradores[0].porCola = value;
                        } else {
                            newFormData.colaboradores.push({ porCola: value });
                        }
                    } else if (typeof newFormData.colaboradores === 'object' && newFormData.colaboradores !== null) {
                        newFormData.colaboradores.porCola = value;
                    } else {
                        newFormData.colaboradores = { porCola: value };
                    }

                    return newFormData;
                });
                porCola = value;

                recalcularValoresToEdit(isCompartida, tipoCom, porLae, porCoor, porRespo, porAaff, porCap, porVend, porCola);
                // recalcularValoresToEdit(isCompartida, tipoCom, porLae, porPres, porCola, porCaptador, porVendedor);
            }
            if (name === 'porAaff') {
                porAaff = value;
                recalcularValoresToEdit(isCompartida, tipoCom, porLae, porCoor, porRespo, porAaff, porCap, porVend, porCola);
            }
            if (name === 'porVendedor') {
                porVend = parsedValue;
                setFormData(prevState => ({ ...prevState, [name]: value }));
            }
            if (name === 'tipoCom') {
                tipoCom = parsedValue;
                setFormData(prevState => ({ ...prevState, [name]: value }));
            }
            if (name === 'porLae') {
                porLae = parsedValue;
                setFormData(prevState => ({ ...prevState, [name]: value }));
            }

            setFormData(prevState => ({ ...prevState, [name]: value }));
            recalcularValoresToEdit(isCompartida, tipoCom, porLae, porCoor, porRespo, porAaff, porCap, porVend, porCola);
        }
        if (name === 'vinculo') {
            setVinculo(value);
            setFormData(prevState => ({ ...prevState, [name]: value }));
            recalcularValoresToEdit(isCompartida, tipoCom, porLae, porCoor, porRespo, porAaff, porCap, porVend, porCola);
        }
        const UpdateedProps = propiedades?.map(prop => {
            return {
                id_prop: prop?.id,
                cp: prop?.cp,
                direccion: prop?.direccion,
                promocion: prop?.promocion,
                municipio: prop?.municipio,
                importe: prop?.precioinmo || parseFloat(prop?.importe),
            }
        });
        setFormData(prevState => ({
            ...prevState,
            ["id_captador"]: formData.captador?.id,
            ["id_comprador"]: formData.comprador?.id,
            ["id_agVendedor"]: formData?.agenteVendedor?.id,
            ["id_vendedor"]: formData.vendedor?.id,
            ["propiedades"]: UpdateedProps

        }));
        
    };
    console.log(formData);
    const recalcularValoresToEdit = (
        isCompartida = false,
        tipoCom = 0,
        porLae = 0,
        porCoor = 0,
        porRespo = 0,
        porAaff = 0,
        porCap = 0,
        porVend = 0,
        porCola = 0,
    ) => {
        const { vinculo } = formData;
        
        const importeNeto =  typeof comision == 'string' ? parseFloat(comision.replace('.', '')).toFixed(2) * (1 - porLae) : comision * (1 - porLae);
        const totalComision = typeof comision == 'string' ? parseFloat(comision.replace('.', '')).toFixed(2) : comision ;
        const totalComisonNeta = (isCompartida || formData?.opCompartida) && porLae > 0 ? totalComision - importeNeto : totalComision;
        const importeCoordinador = parseFloat((totalComisonNeta * porCoor).toFixed(2));
        const importeResponsable = parseFloat((totalComisonNeta * porRespo).toFixed(2));
        const importeCaptador = parseFloat((totalComisonNeta * porCap).toFixed(2));
        const importeVendedor = parseFloat((totalComisonNeta * porVend).toFixed(2));
        const importeAaff = parseFloat((totalComisonNeta * porAaff).toFixed(2)) / 100 ;
        let sumRestos = 0;
        
        const importeColaborador = parseFloat((
            vinculo === 'AAFF' ? importeAaff
            :
            vinculo === 'VENDEDOR' ? importeVendedor
            :
            vinculo === 'CAPTADOR' ? importeCaptador
            :
            totalComisonNeta) * parseFloat(porCola) / 100).toFixed(2);
    
        sumRestos = importeCoordinador + importeResponsable + importeAaff + importeCaptador + importeVendedor;
    
        
        if (formData.aaff.length > 0) {
            const mi_aaff =  formData.aaff.map(aaffItem => {
                return {
                    ...aaffItem,
                    porAaff: porAaff,
                    totalAaff: vinculo === "AAFF" ? importeAaff - importeColaborador : importeAaff
                };
            });

            setFormData(prevState => ({
                ...prevState,
                ["aaff"]: mi_aaff,
            }));
        }
        if (formData.colaboradores.length > 0) {
            const mi_colaborador = formData.colaboradores.map(aaffItem => {
                return {
                    ...aaffItem,
                    porCola: porCola,
                    totalCol: importeColaborador
                };
            });

            setFormData(prevState => ({
                ...prevState,
                ["colaboradores"]:  mi_colaborador,
            }));
        }
        setFormData(prevState => {
            const newFormData = {
                ...prevState,
                // aaff: updatedAaff,
                // colaboradores: updatedColaboradores,
                totalCoor: importeCoordinador,
                totalRespo: importeResponsable,
                totalCaptador: vinculo === 'CAPTADOR' ? importeCaptador - importeColaborador : importeCaptador,
                totalVendedor: vinculo === 'VENDEDOR' ? importeVendedor - importeColaborador : importeVendedor,
                comisionTotal: totalComision,
                comisionLae: totalComisonNeta,
                totalComisionNeta: (formData.opCompartida) ? totalComisonNeta - sumRestos : totalComision - sumRestos,
            };

            return newFormData;
        });

    };
    const handleCalculaPorcentaje = (e) => {
        const { value } = e.target;
        const parsedValue = parseFloat(value);
        
        if (!importe || importe === 0 || isNaN(parsedValue)) {
            setComision(0);
            return;
        }

        setComision(parsedValue);
        let el_porcentaje = (parsedValue / importe) * 100;
        el_porcentaje = parseFloat(el_porcentaje.toFixed(2));  

        setFormData(prevState => ({
            ...prevState,
            tipoCom: el_porcentaje,
            totalComisionNeta: parsedValue,  
        }));
        setTotalComision(parsedValue);
        
    };
    const clean = (field) => {
        if (field === 'colaboradores') {
            !id ? 
                recalcularValores( 
                    isCompartida,
                    parseFloat(formData.tipoCom),
                    isNaN(parseFloat(formData?.porLae))? 0 : parseFloat(formData?.porLae) /100 ,
                    isNaN(parseFloat(formData.porCoor))? 0 : parseFloat(formData.porCoor) / 100, 
                    isNaN(parseFloat(formData.porRespo))? 0 : parseFloat(formData.porRespo) /100,
                    isNaN(parseFloat(formData.aaff[0]?.porAaff)) ? 0 : parseFloat(formData.aaff[0]?.porAaff),
                    isNaN(parseFloat(formData.porCaptador))? 0 : parseFloat(formData.porCaptador) / 100,
                    isNaN(parseFloat(formData.porVend))? 0 : parseFloat(formData.porVend) ,
                    0
                )
            :
                recalcularValoresToEdit( 
                    isCompartida,
                    parseFloat(formData.tipoCom),
                    isNaN(parseFloat(formData?.porLae))? 0 : parseFloat(formData?.porLae) /100 ,
                    isNaN(parseFloat(formData.porCoor))? 0 : parseFloat(formData.porCoor) / 100, 
                    isNaN(parseFloat(formData.porRespo))? 0 : parseFloat(formData.porRespo) /100,
                    isNaN(parseFloat(formData.aaff[0]?.porAaff)) ? 0 : parseFloat(formData.aaff[0]?.porAaff),
                    isNaN(parseFloat(formData.porCaptador))? 0 : parseFloat(formData.porCaptador) / 100,
                    isNaN(parseFloat(formData.porVend))? 0 : parseFloat(formData.porVend) ,
                    0
                )
            setVinculo('');
            setColaborador([]);
            setFormData(prevFormData => ({
                ...prevFormData,
                colaboradores: [],
                vinculo:null
            }));
            console.log(field)
        } else if (field === 'captador') {
        
            setFormData(prevState => {
                const { captador, id_captador, porCaptador, ...newState } = prevState;
                return {
                    ...newState,
                    porCaptador: 0 
                };
            });
            id ?
                recalcularValoresToEdit(
                    formData.opCompartida,
                    parseFloat(formData.tipoCom),
                    isNaN(parseFloat(formData?.porLae)) ? 0 : parseFloat(formData?.porLae) / 100,
                    isNaN(parseFloat(formData.porCoor)) ? 0 : parseFloat(formData.porCoor) / 100,
                    isNaN(parseFloat(formData.porRespo)) ? 0 : parseFloat(formData.porRespo) / 100,
                    isNaN(parseFloat(formData.aaff[0]?.porAaff)) ? 0 : parseFloat(formData.aaff[0]?.porAaff),
                    0,
                    isNaN(parseFloat(formData.porVend)) ? 0 : parseFloat(formData.porVend),
                    isNaN(parseFloat(formData.colaboradores[0]?.porCola)) ? 0 : parseFloat(formData.colaboradores[0]?.porCola)
                )
            :
                recalcularValores(
                    formData.opCompartida,
                    parseFloat(formData.tipoCom),
                    isNaN(parseFloat(formData?.porLae)) ? 0 : parseFloat(formData?.porLae) / 100,
                    isNaN(parseFloat(formData.porCoor)) ? 0 : parseFloat(formData.porCoor) / 100,
                    isNaN(parseFloat(formData.porRespo)) ? 0 : parseFloat(formData.porRespo) / 100,
                    isNaN(parseFloat(formData.aaff[0]?.porAaff)) ? 0 : parseFloat(formData.aaff[0]?.porAaff),
                    0,
                    isNaN(parseFloat(formData.porVend)) ? 0 : parseFloat(formData.porVend),
                    isNaN(parseFloat(formData.colaboradores[0]?.porCola)) ? 0 : parseFloat(formData.colaboradores[0]?.porCola)
                );
            
        } else if (field === 'agenteVendedor') {
          
            !id ? 
                recalcularValores( 
                    isCompartida,
                    parseFloat(formData.tipoCom),
                    isNaN(parseFloat(formData?.porLae))? 0 : parseFloat(formData?.porLae) /100 ,
                    isNaN(parseFloat(formData.porCoor))? 0 : parseFloat(formData.porCoor) / 100, 
                    isNaN(parseFloat(formData.porRespo))? 0 : parseFloat(formData.porRespo) /100,
                    isNaN(parseFloat(formData.aaff[0]?.porAaff)) ? 0 : parseFloat(formData.aaff[0]?.porAaff),
                    isNaN(parseFloat(formData.porCaptador))? 0 : parseFloat(formData.porCaptador) / 100,
                    0 ,
                    isNaN(parseFloat(formData.colaboradores[0]?.porCola))? 0 : parseFloat(formData.colaboradores[0]?.porCola)
                )
            :
                recalcularValoresToEdit( 
                    isCompartida,
                    parseFloat(formData.tipoCom),
                    isNaN(parseFloat(formData?.porLae))? 0 : parseFloat(formData?.porLae) /100 ,
                    isNaN(parseFloat(formData.porCoor))? 0 : parseFloat(formData.porCoor) / 100, 
                    isNaN(parseFloat(formData.porRespo))? 0 : parseFloat(formData.porRespo) /100,
                    isNaN(parseFloat(formData.aaff[0]?.porAaff)) ? 0 : parseFloat(formData.aaff[0]?.porAaff),
                    isNaN(parseFloat(formData.porCaptador))? 0 : parseFloat(formData.porCaptador) / 100,
                    0 ,
                    isNaN(parseFloat(formData.colaboradores[0]?.porCola))? 0 : parseFloat(formData.colaboradores[0]?.porCola)
                )
            setFormData(prevState => {
                const { agenteVendedor, id_agVendedor, ...newState } = prevState;
                return { ...newState, porVendedor: 0 };
            });
        
        } else if (field === 'aaff') {
            !id ? 
                recalcularValores( 
                    isCompartida,
                    parseFloat(formData.tipoCom),
                    isNaN(parseFloat(formData?.porLae))? 0 : parseFloat(formData?.porLae) /100 ,
                    isNaN(parseFloat(formData.porCoor))? 0 : parseFloat(formData.porCoor) / 100, 
                    isNaN(parseFloat(formData.porRespo))? 0 : parseFloat(formData.porRespo) /100,
                    parseFloat(0),
                    isNaN(parseFloat(formData.porCaptador))? 0 : parseFloat(formData.porCaptador) / 100,
                    isNaN(parseFloat(formData.porVend))? 0 : parseFloat(formData.porVend) ,
                    isNaN(parseFloat(formData.colaboradores[0]?.porCola))? 0 : parseFloat(formData.colaboradores[0]?.porCola)
                )
            :
                recalcularValoresToEdit( 
                    isCompartida,
                    parseFloat(formData.tipoCom),
                    isNaN(parseFloat(formData?.porLae))? 0 : parseFloat(formData?.porLae) /100 ,
                    isNaN(parseFloat(formData.porCoor))? 0 : parseFloat(formData.porCoor) / 100, 
                    isNaN(parseFloat(formData.porRespo))? 0 : parseFloat(formData.porRespo) /100,
                    parseFloat(0),
                    isNaN(parseFloat(formData.porCaptador))? 0 : parseFloat(formData.porCaptador) / 100,
                    isNaN(parseFloat(formData.porVend))? 0 : parseFloat(formData.porVend) ,
                    isNaN(parseFloat(formData.colaboradores[0]?.porCola))? 0 : parseFloat(formData.colaboradores[0]?.porCola)
                )
            /*clean array*/
            setFormData(prevFormData => ({
                ...prevFormData,
                aaff: []
            }));
        }
    };
    const cleanProp = (idx) => {
        
        const nuevasPropiedades = propiedades.filter((_, index) => index == idx);
        let propTodelete = propiedades.find((_, index) => index === idx);

        calculaImmporte(propTodelete, idx, false);
        setPropiedades(nuevasPropiedades);
        setFormData(prevData=>({
            ...prevData,
            propiedades:nuevasPropiedades,
        }))
    };
    const formDataInfo = (persona) => {
        if (!persona) {
            return '';
        }
        if (persona.nombre_comercial) {
            return persona.nombre_comercial;
        }
        if (!persona.nombre || !persona.apellidos) {
            return '';
        }
        return `${persona.nombre} ${persona.apellidos}`;
    };
    const handleSubmit = async () => {
        const response = await createHojaForm(formData);
        if (response.status == 201) {
            SnackbarUtilities.success("Hoja De Formalización creada Correctamente");
            setTimeout(() => {
                navigate('/HojaDeFormalizacion');
            }, 3000);
        } else {
            SnackbarUtilities.error("Ha habido un error intentelo de nuevo más tarde");
        }
    };
    const handleSubmitEdit = async () => {
        
        const response = await updateHojaForm(id, formData);

        if (response.status == 201) {
            SnackbarUtilities.success("Hoja De Formalización Editada Correctamente");
            setTimeout(() => {
                navigate('/HojaDeFormalizacion');
            }, 3000);
        } else {
            SnackbarUtilities.error("Ha habido un error intentelo de nuevo más tarde");
        }
    };
    const handleVerify = async () => {
        const response = await verifyHojaForm(id);
        if (response.status == 201) {
            SnackbarUtilities.success("Hoja De Formalización Verificada");
            setTimeout(() => {
                navigate('/HojaDeFormalizacion');
            }, 3000);
        } else {
            SnackbarUtilities.error("Ha habido un error intentelo de nuevo más tarde");
        }
    };
    const fetcherData = async () => {   
        const response = await getHojaForm(id);
        let datoFormateado = formatUnicData(response);
        const { propiedades } = datoFormateado;
   
        setFormData(datoFormateado);
        setPropiedades(propiedades);
        setImporte(formData?.total);
        setComision(response?.comisionTotal);
        
    };
    useEffect(()=>{},[formData]); // para que se actualice formData correctamente
    useEffect(() => {
        const loadData = async () => {
            setFormData(prevFormData => ({
                ...prevFormData,
                aaff: [{ ...prevFormData?.aaff[0], porAaff: prevFormData.aaff[0]?.porAaff }],
                colaboradores: prevFormData?.colaboradores.map(col => ({ ...col, porCola: col?.porCola }))
            }));
        };
    
        if (!formData.aaff[0]?.porAaff && !formData.colaboradores[0]?.porCola) {
            loadData();
        }
    }, [formData.aaff[0]?.porAaff, formData.colaboradores[0]?.porCola]);
    useEffect(() => {
        if (sucursal) { 
            chooseSucursal(sucursal);
        }
        if (id) {
            fetcherData();
        } else {
            fetchData({ offset: 1, limit: 1000, filtros: { estado: 1 } });
            setLoad(prevLoad => !prevLoad);
        }
    }, [id, sucursal]);
    return (
        <>
            <div className="container">
                {/* <BreadCrumb /> */}
                <div className="top-line">
                    <div style={{ textAlign: "start" }}>
                        <h3>{id ? 'Editar' : 'Crear'} Hoja de Formalización</h3>
                    </div>
                    <div style={{ display: "flex", flex: 0.5, justifyContent: "end" }}>
                        <Button
                            variant="outline-dark"
                            size="small"
                            sx={{ ...styledButton, marginRight: '10px' }}
                            onClick={() => handleVerify()}
                            startIcon={
                                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                    <Typography sx={{ paddingLeft: '5px', fontSize: '13px !important' }}>
                                        Verificar
                                    </Typography>
                                </Box>
                            }
                        />
                        <Button
                            variant="outline-dark"
                            size="small"
                            sx={styledButton}
                            onClick={() => id ? handleSubmitEdit() : handleSubmit()}
                            startIcon={
                                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                    <Save />
                                    <Typography sx={{ paddingLeft: '5px', fontSize: '13px !important' }}>
                                        Guardar
                                    </Typography>
                                </Box>
                            }
                        />
                    </div>
                </div>
                <div className="middleline">
                    <Box sx={{ width: "90%", minHeight: "100vh" }}>
                        {id == undefined ?
                            <>
                                <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%' }}>
                                    <Autocomplete
                                        fullWidth
                                        options={data.rows}
                                        onChange={(event, newValue) => {
                                            chooseSucursal(newValue)
                                        }}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        value={sucursalChoosed || null}
                                        disabled={user?.user?.rol?.permisos?.customViews['HojaDeFormalizacion']?.includes('Create HojasF') ? true : false}
                                        getOptionLabel={(option) => `LAE HOMES - ${option?.municipio}`}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Elige una sucursal"
                                                name="sucursal"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <FaSearch />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                sx={{
                                                    '& .MuiInputBase-input': {
                                                        ...inputStyles,
                                                        color: '#33383b', // Color del texto
                                                    },
                                                    '& .MuiInputLabel-root.Mui-focused': {
                                                        color: '#33383b', // Color del label cuando está enfocado
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        ...inputStyles,
                                                        borderColor: '#c4af6c', // Color del borde
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#c4af6c', // Color del borde cuando está enfocado
                                                        },
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Paper>
                                <Divider variant="middle" />
                            </>
                            :
                            <></>
                        }

                        {/* DATOS PRINCIPALES */}
                        <Paper elevation={0} sx={paperStyles}>
                            <TextField
                                onChange={(e) => handleChange(e)}
                                id="filled-helperText"
                                size="small"
                                name="referencia"
                                disabled
                                value={formData?.referencia}
                                placeholder="Referencia"
                                sx={{
                                    ...inputStyles,
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: '#33383b', // Necesario para algunos navegadores
                                    },
                                }}
                            />
                            <TextField
                                onChange={id ? handeEditChange : handleChange}
                                id="fechaOp"
                                label="Fecha Operación"
                                type="date"
                                size="small"
                                name="fechaOperacion"
                                value={formatDateToHF(formData?.fechaOperacion) || getCurrentDate()}
                                sx={inputStyles}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            {/* ACCIONES */}
                            <RadioGroup name="accion" row onChange={handleChange} value={formData?.accion ?? ''}>
                                <FormControlLabel
                                    value="ALQUILER"
                                    control={<Radio sx={CheckboxStyles} />}
                                    label="ALQUILER"
                                />
                                <FormControlLabel
                                    value="COMPRA_VENTA"
                                    control={<Radio sx={CheckboxStyles} />}
                                    label="COMPRA - VENTA"
                                />
                                <FormControlLabel
                                    value="TRASPASO"
                                    control={<Radio sx={CheckboxStyles} />}
                                    label="TRASPASO"
                                />
                                <FormControlLabel
                                    value="ALQ_OPCION_COMPRA"
                                    control={<Radio sx={CheckboxStyles} />}
                                    label="ALQ. OPCIÓN COMPRA"
                                />
                            </RadioGroup>

                        </Paper>
                        {/* DATOS INMUEBLE */}
                        <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%', marginTop: '2%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <Typography variant="h4" sx={{ textAlign: 'left', color: '#33383b', marginBottom: '16px' }}>
                                    Propiedades
                                </Typography>
                                <Button
                                    variant="contained"
                                    sx={styledButton}
                                    onClick={() => { setSidePropOpen(!sidePropOpen) }}
                                    // startIcon={<AddHomeIcon />} // Si existe
                                    // Alternativamente, usa ambos íconos
                                    startIcon={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <AddIcon />
                                            <HomeIcon />
                                        </Box>
                                    }

                                />
                            </Box>
                            {/* PROPIEDADES */}
                            {(propiedades.length > 0) ? (
                                <>
                                    {(propiedades).map((prop, index) => (
                                        <>
                                            <React.Fragment key={index}>
                                                <HojaPropiedadForm
                                                    propiedad={prop}
                                                    iconButtonStyles={iconButtonStyles}
                                                    handleChange={id ? handeEditChange : handleChange}
                                                    handleBlur={handleBlur}
                                                    inputStyles={inputStyles}
                                                    comision={comision}
                                                    index={index}
                                                    cleanProps={cleanProp}
                                                    formatNumbers={formatNumbers}
                                                />
                                                <Divider
                                                    variant="middle"
                                                    sx={{
                                                        borderColor: '#c4af6c',
                                                        borderWidth: '1.5px',
                                                        marginBottom: '20px',
                                                        marginTop: '20px',
                                                        width: '83%'
                                                    }}
                                                />
                                            </React.Fragment>
                                        </>
                                    ))}
                                </>
                            ) : (
                                <></>
                            )}
                            <TextField
                                onChange={handleChange}
                                label="Observación"
                                size="small"
                                name="observacion"
                                value={formData.observacion}
                                sx={{ ...inputStyles, maxWidth: '83%' }}
                                fullWidth
                            />
                        </Paper>
                        <Divider variant="middle" sx={{ borderColor: '#c4af6c', borderWidth: '1.5px', marginBottom: '20px' }} />
                        {/* DATOS COMPRADOR */}
                        <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%', marginTop: '2%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <Typography variant="h4" sx={{ textAlign: 'left', color: '#33383b', marginBottom: '16px' }}>
                                    Comprador
                                </Typography>
                                <Button
                                    variant="contained"
                                    sx={styledButton}
                                    onClick={() => { setSideCompradorOpen(!sideCompradorOpen) }}
                                    startIcon={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <AddIcon />
                                            <Person />
                                        </Box>
                                    }
                                />
                            </Box>
                            <HojaClienteForm
                                handleChange={handleChange}
                                cliente={formData.comprador}
                                inputStyles={inputStyles}
                            />

                        </Paper>
                        <Divider variant="middle" sx={{ borderColor: '#c4af6c', borderWidth: '1.5px' }} />
                        {/* DATOS VENDEDOR */}
                        <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%', marginTop: '2%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <Typography variant="h4" sx={{ textAlign: 'left', color: '#33383b', marginBottom: '16px' }}>
                                    Vendedor
                                </Typography>
                                <Button
                                    variant="contained"
                                    sx={styledButton}
                                    onClick={() => { setSideVendedorOpen(!sideVendedorOpen) }}
                                    startIcon={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <AddIcon />
                                            <Person />
                                        </Box>
                                    }
                                />
                            </Box>
                            <HojaClienteForm
                                handleChange={handleChange}
                                cliente={formData.vendedor}
                                inputStyles={inputStyles}
                            />
                        </Paper>
                        <Divider variant="middle" sx={{ borderColor: '#c4af6c', borderWidth: '1.5px' }} />
                        {/* DATOS ECONOMICOS */}
                        <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%', marginTop: '2%' }}>
                            <Box sx={{ width: '100%' }}>
                                <Typography variant="h4" sx={{ textAlign: 'left', color: '#33383b', marginBottom: '16px' }}>
                                    Datos Económicos
                                </Typography>
                            </Box>
                        </Paper>
                        {/* IMPORTES */}
                        <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%', marginTop: '2%' }}>
                            <HojaImporteForm
                                setImporte={setImporte}
                                formData={formData}
                                setFormData={setFormData}
                                handleCalculaPorcentaje={handleCalculaPorcentaje}
                                importe={formData?.total || importe}
                                propiedad={formData?.propiedades}
                                formatNumbers = {formatNumbers}
                                comision={comision}
                                tipoCom={formData?.tipoCom}
                                inputStyles={inputStyles}
                                handleChange={id ? handeEditChange : handleChange} // 
                            />
                        </Paper>
                        <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%', marginTop: '2%' }}>
                            <FormControlLabel
                                disabled={isCompartida}
                                sx={{ marginRight: '30px' }}
                                control={
                                    <Checkbox
                                        sx={CheckboxStyles}
                                        checked={formData.haya}
                                        onChange={id ? handeEditChange : handleChange}
                                        name="haya"
                                    />
                                }
                                label={<Typography sx={{ fontSize: '13.5px' }}>HAYA</Typography>}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                disabled={haya}
                                sx={{ marginRight: '30px' }}
                                control={
                                    <Checkbox
                                        sx={CheckboxStyles}
                                        checked={!!formData?.opCompartida}
                                        onChange={id ? handeEditChange : handleChange}
                                        name="opCompartida"
                                    />
                                }
                                label={<Typography sx={{ fontSize: '13.5px' }}>OPERACIÓN COMPARTIDA</Typography>}
                                labelPlacement="end"
                            />
                            {formData?.opCompartida == true ?
                                <>
                                    <TextField
                                        onChange={id ? handeEditChange : handleChange}
                                        label="Porcentaje"
                                        size="small"
                                        name="porLae"
                                        value={formData.porLae}
                                        sx={{ ...inputStyles, maxWidth: '20%' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment sx={{ paddingLeft: '1%' }} position="end">%</InputAdornment>
                                            )
                                        }}
                                    />
                                    <TextField
                                        onChange={id ? handeEditChange : handleChange}
                                        label="Comisión neta"
                                        size="small"
                                        name="comisonLae"
                                        value={formatNumbers(formData.comisionLae)?? ''}
                                        sx={{ ...inputStyles, maxWidth: '20%' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment sx={{ paddingLeft: '1%' }} position="end">€</InputAdornment>
                                            )
                                        }}
                                    />
                                </>
                                :
                                <></>
                            }
                            <TextField
                                onChange={handleChange}
                                label="Observación"
                                size="small"
                                name="obserDatosEco"
                                value={formData.obserDatosEco}
                                sx={{ ...inputStyles, width: '81%' }}
                            />
                        </Paper>
                        <Divider variant="middle" sx={{ borderColor: '#c4af6c', borderWidth: '1.5px' }} />
                        {/* DATOS AGENTES */}
                        <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%', marginTop: '2%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <Typography variant="h4" sx={{ textAlign: 'left', color: '#33383b', marginBottom: '16px' }}>
                                    Datos de agentes
                                </Typography>

                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{ ...styledButton, }}
                                    onClick={() => { setSideAaffOpen(!sideAaffOpen) }}
                                    startIcon={
                                        <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '13px !important' }} >
                                            <AddIcon />
                                            AAFF
                                        </Box>
                                    }
                                />
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{ ...styledButton, }}
                                    onClick={() => { setSideCaptadorOpen(!sideCaptadorOpen) }}
                                    startIcon={
                                        <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '13px !important' }} >
                                            <AddIcon />
                                            Captador
                                        </Box>
                                    }
                                />
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{ ...styledButton, }}
                                    onClick={() => { setSideAgenteVendedorOpen(!sideAgenteVendedorOpen) }}
                                    startIcon={
                                        <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '13px !important' }} >
                                            <AddIcon />
                                            Vendedor
                                        </Box>
                                    }
                                />
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{ ...styledButton, fontSize: '16px !important' }}
                                    onClick={() => { setColaboradores(!sideColaboradorOpen) }}
                                    startIcon={
                                        <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '13px !important' }}>
                                            <AddIcon />
                                            Colaborador
                                        </Box>
                                    }
                                /> 
                            </Box>
                        </Paper>
                        <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%' }}>
                            <TextField
                                // onChange={id ? handeEditChange : handleChange}
                                id="coordinador"
                                label="Coodinador"
                                size="small"
                                name="colaborador"
                                value={
                                    formDataInfo(Object.keys(coordinador ?? {}).length === 0 ? formData?.coordinador ?? '' : coordinador)
                                }
                                sx={{ ...inputStyles, maxWidth: '70%' }}
                                fullWidth
                            />
                            <TextField
                                onChange={id ? handeEditChange : handleChange}
                                id="porCoor"
                                label=""
                                size="small"
                                name="porCoor"
                                placeholder="00.00"
                                value={formData?.porCoor ?? ''}
                                sx={{
                                    ...inputStyles,
                                    width: "10%",
                                    "& .MuiInputAdornment-root": {
                                        width: "unset",
                                    }
                                }}
                                InputProps={{
                                    readOnly: Object.keys(coordinador ?? {}).length === 0 && !formData?.coordinador,
                                    endAdornment: (
                                        <InputAdornment position="end">%</InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                // onChange={id ? handeEditChange : handleChange}
                                id="responsable"
                                label="Responsable"
                                size="small"
                                name="responsable"
                                value={
                                    formDataInfo(Object.keys(responsable).length === 0 ? formData?.responsable  : responsable)
                                }
                                sx={{ ...inputStyles, maxWidth: '70%' }}
                                fullWidth
                            />
                            <TextField
                                onChange={id ? handeEditChange : handleChange}
                                label=""
                                size="small"
                                name="porRespo"
                                placeholder="00.00"
                                value={formData?.porRespo ?? ''}
                                sx={{
                                    ...inputStyles,
                                    width: "10%",
                                    "& .MuiInputAdornment-root": {
                                        width: "unset",
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">%</InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                onChange={id ? handeEditChange : handleChange}
                                id="adminFincas"
                                label="AAFF"
                                size="small"
                                name="aaff"
                                value={formData?.aaff && formData.aaff.length > 0
                                    ?
                                    formDataInfo(formData?.aaff[0])
                                    : formDataInfo(formData?.aaff)}
                                sx={{ ...inputStyles, maxWidth: '70%' }}
                                fullWidth
                            />
                            <TextField
                                onChange={id ? handeEditChange : handleChange}
                                id="porAaff"
                                label=""
                                size="small"
                                name="porAaff"
                                placeholder="00.00"
                                value={formData?.aaff[0]?.porAaff ?? ''}
                                sx={{
                                    ...inputStyles,
                                    width: "10%",
                                    "& .MuiInputAdornment-root": {
                                        width: "unset",
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">%</InputAdornment>
                                    )
                                }}
                            />
                           
                            {(formData?.aaff[0]?.id == undefined || formData?.aaff[0]?.porAaff == undefined || formData?.aaff[0]?.porAaff == 0 ) ? (
                                <></>
                            ) : (
                                <IconButton sx={iconButtonStyles} onClick={() => clean('aaff')}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                            <TextField
                                onChange={id ? handeEditChange : handleChange}
                                id="captador"
                                label="Captador"
                                size="small"
                                name="captador"
                                value={formDataInfo(formData.captador) ?? ''}
                                sx={{ ...inputStyles, maxWidth: '70%' }}
                                fullWidth
                            />
                            <TextField
                                onChange={id ? handeEditChange : handleChange}
                                id="porCaptador"
                                label=""
                                size="small"
                                name="porCaptador"
                                placeholder="00.00"
                                value={formData.porCaptador ?? ''}
                                sx={{
                                    ...inputStyles,
                                    width: "10%",
                                    "& .MuiInputAdornment-root": {
                                        width: "unset",
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">%</InputAdornment>
                                    )
                                }}
                            />
                            {formData.captador ?
                                <IconButton sx={iconButtonStyles} onClick={() => clean('captador')}>
                                    <DeleteIcon />
                                </IconButton>
                                :
                                <></>
                            }

                        </Paper>
                        <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%' }}>
                            <TextField
                                onChange={handleChange}
                                id="vendedor"
                                label="Vendedor"
                                size="small"
                                name="vendedor"
                                value={formDataInfo(formData.agenteVendedor) || ''}
                                sx={{ ...inputStyles, maxWidth: '70%' }}
                                fullWidth
                            />
                            <TextField
                                onChange={id ? handeEditChange : handleChange}
                                id="porVend"
                                label=""
                                size="small"
                                name="porVendedor"
                                placeholder="00.00"
                                value={formData?.porVendedor ?? ''}
                                sx={{
                                    ...inputStyles,
                                    width: "10%",
                                    "& .MuiInputAdornment-root": {
                                        width: "unset",
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">%</InputAdornment>
                                    )
                                }}
                            />
                            {formData.agenteVendedor ?
                                <IconButton sx={{...iconButtonStyles}} onClick={() => clean('agenteVendedor')}>
                                    <DeleteIcon />
                                </IconButton>
                                :
                                <></>
                            }
                            <TextField
                                onChange={id ? handeEditChange : handleChange}
                                id="colaborador"
                                label="Colaborador"
                                size="small"
                                name="colaborador"
                                value={
                                    formData?.colaboradores && formData.colaboradores.length > 0
                                        ?
                                        formDataInfo(formData.colaboradores[0])
                                        : formDataInfo(formData.colaboradores)
                                }
                                sx={{ ...inputStyles, maxWidth: '70%' }}
                                fullWidth
                            />
                            <HojaPrescriptorForm
                                handleChange={id ? handeEditChange : handleChange}
                                vinculo={vinculo || formData.vinculo}
                                anchorEl={anchorEl}
                                open={open}
                                handleClick={handleClick}
                                handleClose={handleClose}
                                ide={ide}
                                formData={formData}
                            />
                            {(formData?.colaboradores[0]?.porCola == undefined || formData?.colaboradores[0]?.porCola == 0 ) ? (
                                <></>
                            ) : (
                                <IconButton sx={iconButtonStyles} onClick={() => clean('colaboradores')}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </Paper>
                        <Divider variant="middle" sx={{ borderColor: '#c4af6c', borderWidth: '1.5px', marginTop: '2%' }} />
                        {/* REPARTO */}
                        <Grid container spacing={2} columns={16}>
                            <HojaRepartosForm
                                handleChange={handleChange}
                                formData={formData}
                            />
                            <Grid item xs={8}>
                                <Box sx={{
                                    maxWidth: '50%',
                                    marginTop: '30%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    height: '0.5%'
                                }}>
                                    <Typography variant="h5">Total: </Typography>
                                    <Typography variant="h3" sx={{ marginLeft: '10px' }}>
                                        {formData?.totalComisionNeta?.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
            <DrawerPropiedades
                open={sidePropOpen}
                setOpen={setSidePropOpen}
                setPropiedad={setFormData}
                setPropiedadesAux={setPropiedades}
                sucursal={formData?.sucursal || sucursalChoosed || sucursal}
                propiedades={propiedades}
                calculaImmporte={calculaImmporte}
            />
            {/* COMPRADOR */}
            <DrawerClientes
                open={sideCompradorOpen}
                toSave={'comprador'}
                sucursal={formData?.sucursal || sucursalChoosed || sucursal}
                setOpen={setSideCompradorOpen}
                setCliente={setFormData}
                cliente={formData.comprador}
            />
            {/* VENDEDOR */}
            <DrawerClientes
                open={sideVendedorOpen}
                toSave={'vendedor'}
                sucursal={formData?.sucursal || sucursalChoosed || sucursal}
                setOpen={setSideVendedorOpen}
                setCliente={setFormData}
                cliente={formData.vendedor}
            />
            {/* AAFF */}
            <DrawerAaff
                open={sideAaffOpen}
                setOpen={setSideAaffOpen}
                setAaff={setFormData}
                toSave={'aaff'}
                sucursal={formData?.sucursal || sucursalChoosed || sucursal}
                aaff={formData?.aaff}
            />
            <DrawerTrabajadores
                open={sideAgenteVendedorOpen}
                setOpen={setSideAgenteVendedorOpen}
                setTrabajador={setFormData}
                toSave={'agenteVendedor'}
                sucursal={formData?.sucursal || sucursalChoosed || sucursal}
                trabajador={formData?.agVendedor}
            />
            <DrawerTrabajadores
                open={sideCaptadorOpen}
                setOpen={setSideCaptadorOpen}
                setTrabajador={setFormData}
                toSave={'captador'}
                sucursal={formData?.sucursal || sucursalChoosed || sucursal}
                trabajador={formData?.captador}
            />
            {/* COLABORADOR */}
            <DrawerContactos
                open={sideColaboradorOpen}
                setOpen={setColaboradores}
                setContacto={setFormData}
                sucursal={formData?.sucursal || sucursalChoosed || sucursal}
                toSave={'colaboradores'}
                contacto={formData?.colaboradores && formData.colaboradores.length > 0
                    ?
                    formDataInfo(formData.colaboradores[0])
                    : ''
                }
            />
        </>
    );
};

export default HojaFormalizacion;