import React, { useState, useEffect, useReducer } from "react";
import {
  Download,
  XCircleFill,
  PencilFill,
  EyeFill,
  TrashFill,
  TypeUnderline,
} from "react-bootstrap-icons";
import BreadCrumb from "../../components/navbar/Breadcrumb";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import Paginacion from "../../components/Paginacion";
import {
  categoryCreate,
  categoryDelete,
  listUsers,
  listsCategories,
} from "../../services";
import useColumns from "../../hooks/useColumns";
import { SyncLoader } from "react-spinners";
import { GetLanguageFlags, SnackbarUtilities } from "../../utilities";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";

const columns = [
  { Header: "ID", accessor: "id" },
  {
    Header: "Nombre",
    accessor: "name",
  },
  {
    Header: "Traducciones",
    accessor: "translations",
  },
  { Header: "Acción", accessor: "actions" },
];

function Categorias() {
  const [categorias, setCategorias] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const tableInstance = useTable(
    { columns, data: categorias, initialState: { pageSize: 20 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page, // Obtener la página actual
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = tableInstance;

  const fetchData = async () => {
    try {
      const response = await listsCategories({ offset: 1, limit: 1000 });
      console.log(response);
      setCategorias(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (e) => {
    // window.open(window.location.origin + `/categorias/create`, "_top  ");
    navigate("/Categorias/form");
  };

  const handleDelete = async (id) => {
    try {
      Swal.fire({
        title: "¿Desea eliminar esta Categoría?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Si",
        denyButtonText: `Cancelar`,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const response = await categoryDelete(id);
          console.log(response.data);
          await fetchData();
          SnackbarUtilities.success("Categoría eliminado con éxito");
        } else if (result.isDenied) {
          return;
        }
      });
    } catch (error) {
      console.error(error);
      SnackbarUtilities.error("Error al eliminar la Categoría");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container">
      {/* <BreadCrumb /> */}

      <div className="top-line">
        <div style={{ textAlign: "start" }}>
          <h3>Categorias</h3>
        </div>
        <div style={{ display: "flex", flex: 0.5, justifyContent: "end" }}>
          <Button onClick={handleClick} variant="outline-dark">
            Crear
          </Button>
        </div>
      </div>
      <div className="middleline">
        {/* <div className="form-group buscador">
          <input
            className="form-control"
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar en la tabla..."
          />
        </div> */}
        <div style={{ display: "flex", flex: 1, justifyContent: "end" }}>
          <Paginacion
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            previousPage={previousPage}
            nextPage={nextPage}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
          />
        </div>
      </div>

      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            placeItems: "center",
            placeContent: "center",
            width: "100%",
            minHeight: 450,
            backgroundColor: "#fff",
            marginBottom: 15,
            borderRadius: 5,
          }}
        >
          <SyncLoader color="#33383b" />
        </div>
      ) : (
        <table
          {...getTableProps()}
          className="table"
          style={{ width: "100%", backgroundColor: "#fff" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // Añadir la funcionalidad para permitir el ordenamiento
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " ↓"
                          : " ↑"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="table-group-divider" {...getTableBodyProps()}>
            {page.length == 0 ?
              <div>No hay categorias</div>
              :
              page.map((row) => {
                prepareRow(row);
                const prop = row?.original;
                return (
                  <tr
                    className="pb-5"
                    {...row.getRowProps()}
                    key={row?.id}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{prop?.id}</td>
                    <td>{prop?.name}</td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {prop.translations?.map((lang) => (
                          <GetLanguageFlags
                            countryCode={lang?.language?.iso_code}
                            key={lang.id}
                          />
                        ))}
                      </div>
                    </td>

                    <td>
                      <div
                        className="botones"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          justifyContent: "center",
                        }}
                      >
                        {/* <Link to={`/Categorias/form/${prop.id}`} target="_top"> */}
                        <Link to={`/Categorias/form/${prop.id}`} >
                          <button
                            className="btn btn-outline-dark"
                          >
                            <PencilFill />{" "}
                          </button>
                        </Link>
                        <button
                          className="btn btn-outline-dark"
                          onClick={() => handleDelete(prop.id)}
                        >
                          <TrashFill />{" "}
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      )}
      <Paginacion
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
      />
    </div>
  );
}

export default Categorias;
