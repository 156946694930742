import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

const BreadCrumb = () => {
    const location = useLocation();
    //Quita que sea un numero y lo divide en varias partes
    const pathnames = location.pathname.split('/').filter((x) => x && isNaN(x));

    return (
        <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ml:1.5,mt:2,mb:2}}>
            <Link underline="hover" style={{color:"#A69150"}} to="/">
                Inicio
            </Link>
            {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                return last ? (
                    <Typography color="text.primary" key={to}>
                        {value.charAt(0).toUpperCase() + value.slice(1)}
                    </Typography>
                ) : (
                    <Link underline="hover" style={{color:"#A69150"}} to={to} key={to}>
                        {value.charAt(0).toUpperCase() + value.slice(1)}
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
};

export default BreadCrumb;
