import axios from "axios";
const urlPath = "contactos";

export const listContactos = async (data) => {
    try {
        // console.log(data);
        const response = await axios.post(`${urlPath}/filter`, data.filtros, {
            params: data.params,
        });
        console.log(response.data);
        return response.data;
    } catch (error) {

        console.log(error);
    }
};

export const getContacto = async ( id ) => {
    try {
        const response = await axios.get(`${urlPath}/${id}`);
        console.log(response);
        return response.data;
    } catch (error) {
        console.log(error);
        return error;
    }
};
export const createContacto = async ( data ) => {
    try {
    
        const response = await axios.post(`${urlPath}`, data);
        console.log(response)
        return response.status;
    } catch (error) {
        console.log(error);
        return error;
    }
};
export const updateContacto = async (id, data) => {
    try {

        const response = await axios.put(`${urlPath}/${id}`, data);
        console.log(response);
        return response.status;
    } catch (error) {
        console.log(error);
        return error;
    }
};
export const deleteContacto = async (id) => {
    try {
        const response = await axios.delete(`${urlPath}/${id}`);
        // console.log(response);
        return response.status;
    } catch (error) {
        console.log(error);
        return error;
    }
};
