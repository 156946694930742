import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/navbar/Breadcrumb";
import {
  categoryCreate,
  categoryDetails,
  categoryUpdate,
  listLanguages,
} from "../../services";
import { Accordion, Button, Form, InputGroup } from "react-bootstrap";

import { SnackbarUtilities } from "../../utilities";
import CategoryForm from "../../components/Forms/CategoryForm";
import { useParams } from "react-router-dom";

function CreateCategoria() {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
  });
  const [languages, setLanguages] = useState([]);
  const [selected, setSelected] = useState("ES");
  const fetchLanguages = async () => {
    try {
      const response = await listLanguages({ offset: 1, limit: 1000 });

      setLanguages(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCategory = async () => {
    try {
      const response = await categoryDetails(id);

      const { name, translations, slug } = response.data.data;

      const translationsToJSON = {};

      await translations.forEach((t) => {
        console.log(t.id)
        return (translationsToJSON[t.id] = { name: t.name, language_id: t.language?.id });
      });
      
      setFormData({
        name,
        ...translationsToJSON,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const putCategory = async () => {
    try {
      const { name, ...rest } = formData;

      const translations = [];

      Object.keys(rest).forEach((key) => {
        translations.push({
          language_id: key,
          name: rest[key].name,
        });
      });

      console.log({ name, translations });
      const response = await categoryUpdate(id, { name, translations });

      console.log(response?.data);
      SnackbarUtilities.success("Categoría editada con éxito");
    } catch (error) {
      console.log(error);
      SnackbarUtilities.error("Error al editar la categoría");
    }
  };

  const postNewCategory = async () => {
    try {
      const { name, ...rest } = formData;

      const translations = [];

      Object.keys(rest).forEach((key) => {
        translations.push({
          language_id: key,
          name: rest[key].name,
        });
      });

      console.log({ name, translations });
      const response = await categoryCreate({ name, translations });

      console.log(response?.data);
      SnackbarUtilities.success("Categoría creada con éxito");
      // setTimeout(() => {
      //   window.location.href = '/categorias';
      // }, 2000); // 2 segundos de retraso
    } catch (error) {
      console.log(error);
      SnackbarUtilities.error("Error al crear la categoría");
    }
  };

  useEffect(() => {
    fetchLanguages();
    if (id) fetchCategory();
  }, []);

  return (
    <div className="container">
      {/* <BreadCrumb /> */}

      <div
        className="top-line"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <div style={{ textAlign: "start" }}>
          <h3>{id ? "Editar" : "Crear"} Categoría</h3>
        </div>
        <div style={{ display: "flex", flex: 1, justifyContent: "end" }}>
          <Button
            onClick={id ? putCategory : postNewCategory}
            variant="outline-dark"
          >
            Guardar
          </Button>
        </div>
      </div>

      <div className="middleline">
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <div className="d-flex gap-2">
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Titulo de la categoría"
                onChange={({ target }) =>
                  setFormData((prev) => ({ ...prev, name: target.value }))
                }
                defaultValue={formData?.name}
              />
            </InputGroup>
            {/*  <InputGroup className="mb-3">
              <Form.Control
                placeholder="Categoría"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
            </InputGroup> */}
          </div>

          <Accordion>
            {languages.length &&
              
              languages.map((lang, index) => (
                <CategoryForm
                  key={lang.id}
                  eventKey={index}
                  title={lang.name}
                  languageCode={lang.iso_code}
                  initialValue={formData[lang.id]?.name}
                  onChange={(data) => {
                    setFormData((prev) => ({
                      ...prev,
                      [lang.id]: { language_id: lang.id, name: data },
                    }));

                    
                  }}
                />
              ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default CreateCategoria;
