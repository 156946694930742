import axios from "axios";

const urlPath = "users";

export const listUsers = async ({ offset, limit }) => {
  try {
    const response = await axios.get(urlPath, { offset, limit });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const userDetails = async (id) => {
  try {
    const response = await axios.get(`${urlPath}/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const userCreate = async (data) => {
  try {
    const response = await axios.post(`${urlPath}`, data);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const userUpdate = async (id, data) => {
  try {
    const response = await axios.put(`${urlPath}/${id}`, data);
    return response;
  } catch (error) {
    return error;
    console.log(error);
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await axios.delete(`${urlPath}/${id}`);
    return response.status;
  } catch (error) {
    console.log(error);
  }
};
