import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { signIn, verifyAccount } from "../../services";
import Logo from './../../images/logo_laehomes_color.svg'
import { SnackbarUtilities } from "../../utilities";
import { Box, Button, TextField, Typography, Container, Paper } from '@mui/material';
import imgBackground from '../../images/casa-fondo.jpg';

const Verify = () => {
    const { token } = useParams();
    const navigate = useNavigate();

    const handleSubmit = async () => {
        const response = await verifyAccount({ token });
        if (response == 200) {
            setTimeout(() => {
                SnackbarUtilities.success("CUENTA VERIFICADA!");
                // history(`/login?token=${token}`); 
                navigate(`/reset-password?token=${token}`);
            }, 3000);
        } else {
            SnackbarUtilities.error("ERROR AL VERIFICAR LA CUENTA");
        }
    }
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundImage: `url(${imgBackground})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                margin: 0
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    p: 4,
                    display: 'flex',
                    '& > :not(style)': {
                        m: 1,
                        width: 380,
                        height: '65%',
                    },
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    margin: 0
                }}
            >
                <Box
                    sx={{
                        px: '5vh',
                        py: '5vh',
                        textAlign: "center",
                        margin: 0
                    }}
                >
                    <img src={Logo} alt="LAE HOMES" style={{ width: '70%', marginBottom: 10 }} />
                    <Typography variant="subtitle1" gutterBottom sx={{ mb: 3, color: "#bababa" }} >
                        Verificación de Cuenta
                    </Typography>

                    <Button
                    variant="contained"
                    type="submit" sx={{
                        backgroundColor: '#212529',
                        ':hover': {
                            backgroundColor: '#fff',
                            boxShadow: "none",
                            border: "1px solid #33383b" // Cambia este valor al color deseado
                        },
                        color: "rgb(196, 175, 108)",
                    }}
                    fullWidth
                    onClick={()=>handleSubmit()}
                    >
                        Verificar
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
}

export default Verify;