import React from 'react'
import { Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import {Description,Euro,Person} from '@mui/icons-material';

export default function ResumenComisiones({ data }) {

    function calculateSummary(data) {
        // Initialize counters for total commission, total workers, and total hojas
        let totalCommission = 0;
        let totalWorkers = 0;
        let totalHojas = 0;

        // Loop through each worker object in the array
        data.forEach(worker => {
            totalWorkers += 1; // Increment total worker count for each worker

            // Increment total hojas count
            totalHojas += worker.numero_hoja;

            // Clean up the commission value and convert it to a number, then add it to totalCommission
            const cleanedCommission = parseFloat(worker.total_comision.replace('.', '').replace(',', '.').replace(' €', ''));
            totalCommission += isNaN(cleanedCommission) ? 0 : cleanedCommission;
        });

        return {
            totalCommission: totalCommission.toLocaleString('de-DE'),
            totalWorkers,
            totalHojas
        };
    }

    const summary = calculateSummary(data?.rows);
    
    return (
        <Box sx={{ padding: 4, backgroundColor: '#f5f5f5', marginBottom: 6 }}>
            <Paper sx={{ padding: 3, marginBottom: 1, backgroundColor: '#ffffff', display: "flex", flexDirection: "column", alignItems: "center",gap:2 }}>
                <Typography variant="h4" gutterBottom sx={{ fontSize: 31 }}>Resumen General</Typography>
                <Box
                    sx={{ display: "flex", justifyContent: "center", gap: 10,mb:1 }}
                >
                    <Card sx={{ width: 260, height: 120, border: "2px solid #c4af6c", boxShadow: "none", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <CardHeader
                            titleTypographyProps={{ fontSize: 22, textAlign: 'center', fontWeight: 'bold', color: '#33383b' }} // Ajusta el tamaño y estilo del título
                            title="Trabajadores"
                            sx={{ paddingBottom: 1 }} // Reduce el padding inferior para mantenerlo más compacto
                        />
                        <CardContent sx={{ display: "flex", alignItems: "center", gap: 2, padding: 0 }}>
                            <Person sx={{ fontSize: 40, color: '#c4af6c' }} />
                            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#33383b' }}>
                                {summary?.totalWorkers}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ width: 260, height: 120, border: "2px solid #c4af6c", boxShadow: "none", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <CardHeader
                            titleTypographyProps={{ fontSize: 22, textAlign: 'center', fontWeight: 'bold', color: '#33383b' }} // Ajusta el tamaño y estilo del título
                            title="Comisiones Total"
                            sx={{ paddingBottom: 1 }} // Reduce el padding inferior para mantenerlo más compacto
                        />
                        <CardContent sx={{ display: "flex", alignItems: "center", gap: 2, padding: 0 }}>
                            <Euro sx={{ fontSize: 40, color: '#c4af6c' }} />
                            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#33383b' }}>
                                {summary?.totalCommission}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ width: 260, height: 120, border: "2px solid #c4af6c", boxShadow: "none", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <CardHeader
                            titleTypographyProps={{ fontSize: 22, textAlign: 'center', fontWeight: 'bold', color: '#33383b' }} // Ajusta el tamaño y estilo del título
                            title="H. Formalización"
                            sx={{ paddingBottom: 1 }} // Reduce el padding inferior para mantenerlo más compacto
                        />
                        <CardContent sx={{ display: "flex", alignItems: "center", gap: 2, padding: 0 }}>
                            <Description sx={{ fontSize: 40, color: '#c4af6c' }} />
                            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#33383b' }}>
                                {summary?.totalHojas}
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>

            </Paper>

        </Box>


    )
}
