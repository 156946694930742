import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

const BarrasGrafico = ({ data, seriesColumn }) => {
    //console.log(data.length);
    const chartSetting = {
        yAxis: [
            {
                // label: 'Precios Propiedades (€)',
            },
        ],
        width: (data.length > 40) ? 8000 : 580, // Ajusta según sea necesario
        height: 400, // Altura más razonable
        sx: {
            [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translate(-20px, 0)',
            },
        },
    };

    return (
      <div  style={{ overflowX: 'auto', maxWidth: 600 }}>
            <BarChart
                series={seriesColumn}
                xAxis={[{ dataKey: 'fecha', scaleType: 'band', categoryGapRatio: 0.1 }]} // Ajusta categoryGapRatio según sea necesario
                {...chartSetting}
                margin={{ top: 10, bottom: 30, left: 100, right: 10 }}
                dataset={data}
                slotProps={{            
                  legend: {
                      
                      direction: 'column',
                      position: { vertical: 'top', horizontal: 'middle' },
                      padding: 0,
                      labelStyle: {
                          // cursor:"pointer",
                          // width:"auto",
                          // border:"1px solid red"
                        },
                        hidden:true
                },
              }}
            />
        </div>
    );
};

export default BarrasGrafico;
