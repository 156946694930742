import React, { useEffect, useState } from "react";
import Logo from './../../images/logo_laehomes_color.svg'
import { SnackbarUtilities } from "../../utilities";
import { useFormValidation } from "../../utilities/formValidator";
import * as Yup from 'yup';
import { Box, Button, TextField, Typography, Container, Paper } from '@mui/material';
import imgBackground from '../../images/casa-fondo.jpg';
import { logout, userUpdate, sendMailRecoverPass } from "../../services";

const RecoverPass = () => {

    const initialValues = {
        email: '',
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Formato de correo no válido').required('Campo obligatorio')
    });

    // Usa la función de validación personalizada
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setCustomFieldValue, setFieldTouched, setStatus, status } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (values) {
                if (values.email === values.email) {
                    console.log(values);
                    const mailData = { email:values.email, resendMail: true }
                    try {
                        const data = await sendMailRecoverPass(mailData);
                        console.log(data?.request?.status);
                        console.log(data);
                        
                        if (data == 200) {
                            SnackbarUtilities.success("Correo Reenviado Correctamente");
                            setStatus({ success: 'Porfavor revisa la bandeja de tu correo' });
                            setTimeout(()=>{
                                window.location.replace('/');
                            },2600);
                        }else if(data?.request?.status == 404){
                            SnackbarUtilities.error("Usuario No encontrado");

                        } else {
                            SnackbarUtilities.error("Error al Reenviar el Correo");
                        }
                    } catch (error) {
                        SnackbarUtilities.error("Ha habido un error, Intente más tarde");
                        console.log(error);
                    }

                    // setStatus({ success: 'Las contraseñas coinciden.' });
                    // Lógica para enviar el formulario
                } else {
                    // setStatus({ error: 'Las contraseñas no coinciden.' });
                }

            } else {

                // SnackbarUtilities.error("Error en algun validador del campo");
            }
        },
    });

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundImage: `url(${imgBackground})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                margin: 0
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    p: 4,
                    display: 'flex',
                    '& > :not(style)': {
                        m: 1,
                        width: 380,
                        height: '65%',
                    },
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    margin: 0
                }}
            >
                <Box
                    sx={{
                        px: '5vh',
                        py: '5vh',
                        textAlign: "center",
                        margin: 0
                    }}
                >
                    <img src={Logo} alt="LAE HOMES" style={{ width: '70%', marginBottom: 10 }} />
                    <Typography variant="subtitle1" gutterBottom sx={{ mb: 3, color: "#bababa" }} >
                        Recuperar Acceso
                    </Typography>
                    <form onSubmit={handleSubmit} autocomplete="off">

                        <TextField
                            sx={inputStyles}
                            label="Email"
                            variant="outlined"
                            id="textbox"
                            type="text"
                            //focused
                            name='email'
                            fullWidth
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autocomplete="off"
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}

                        />
                        {status && (
                            <Typography
                                sx={{ mb: 2, color: status.error ? 'red' : 'green' }}
                            >
                                {status.error || status.success}
                            </Typography>
                        )}
                        <Button variant="contained" type="submit" sx={{
                            backgroundColor: '#212529',
                            ':hover': {
                                backgroundColor: '#fff',
                                boxShadow: "none",
                                border: "1px solid #33383b" // Cambia este valor al color deseado
                            },
                            color: "rgb(196, 175, 108)",

                        }} fullWidth >
                            Enviar
                        </Button>
                    </form>
                </Box>
            </Paper>
        </Box>
    );
};

const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
    width: "100%",
    marginBottom: 4,
    marginTop: 0
};

export default RecoverPass;
