import React, { useEffect } from 'react';
import { Box, Button, TextField, Typography, Container, Paper } from '@mui/material';
import imgBackground from '../../images/casa-fondo.jpg';
import Logo from '../../images/logo_laehomes_color.svg';
import * as Yup from 'yup';
import { useFormValidation } from "../../utilities/formValidator";
import AutoFillAwareTextField from '../../utilities/AutoFillAwareTextField';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { signIn } from "../../services/auth.service";
import { SnackbarUtilities } from "../../utilities/snackbar-manager";
import { UserProviderLogin, useUser } from '../../hooks/UserContextLogin';

const Login = () => {
    const navigate = useNavigate();
    const { setUser } = useUser();

    const initialValues = {
        email: '',
        password: '',
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string().required('Campo obligatorio').min(4, 'Debe tener al menos 5 caracteres'),
        email: Yup.string().email('Formato de correo no válido').required('Campo obligatorio'),
    });

    // Usa la función de validación personalizada
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setCustomFieldValue, setFieldTouched } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            console.log("join 111");

            if (values) {
                // console.log(values);
                try {
                    const res = await signIn(values);
                    console.log(res);
                    
                    if (res.token) {
                        setUser(res);
                        // console.log("entro");
                        // console.log(localStorage.getItem("token"));
                        if (localStorage.getItem("token")) {
                            navigate("/", { state: { fromLogin: true } });

                        }
                        SnackbarUtilities.success("Logeado correctamente!!");
                    }else if (res.status == 401 || res.status == 404) {
                        SnackbarUtilities.error("Usuario o Contraseña incorrecta");
                    }
                    
                } catch (error) {
                    console.log(error);
                    
                }


                // const formData = new FormData();
            } else {
                // SnackbarUtilities.error("Error en algun validador del campo");
            }
        },
    });

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundImage: `url(${imgBackground})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                margin: 0
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    p: 4,
                    display: 'flex',
                    '& > :not(style)': {
                        m: 1,
                        width: 380,
                        height: '65%',
                    },
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    margin: 0
                }}
            >
                <Box
                    sx={{
                        px: '5vh',
                        py: '5vh',
                        textAlign: "center",
                        margin: 0
                    }}
                >
                    <img src={Logo} alt="LAE HOMES" style={{ width: '70%', marginBottom: 10 }} />
                    <Typography variant="subtitle1" gutterBottom sx={{ mb: 3, color: "#bababa" }} >
                        Inicia sesión
                    </Typography>
                    <form onSubmit={handleSubmit} autocomplete="off">

                        <AutoFillAwareTextField
                            sx={inputStyles}
                            label="Usuario"
                            variant="outlined"
                            id="textbox"
                            //focused
                            name='email'
                            fullWidth
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autocomplete="off"
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}

                        />
                        <AutoFillAwareTextField
                            sx={inputStyles}
                            label="Contraseña"
                            //focused
                            type="password"
                            name='password'
                            variant="outlined"
                            autocomplete="off"
                            fullWidth
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.password && Boolean(errors.password)}
                            helperText={touched.password && errors.password}

                        />
                        <Button variant="contained" type="submit" sx={{
                            backgroundColor: '#212529',
                            ':hover': {
                                backgroundColor: '#fff',
                                boxShadow: "none",
                                border: "1px solid #33383b" // Cambia este valor al color deseado
                            },
                            color: "rgb(196, 175, 108)",

                        }} fullWidth >
                            Iniciar Sesión
                        </Button>
                    </form>
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                        <Link style={{ color: "#000" }} to="/recover-password">¿Has olvidado tu contraseña?</Link>

                    </Typography>
                </Box>
            </Paper>
        </Box>
    );
};
const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
    width: "100%",
    marginBottom: 4,
    marginTop: 0
};
export default Login;
