import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../hooks/UserContextLogin';

const ProtectedRoute = ({ element, requiredPermission }) => {
  const { user } = useUser();
  // console.log(user);

  const hasPermission = user.rol.permisos.acciones.some(permission =>
    (permission.action === requiredPermission.action || permission.action === 'all') && permission.resource === requiredPermission.resource
  );
  // console.log(hasPermission);
  
  const hasGraphPermission = user.rol.permisos.acciones.some(permission =>
    permission.action === 'view' && permission.resource === 'Graficos'
  );

  if (!hasPermission) {
    console.log("Error en el protected rute");
  }
  if (hasPermission) {
    return element;
  } else if (!hasPermission && hasGraphPermission) {
    return <Navigate to="/" />
  }else if (!hasPermission && !hasGraphPermission) {
    return <Navigate to="/Sucursales" /> 
  }
  // return hasPermission ? element : <Navigate to="/" />;
};

export default ProtectedRoute;
