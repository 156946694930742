import Swal from 'sweetalert2'
import axios from "axios";
import saveAs from 'file-saver';
import { usuarioContext } from './userContext';
import { jwtDecode } from "jwt-decode";

export const AUTH_TOKEN = {
  token:function(mtoken){
    return mtoken;
  }
};

// console.log(usuarioContext?._currentValue);
const token = localStorage.getItem("token");
// let decoded = jwtDecode(token);
let config = {
  headers: {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json;',
    "Access-Control-Allow-Origin": "*",
  }
}
const cargarPagina = () => {
  window.location.reload();
}
const URL = "https://api.laehomes.com/crm/v1/";
const URL_TEST = "https://api.test.laehomes.diversius.com/crm/v1/";

export const signIn = async (login) => {
  const data = { email: login.email, password: login.password };
  const timeNow = Date.now();
  let fechaExpire = new Date(timeNow);
  fechaExpire = fechaExpire.setDate(fechaExpire.getDate()+27);
  
  
  try {
    const response = await axios.post(`${URL}auth/login`,data);
   
    if(response.status == 200){
      document.cookie ="LAECRM="+response.data.refreshToken+";expires="+new Date(fechaExpire).toUTCString();
      
      localStorage.setItem("expires",new Date(fechaExpire).toUTCString());
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("state", true);
      AUTH_TOKEN.token = response.data.token;
      window.location.replace("/clientes");
    }
    
    return response.status;
  }catch (err) {
    console.log(err);
    //return window.location.replace("/login")
  }
};
export const logout = async () =>{

  localStorage.removeItem('token');
  localStorage.removeItem('state');
  localStorage.removeItem('expires');
  // document.cookie.split(";").forEach(function(c) {
  //     document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  //   });
  console.log("entro correctamente");
  window.location.replace("/login");
};
export const passwordEmail = async (props) => {
  
  const data = { email: props.email };
  try {
    const response = await axios.post(
      `${URL}auth/recover-password`,
      data
    );
    
    if(response.status ===200){
      localStorage.setItem("estado", false);
    }else{
      localStorage.setItem("estado", true);
    }
    //return response.status
    
  }catch (err) {
    
    console.log(err);
    localStorage.setItem("estado", true);
  }
};
export const refreshToken = async () => {
  const data = getCookie("LAECRM");
  // localStorage.removeItem("token");
  //let fecha = new Date(1651131261*1000.0199);
  try {
    // // const response = await axios.post(`${URL}auth/token?refresh_token=${data}`).then(response => {
    // //   // localStorage.setItem("token", response.data.token);
    // //   return response.data.token;
     
    // // }).catch(res=>{
    // //   capturaError(res);
    // // });
    
    // // localStorage.setItem("token",response);
    // // usuarioContext._currentValue.token = response;
    // // // document.cookie ="LAE="+response.data.refreshToken+";expires="+fecha.toUTCString()+";path=/;";
    // // // localStorage.setItem("refreshToken", response.data.refreshToken)
    // return response;
  }catch (err) {
    console.log(err);
  }
};
const getCookie = (name) =>{
  // var arg=name;
  let misCookies = document.cookie;
  let listaCookies = misCookies.split(";");
  
  for (let i in listaCookies) {
      let busca = listaCookies[i].search(name);
      if (busca > -1) {
          let micookie=listaCookies[i]

          return micookie.split("=")[1];
      }
  
  }
 
  // return null;
}
const capturaError = (error) => {
  console.log(error);
  switch(error){
    case 401:
      // cargarPagina();
      // refreshToken();
      break;
    case 400:
      console.log(error);
  }
}
export const usuario = async () => {
  //const data = localStorage.getItem("token")
  const response = axios.get(`${URL}agent`, config).then(resp => {
    return resp.data;
  }).catch(error => { 
    console.log(error);
    
    capturaError(error.response.status);
  });

  return response;
};
export const customers = async (d) => {
  let data = {
    "page": 1,
    "itemsPerPage": 100,
    "sortField": "completeName",
    "reverse": false,
    "provinceHashes": [
      d.province
    ],
    "capturingAgentHashes": [
      d.capturingAgent
    ],
    "salesAgentHashes": [
      d.salesAgent
    ]
  }
  try {
    const response = await  axios.post(`${URL}customer/filter`, data, config)
    .catch(error =>{
      capturaError(error.response.status);  
    });
    console.log(response.data)
  
    return { type: "SUCCESS", data: response.data.items }
  
  }catch (err) {
    
    console.log(err);

  }
  //runJquery()
};
export const getCustomers = async () => {
  let data = {
    "page": 1,
    "itemsPerPage": 100,
    "sortField": "completeName",
    "reverse": false,
    "provinceHashes": [
      ""
    ],
    "capturingAgentHashes": [
      ""
    ],
    "salesAgentHashes": [
      ""
    ]
  }
  try {
    const response = await  axios.post(`${URL}customer/filter`, data,config)
    .catch(error => {
      capturaError(error.response.status);  
    });
    console.log(response.data)
  
    return { type: "SUCCESS", data: response.data.items }
    
  }catch (err) {
    
    console.log(err);
    
  }
}
export const getCustomer = async (hash) => {
  let data = hash
  try {
    const response = await  axios.get(`${URL}customer/${data}`, config)
    .catch(error =>{
      capturaError(error.response.status);  
    });
      return response.data
    
  }catch (err) {
    console.log(err);
    
  }
};
export const editCustomer = async (id, d) => {
  let hash = id
  let data = d
  console.log(data)
  data = {
    "name": d.name,
    "firstSurname": d.firstSurname,
    "secondSurname": d.secondSurname,
    "documentId": d.documentId,
    "email": d.email,
    "cityHash": d.cityHash,
    "postCode": d.postCode,
    "address": d.address,
    "managementType": d.managementType,
    "phoneNumber": d.phoneNumber,
    "birthdate": new Date(d.birthdate).toLocaleDateString()
  }
  
  console.log(data)
  try {
    const response = await axios.put(`${URL}customer/${hash}`, data, config);
    console.log(response.data)
    return response.data
  }catch (err) {
    console.log(err);
    return err.response
    
  }
};
export const getAgents = async () => {
  let response = await axios.get(`${URL}`,config)
  .then(res=> {
    console.log(res)
  }).catch(err=>{
    capturaError(err);
  })
}
export const deleteCustomer = async (hash) => {

  let response = await axios.delete(`${URL}customer/${hash}`,config)
  .then(res=>{
    return res.status;
  })
  .catch(res=>{
    capturaError(res)
    Swal.fire('Error', 'Ha habido algun error', 'error')
  })
  
  return response;
 
};
export const createCustomer = async (d) => {
 
  d.birthdate = new Date(d.birthdate).toLocaleDateString();
// PULIR
  console.log(d)
  console.log(config)
  try {
    const response = await axios.post(`${URL}customer`, d, config).then(res=>{
      if(res.status===200){
        Swal.fire({
          icon: 'success',
          text: 'Cliente creado',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout(()=>{
          window.location.replace('/clientes');
        },1500)
      }
    }).catch(error =>{
      // console.log(error.response.request.responseText)
      // CORREGIR
      if(error.response.data.errors.global.statusCode === 612){
        Swal.fire({
          icon: 'error',
          text: 'DNI repetido',
          showConfirmButton: false,
          timer: 1500
        })
      }if(error.response.status === 401){
        capturaError(error.response.status);
      }

      // capturaError(error.response.status); 
    });
    console.log(response)
    
  }catch (err) {
    // console.log(err);
    return err;
  }
};
export const reportCustomers = async (d) =>{
  let data = {
    "page": 1,
    "itemsPerPage": 100,
    "sortField": "completeName",
    "reverse": false,
    "provinceHashes": [
      d.province
    ],
    "capturingAgentHashes": [
      d.capturingAgent
    ],
    "salesAgentHashes": [
      d.salesAgent
    ]
  }
  axios.post(`${URL}customer/filter-report`, data, 
  {
    responseType: 'arraybuffer',
    // responseType: 'blob',
    headers:{
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
    }
  }).then(response => {
    console.log(response);
    const data = new Blob([ response.data ],{
      type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
  
    saveAs(data,  `listado_filtrado_clientes-${Date.now()}.xls`);
  }).catch(error=>{
    console.log(error);
  });
}
export const changePassword = async (d) => {
  let data = d
  console.log(data)
  try {
    const response = await axios.put(`${URL}user/change-password`, data, config);
    console.log(response)
    return response.data
    
  }catch (err) {
    
    console.log(err);
    return err.response
  }
};
export const getManagementType = async () => {

  try {
    const response = await axios.get(`${URL}customer/management-types`, config)
    .catch(error =>{
      capturaError(error.response.status);  
    });
    return response.data;
    // }
    
  }catch (err) {
    refreshToken();
    console.log(err);
  }
};
export const getCommunity = async () => {

  try {
    const response = await axios.get(`${URL}community`, config).then(res=>{
      return res.data;
    })
    .catch(error =>{
      capturaError(error.response.status);  
    });
    return response;
  
    
  }catch (err) {
    console.log(err);
  }
};
export const getProvince = async () => {

  try {
    console.log(config);
    const response = await axios.get(`${URL}province`, config).then(res =>{
      return res.data;
    })
    .catch(error =>{
      capturaError(error.response.status);  
    });
      return response;
    
  }catch (err) {
   
    console.log(err);
  }
};
export const getProvinceCommunity = async (hash) => {

  console.log(hash);
  try {
    const response = await axios.get(`${URL}province/${hash}`, config);
    
    // if(response.data.errors !== undefined && response.data.errors.global.code === 'Invalid JWT'){
    //   cargarPagina()
    // }else{
      return response.data
    // }
  }catch (err) {
    console.log(err);
  }
};
export const getCity = async (id) => {
  let hash = id
  try {
    const response = await axios.get(`${URL}city/${hash}`, config);
    console.log(response.data)
    return response.data

    
  }catch (err) {
    console.log(err);
  }
};
export const pendingNotifications = async () => {
  try {
    const response = await axios.get(`${URL}notification/pending`, config);
    
    return response.data.totalPendingNotifications
    
  }catch (err) {
    
    console.log(err);
  }
};
export const customerProperty = async (id) => {
  let hash = id
  try {
    const response = await axios.get(`${URL}customer/${hash}/properties`, config);
    console.log(response.data)
    return response.data
    
  }catch (err) {
    
    console.log(err);
  }
};
export const properties = async (f) => {
  
  let data = {
  
    "sortField": "name",
    "reverse": false,
    "provinceHashes": [
      f.province
    ],
    "propertyStatusCodes": [
      f.status
    ],
    "capturingAgentHashes": [
      f.capturingAgent
    ],
    "salesAgentHashes": [
      f.salesAgent
    ]
  }

  try {
    const response = await axios.post(`${URL}property/filter`,data, config).then(res=>{
      return res;
    }).catch(error => {
      capturaError(error.response.status); 
    });
    
    return { type: "SUCCESS", data: response.data.items }
    
  }catch (err) {
    
    console.log(err);
  }
};
export const getProperty = async (hash) => {
  let data = hash;
  try {
    const response = await axios.get(`${URL}property/${data}`, config)
    .catch(error => {
      console.log(error.response.data);
      console.log(error.response.status);
      if(error.response.status==401){
        refreshToken();
      }
      console.log(error.response.headers);
    });
    console.log(response.status)
    
    return response.data;
    
  }catch (err) {
    
    console.log(err);
  }
};
export const getStatuses = async () => {
  try {
    const response = await axios.get(`${URL}property/statuses`,config);
    return response.data
  } catch (error) {
    
  }
};
export const getEnergyCertificate = async () => {
  try {
    const response = await axios.get(`${URL}property/energy-certificates`,config);
    return response.data
  } catch (error) {
    
  }
};
export const getFloors = async () =>{
  try {
    const response = await axios.get(`${URL}property/floors`,config);
    return response.data
  } catch (error) {
    
  }
};
export const getPropertyTypes = async () =>{
  try {
    const response = await axios.get(`${URL}property/types`,config);
    return response.data
  } catch (error) {
    console.log(error)
  }
};
export const getConservationState = async () => {
  try {
    const response = await axios.get(`${URL}property/conservation-states`,config);
    return response.data
  } catch (error) {
    console.log(error)
  }
};
export const createProperty = async (dt) => {
  
  let body = {
      "ownerHash": dt.ownerHash,
      // "buyerHash": dt.buyerHash,
      "status": dt.status,
      "name": dt.name,
      "cityHash": dt.cityHash,
      "address": dt.address,
      "postCode": dt.postCode,
      "customerPrice": dt.customerPrice,
      "laePrice": dt.laePrice,
      "appraisalPrice": dt.appraisalPrice,
      "managedByLae": dt.managedByLae === undefined ? false : dt.managedByLae,
      "publicationDate": new Date().getTime(),
      "energyCertificate": dt.energyCertificate,
      "type": dt.propertyType === undefined ? '' : dt.propertyType,
      "floor": dt.floor === undefined ? '' : dt.floor,
      "conservationState": dt.propertyConservationState  === undefined ? '' : dt.propertyConservationState,
      "bedrooms": dt.bedrooms === undefined ? 0 : dt.bedrooms,
      "bathrooms": dt.bathrooms === undefined ? 0 : dt.bathrooms,
      "squareMeters": dt.squareMeters === undefined ? 0 : dt.squareMeters,
      "lift": dt.lift === undefined ? false : dt.lift,
      "garage": dt.garage === undefined ? false : dt.garage,
      "terrace": dt.terrace === undefined ? false : dt.terrace,
      "yard": dt.yard === undefined ? false : dt.yard,
      "swimmingPool": dt.swimmingPool === undefined ? false : dt.swimmingPool,
  };
  
  console.log(body)
  
  var formData = new FormData();
  formData.append("ownerHash",dt.ownerHash)
  formData.append('status', body.status);
  formData.append('name', body.name);
  formData.append('cityHash', body.cityHash);
  formData.append('address', body.address);
  formData.append('postCode', body.postCode);
  formData.append('customerPrice', body.customerPrice);
  formData.append('laePrice', body.laePrice);
  formData.append('appraisalPrice', body.appraisalPrice);
  formData.append('managedByLae', body.managedByLae);
  formData.append('publicationDate', body.publicationDate);
  formData.append('energyCertificate', body.energyCertificate);
  formData.append('type', body.type);
  formData.append('floor', body.floor);
  formData.append('conservationState', body.conservationState);
  formData.append('bedrooms', body.bedrooms);
  formData.append('bathrooms', body.bathrooms);
  formData.append('squareMeters', body.squareMeters);
  formData.append('lift', body.lift);
  formData.append('garage', body.garage);
  formData.append('terrace', body.terrace);
  formData.append('yard', body.yard);
  formData.append('swimmingPool', body.swimmingPool);

  if(dt.imagen!==undefined){
    formData.append('thumbnailImageFile', dt.imagen[0]);
  }
  
  // console.log(dt.propertyImages[0])  
  if(dt.propertyImages.length>0){
    for(let img = 0; img<= dt.propertyImages[0].length;img++){
      if(dt.propertyImages[0][img]!==undefined)
      formData.append('imageFiles',dt.propertyImages[0][img]);
    }
  }
  
  axios.post(`${URL}/property`, formData, {
    headers:{
    'Access-Control-Allow-Origin': "*", 
    'Content-Type':'multipart/form-data',
    'Authorization': 'Bearer ' + localStorage.getItem("token"),
  }}
  ).then(res=>{
      console.log(res.status)
    if(res.status===200){
      console.log(res.status)
      Swal.fire({
        icon: 'success',
        text: 'Inmueble dado de alta!',
        showConfirmButton: false,
        timer: 1500
      });
      window.location.replace('/inmuebles');
    }
  }).catch(error =>{
    console.log(error.response);
  });
  
};
export const editProperty = async (hash, dt) => {
  
  // console.log(dt.propertyFloor)
  // console.log(dt.floor)
  // console.log(dt)
  let body = {
      "ownerHash": dt.ownerHash===undefined?dt.owner.hash:dt.ownerHash,
      "buyerHash": '',
      "status": dt.status===undefined?dt.propertyStatus.code:dt.status,
      "name": dt.name,
      "cityHash": dt?.city?.hash !== undefined? dt.city.hash : dt.cityHash,
      "address": dt.address,
      "postCode": dt.postCode,
      "customerPrice": dt.customerPrice,
      "laePrice": dt.laePrice,
      "appraisalPrice": dt.appraisalPrice,
      "managedByLae": dt.managedByLae === undefined ? false : dt.managedByLae,
      "publicationDate": new Date().getTime(),
      "energyCertificate": dt.energyCertificate.code!==undefined? dt.energyCertificate.code : dt.energyCertificate,
      "type": dt.propertyType.code !== undefined ? dt.propertyType?.code : dt.propertyType,
      "floor": dt.floor===undefined?dt.propertyFloor:dt.floor,
      "conservationState": dt.propertyConservationState.code  !== undefined ? dt.propertyConservationState.code : dt.propertyConservationState,
      "bedrooms": dt.bedrooms === undefined ? 0 : dt.bedrooms,
      "bathrooms": dt.bathrooms === undefined ? 0 : dt.bathrooms,
      "squareMeters": dt.squareMeters === undefined ? 0 : dt.squareMeters,
      "lift": dt.lift === undefined ? false : dt.lift,
      "garage": dt.garage === undefined ? false : dt.garage,
      "terrace": dt.terrace === undefined ? false : dt.terrace,
      "yard": dt.yard === undefined ? false : dt.yard,
      "swimmingPool": dt.swimmingPool === undefined ? false : dt.swimmingPool,
      "imageToDeleteHashes":dt.imageToDeleteHashes===undefined?[]:dt.imageToDeleteHashes
  };
  // const json = JSON.stringify(data);
  // const blob = new Blob([json], {
  //   type: 'application/json'
  // });
  // console.log(dt)
  // console.log(dt.floor)
  console.log(body);
  var formData = new FormData();
  
  formData.append("ownerHash",dt.ownerHash);
  formData.append('status', body.status);
  formData.append('name', body.name);
  formData.append('cityHash', body.cityHash);
  formData.append('address', body.address);
  formData.append('postCode', body.postCode);
  formData.append('customerPrice', body.customerPrice);
  formData.append('laePrice', body.laePrice);
  formData.append('appraisalPrice', body.appraisalPrice);
  formData.append('managedByLae', body.managedByLae);
  formData.append('publicationDate', body.publicationDate);
  formData.append('energyCertificate', body.energyCertificate);
  formData.append('type', body.type);
  formData.append('floor', body.floor);
  formData.append('conservationState', body.conservationState);
  formData.append('bedrooms', body.bedrooms);
  formData.append('bathrooms', body.bathrooms);
  formData.append('squareMeters', body.squareMeters);
  formData.append('lift', body.lift);
  formData.append('garage', body.garage);
  formData.append('terrace', body.terrace);
  formData.append('yard', body.yard);
  formData.append('swimmingPool', body.swimmingPool);
  formData.append("imageToDeleteHashes",body.imageToDeleteHashes);
  formData.append('thumbnailImageFile', dt.thumbnailImageUrl===undefined?dt.imagen[0]:dt.thumbnailImageUrl);
  console.log(dt.propertyImages)
  if(dt.propertyImages.length>0){
    for(let img = 0; img<= dt.propertyImages[0].length;img++){
      if(dt.propertyImages[0][img]!==undefined)
      formData.append('imageFiles',dt.propertyImages[0][img]);
    }
  }
  
  const response = await axios.put(`${URL}property/${hash}`, 
  formData, 
  {
    headers:{
      'Access-Control-Allow-Origin': "*", 
      'Content-Type':'multipart/form-data',
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
    }
  }).then(res=>{
    if(res.status===200){
      console.log(res)
      Swal.fire({
        icon: 'success',
        text: '¡Inmueble editado correctamente!',
        showConfirmButton: false,
        timer: 2500
      })
      // window.location.replace('/inmuebles')
    }
  }).catch(error=>{
      console.log(error.response)
  });
   
};
export const publishProperties = async (data) => {
  let body = {
    "propertyHash":data.hash,
    "name":data.name,
    "description":data.description === undefined?'':data.description,
    "imageToDeleteHashes":data.imageToDeleteHashes,
    "price":data.laePrice,
    "exclusiveUntilDate":data.exclusiveUntilDate === undefined?'':data.exclusiveUntilDate,
    "energyCertificate":data.energyCertificate.code
  }
  console.log(data)

  var formData = new FormData();
  formData.append('propertyHash', body.propertyHash);
  formData.append('name', body.name);
  formData.append('description', body.description);
  formData.append('price', body.price);
  formData.append('exclusiveUntilDate',body.exclusiveUntilDate);
  formData.append('energyCertificate', body.energyCertificate);
  formData.append('thumbnailImageFile', data.thumbnailImageUrl);
  // formData.append('imageFiles',data.images[0]);
  if(data.images[0]!==undefined){
    if(data.images[0].length>0){
      for(let img = 0; img<= data.images[0].length;img++){
        if(data.images[0][img]!==undefined)
          formData.append('imageFiles', data.images[0][img]);
      }
    }
  }
  
  
  await axios.post(`${URL}property/publication`,formData,
  {
    headers:{
      'Content-Type':'multipart/form-data', 
      'Access-Control-Allow-Origin': "*", 
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
    }
  })
  .then((response)=>{
    console.log(response);
    if(response.status===200){
      
      Swal.fire({
        icon: 'success',
        text: 'Inmueble Publicado!',
        showConfirmButton: false,
        timer: 2000
      });
      window.location.replace('/inmuebles');
    }
  }).catch(error =>{
    console.log(error.response);
  });
}
export const deletePublishProperties = async(hash) =>{
  let response = await axios.delete(`${URL}property/publication/${hash}`,config)
  .then(res=>{
    return res.status;
  })
  .catch(res=>{
    Swal.fire('Error', 'Ha habido algun error', 'error');
  })
  
  return response;
}
export const deleteProperty = async (id) => {
  console.log(id)
  let hash = id
    
  try {
    const response = await axios.delete(`${URL}property/${hash}`, config);
    console.log(response.data)
    return response.data
  }catch (err) {
    console.log(err);
    
  } 
  
};
export const reportProperties = async (dt) => { 
  console.log(dt)   
  let data = {
    "page": 1,
    "itemsPerPage": 10,
    "sortField": "name",
    "reverse": false,
    "provinceHashes": [
      dt.province
    ],
    "propertyStatusCodes": [
      dt.status
    ],
    "capturingAgentHashes": [
      dt.capturingAgent
    ],
    "salesAgentHashes": [
      dt.salesAgent
    ]
  }
  axios.post(`${URL}property/filter-report`, data, 
  {
    responseType: 'arraybuffer',
    headers:{
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
    }
  }).then(response=>{
    console.log(response);
    console.log(response);
    const data = new Blob([ response.data ],{
      type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
  
    saveAs(data,  `listado_filtrado_inmuebles-${Date.now()}.xls`);

  }).catch(error=>{
    console.log(error);
  });
  
};
export const getContracts = async () => {
  try {
    const response = await axios.get(`${URL}contract`, config);
    
    return response.data
    
  }catch (err) {
    
    console.log(err);
  }
};
export const getPropertyDocuments = async (id) => {
  let hash = id
  let data = {
    "page": 1,
    "itemsPerPage": 10,
    "sortField": "date",
    "reverse": false
  }
  //let hash = 'd0c2bc7d9807e0cd86a5627aa1477c1f'
  try {
    const response = await axios.post(`${URL}property/${hash}/documents`, data, config);
    return response.data
    
  }catch (err) {
    
    console.log(err);
  }
};
export const getPropertyContracts = async (id) => {
  let hash = id
  //let hash = '8e741d6951121d162555f19daebd91fd'
  try {
    const response = await axios.get(`${URL}property/${hash}/contracts`, config);
    
    return response.data
    
  }catch (err) {
    
    console.log(err);
  }
};
export const getServiceType = async () => {
  try {
    const response = await axios.get(`${URL}service-type`, config);
    
    return response.data
    
  }catch (err) {
    
    console.log(err);
  }
};
export const getDocumentType = async () => {
  try {
    const response = await axios.get(`${URL}customer/document-types`, config);
    
    return response.data
    
  }catch (err) {
    
    console.log(err);
  }
};
export const getCustomerDocuments = async (id) => {
  let hash = id
  //console.log(hash)
  let data = {
    "page": 1,
    "itemsPerPage": 10,
    "sortField": "date",
    "reverse": false
  }
  try {
    const response = await axios.post(`${URL}customer/${hash}/documents`, data, config);
    //console.log(response.data)
    return response.data
    
  }catch (err) {
    
    console.log(err);
  }
};
export const downloadCustomerDocument = async (idProperty, idCustomer) => {

  let hash = '7f9bb8152d06e4682eac705cc3c9fd7a'
  let data = {
    "customerDocumentHashes": idProperty
  }
  await axios.post(`${URL}customer/${hash}/documents/download`, data, {
    responseType: 'blob',
    headers:{
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
    }
  }).then(response=>{
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${hash}.zip`); //or any other extension
    document.body.appendChild(link);
    link.click();

  }).catch(error=>{
  console.log(error);
  });
  
};
export const uploadDocCustomer = async (doc) => {
  console.log(doc)
  
  var formData = new FormData();
  formData.append('documentFile', doc.documento)
  
  try {
    const response = await axios.post(`${URL}customer/${doc.hash}/document?documentType=${doc.tipo}&title=${doc.titulo}`, 
    formData,
    {
      headers:{
        'Content-Type':'multipart/form-data', 
        "Access-Control-Allow-Origin": "*", 
        'Authorization': 'Bearer ' + localStorage.getItem("token")
      }
    });
    console.log(response.data)
    Swal.fire({
      icon: 'success',
      text: 'Documento subido',
      showConfirmButton: false,
      timer: 1500
    })
    setTimeout(()=>{
      window.location.reload()
    },1500)
    
  }catch (err) {
    console.log(err);
    Swal.fire({
      icon: 'error',
      text: 'Ha ocurrido un error',
      showConfirmButton: false,
      timer: 1500
    })
  }
};
export const getDocumentTypeProperties = async () => {
  try {
    const response = await axios.get(`${URL}property/document-types`, config);
    
    return response.data
    
  }catch (err) {
    
    console.log(err);
  }
};
export const downloadPropertyDocument = async (idDocs, idProperty) => {

  let hash = idProperty
  let data = {
    "propertyDocumentHashes": idDocs
  }
  await axios.post(`${URL}property/${hash}/documents/download`, data, {
    responseType: 'blob',
    headers:{
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
    }
  }).then(response=>{
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${hash}.zip`); //or any other extension
    document.body.appendChild(link);
    link.click();

  }).catch(error=>{
    console.log(error);
  });
  
};
export const uploadDocProperty = async (doc) => {
  console.log(doc)
  
  var formData = new FormData();
  formData.append('documentFile', doc.documento)
  
  try {
    const response = await axios.post(`${URL}property/${doc.hash}/document?documentType=${doc.tipo}&title=${doc.titulo}`, 
    formData,
    {
      headers:{
        'Content-Type':'multipart/form-data', 
        "Access-Control-Allow-Origin": "*", 
        'Authorization': 'Bearer ' + localStorage.getItem("token")
      }
    });
    console.log(response.data)
    Swal.fire({
      icon: 'success',
      text: 'Documento subido',
      showConfirmButton: false,
      timer: 1500
    })
    setTimeout(()=>{
      window.location.reload()
    },1500)
    
  }catch (err) {
    console.log(err);
    Swal.fire({
      icon: 'error',
      text: 'Ha ocurrido un error',
      showConfirmButton: false,
      timer: 1500
    })
  }
};
export const getContractsDocumentTypes = async () =>{
  const response = await axios.get(`${URL}contract/document-types`, config)
  .then(res=>{
    return res.data
  }).catch(error => {
    console.log(error);
  });
  return response
}
export const getContract = async (hash) =>{
  console.log()
  const response = await axios.get(`${URL}contract/${hash}`, config)
  .then(res => {
    return res.data;
  }).catch(error => {
    capturaError(error);
    console.log(error);
  })

  return response;
}
export const createContracts = async (d) => {
  console.log(d)
  let data = {
    "capturingAgentHash": "8eb33b405a1d02fc61acbd003e85d17a",
    "serviceTypeRequests": [
      {
        "serviceTypeHash": "0c2a74d0238cba79eb16671dc4384120",
        "price": 73.56
      }
    ],
    "ownerHash": d.ownerHash,
    "price": d.price,
    "propertyHash": d.propertyHash,
    "provinceHash": "e38d4d9ed0e8fe0288de76cd16b94716",
    "salesAgentHash": "8eb33b405a1d02fc61acbd003e85d17a"
  }
  try {
    const response = await axios.post(`${URL}contract`, data, config);
    if(response.status===200){
      Swal.fire({
        icon: 'success',
        text: 'Inmueble dado de alta!',
        showConfirmButton: false,
        timer: 1500
      });
      window.location.replace('/contratos');
    }
    return response.data;
    
  }catch (err) {
    
    console.log(err);
  }
};
