import axios from "axios";

const urlPath = "hojasForm";

export const generateRef = async (id) => {
    try {
        const response = await axios.get(`${urlPath}/generate-reference/${id}`);
        console.log(response);
        return response.data.ref;
    } catch (error) {
        console.log(error);
    }
};
export const listHojasForm = async (data) => {
    try {
        const response = await axios.post(`${urlPath}/filter`, data.filtros, {
            params: data.params,
        });

        return response;
    } catch (error) {
        console.log(error);
    }
};
export const getHojaForm = async (id) => {
    try {
        const response = await axios.get(`${urlPath}/${id}`);        
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const createHojaForm = async (data) => {
    console.log(data)
    try {
        const response = await axios.post(`${urlPath}/`, data);
        console.log(response);
        return response;
    } catch (error) {
        console.log(error);
    }
};
export const updateHojaForm = async (id, data) => {
    try {
        const response = await axios.put(`${urlPath}/${id}`, data);
        
        return response;
    } catch (error) {
        console.log(error);
    }
};
export const deleteHojaForm = async (id) => {
    try {
        const response = axios.delete(`${urlPath}/${id}`);

        return response;
    } catch (error) {
        console.log(error);
    }
};
export const verifyHojaForm = async (id) => {
    try {
        const response = await axios.put(`${urlPath}/verify-hoja/${id}`);
        console.log(response);
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
   
}