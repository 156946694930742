import axios from "axios";
const jsonTitulos = require('../titulos_personalizados.json'); // Cargar el archivo JSON de títulos

const urlPath = "sucursales";

export const listSucursales = async (data) => {
    try {
        // console.log(data);
        const response = await axios.post(`${urlPath}/list`, data.filtros, {
            params: data.params,
        });
        // console.log(response.data);
        return response.data;
    } catch (error) {

        console.log(error);
    }
};

export const sucursalDetails = async (id) => {
    try {
        const response = await axios.get(`${urlPath}/${id}`);
        console.log(response);
        
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const JsonCustomTexts = (arrayObjetos, elemento, seccion, delete_element = []) => {
    const titulosPersonalizados = [];

    // Verificar si el elemento y la sección existen en el JSON de títulos
    if (jsonTitulos[elemento] && jsonTitulos[elemento][seccion]) {
        // Iterar sobre cada objeto en el array
        arrayObjetos.forEach((objeto) => {
            const objetoModificado = {}; // Objeto para almacenar las claves modificadas

            // Iterar sobre cada clave del objeto
            Object.keys(objeto).forEach((key) => {
                if (!delete_element.includes(key)) {
                    // Buscar la clave en la sección especificada del JSON de títulos
                    const titulosSeccion = jsonTitulos[elemento][seccion];
                    const tituloEncontrado = titulosSeccion.find((titulo) => titulo.titulo_original_db === key);
                    if (tituloEncontrado) {
                        // Si se encuentra el título correspondiente, modificar la clave del objeto
                        objetoModificado[tituloEncontrado.titulo_personalizado] = objeto[key];
                    } else {
                        // Si no se encuentra el título correspondiente, mantener la clave original
                        objetoModificado[key] = objeto[key];
                    }
                }

            });

            // Almacenar el objeto modificado en el array de salida
            titulosPersonalizados.push(objetoModificado);
        });
    } else {
        console.error(`No se encontró la sección '${seccion}' en el elemento '${elemento}' del JSON de títulos.`);
    }

    return titulosPersonalizados;
}


export const saveSucursal = async (data) => {
    try {
        const response = await axios.post(`${urlPath}`, data);
        return response;
    } catch (error) {
        console.log(error);
        return error;

    }
};
export const updateSucursal = async (id, data) => {
    try {
        const response = await axios.put(`${urlPath}/${id}`, data);
        return response;
    } catch (error) {
        console.log(error);
        return error;

    }
};

export const deleteSucursal = async (id) => {
    try {
        const response = await axios.delete(`${urlPath}/${id}`);
        // console.log(response);
        return response;
    } catch (error) {
        return error;
    }
};

export const publicar = async (id) => {
    try {
        const res = await axios.put(`${urlPath}/publicar/${id}`);
        return res.status;
    } catch (error) {

        console.log(error);
    }
}
export const despublicar = async (id) => {
    try {
        const res = await axios.put(`${urlPath}/despublicar/${id}`);
        return res
    } catch (error) {

        console.log(error);
    }
}