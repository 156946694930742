import React from "react";
import {
    FormControlLabel,
    Switch
} from '@mui/material';

const SwitchCustom = ({ value = false, handleChange = () => {}, name = '', label = '' }) => {
    const switchStyles = {
        width: '220px',
        marginLeft:'1px',
        '& .MuiFormControlLabel-label': {
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& .MuiButtonBase-root.MuiSwitch-switchBase':{
            color: '#33383b'
        },
        '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked': {
            color: '#c4af6c !important'
        },
        '& .MuiSwitch-thumb.Mui-checked': {
            backgroundColor: '#c4af6c !important',
        },
        '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
            backgroundColor: '#c4af6c !important',
        },
    };
    const formatValueSwitch = (value) => {
        let val = false;
        if(parseInt(value) === 0){
            val = false;
        } else if (parseInt(value) === 1) {
            val = true;
        }
        return val;
    }
    return (
        <FormControlLabel
            control={
                <Switch 
                    color="primary" 
                    defaultChecked={!!formatValueSwitch(value)}
                />
            }
            label={label}
            labelPlacement="start"
            name={name}
            sx={switchStyles}
            defaultValue={`${formatValueSwitch(value)}`}
            onChange={(e) => handleChange(e)}
        />
    )
}

export default SwitchCustom;
