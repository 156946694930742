import React, { useState, useEffect, useContext } from "react";
import BreadCrumb from "../../components/navbar/Breadcrumb";
import { Button, Card, ListGroup, Row, Badge } from "react-bootstrap";
import { deleteSucursal, publicar, despublicar } from "../../services";
import {
    FaTableList,
    FaTableCellsLarge,
    FaMapPin,
    FaPhone,
    FaEnvelope,
} from "react-icons/fa6";
import { SnackbarUtilities } from "../../utilities";
import "./suscursales.css"
import EditIcon from '@mui/icons-material/Edit';
import { DataTable } from "../../components";
import { transforHeader, jsonRenameItems, jsonRenameItemsToOriginal, dynamicTableHeader } from "../../utilities/transformHeaders";
import {
    Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSucursales } from "../../hooks";
import { UserContext } from '../../hooks/UserContext'; // Importa el contexto
import { Link, useNavigate } from "react-router-dom";
import ButtonPermission from "../../utilities/ButtonPermission";
import { FaSearch } from "react-icons/fa";
import { useUser } from "../../hooks/UserContextLogin";

const Sucursales = () => {
    const { setLoading, data, fetchData, dataCustomNames } = useSucursales();

    const [vista, setVista] = useState(true);

    const { usuario } = useContext(UserContext); // Accede al contexto del usuario
    const { user, setUser } = useUser();

    const navigate = useNavigate();


    const handleClick = () => {
        // window.open(window.location.origin + `/Sucursales/edit`, "_top");
        navigate("/Sucursales/edit",);
    }
    const handleClickGoTo = (id) => {
        window.open(`${window.location.origin}/Sucursales/edit/${id}`, "_blank");
    }
    const handleClickGoToFicha = (id) => {
        window.open(`${window.location.origin}/sucursales/ficha/${id}`, "_top");
    }
    const handleChange = () => {
        setVista(!vista);
    }
    const handleDespublicarSucursal = async (id) => {
        const res = await despublicar(id);
        if (res == 201) {
            SnackbarUtilities.info("Sucursal despublicada");
            setTimeout(() => {
                window.location.replace('/sucursales');
            }, 3000);
        }
    }
    const handlePublicarSucursal = async (id) => {
        const res = await publicar(id);
        if (res == 201) {
            SnackbarUtilities.success("Sucursal publicada !");
            setTimeout(() => {
                window.location.replace('/sucursales');
            }, 3000);
        }
    }
    const handleDelete = async (id) => {
        console.log(id)
        const response = await deleteSucursal(id);
        console.log(response);
        if (response.status === 201) {
            SnackbarUtilities.success("Sucursal actualizada correctamente");
            setTimeout(() => {
                window.location.replace('/sucursales');
            }, 3000);
        }
    }

    useEffect(() => {
        fetchData({ offset: 1, limit: 1000, filtros: { estado: 1 } });

    }, []);
    // console.log(user.rol);
    // console.log(user.rol?.permisos?.tablas?.Sucursales.includes('Estados', 'pepe'));
    // const missingElements = ['Estados', 'Acciones'].filter(element => !user.rol?.permisos?.tablas?.Sucursales.includes(element));
    // console.log(missingElements.length);

    const iconColumn = [{
        item: (name) => {
            return (
                <Typography
                    sx={{

                        color: "#33383b !important",
                        "&:hover": { color: "#33383b" },
                        "&:active": { color: "#33383b" },
                        fontSize: "0.9rem",
                        fontWeight: "1rem"
                    }}
                >
                    <div style={{ marginLeft: "15px" }}>{name?.estado ? <CheckIcon sx={{ color: "green", fontSize: 38 }} /> : <CloseIcon sx={{ color: "red", fontSize: 38 }} />}</div>
                </Typography>
            )
        },
        position: data.rows.length,
        titleHeader: "Estados",
        nameColumn: "estado"
    },
    {
        item: (name) => (
            <div style={{ marginLeft: -23, display: "flex", justifyContent: "center" }}>
                <button className="btn btn-outline-dark" onClick={() => handleClickGoTo(name.id)}>
                    <EditIcon />
                </button>
                <button className="btn btn-outline-dark" onClick={() => handleDelete(name.id)} style={{ marginLeft: "20px" }}>
                    <DeleteIcon />
                </button>
            </div>
        ),
        position: data.rows.length,
        titleHeader: "Acciones"
    }
    ]

    return (
        <div className="container">
            {/* <BreadCrumb /> */}
            <div className="top-line">
                <div style={{ textAlign: "start" }}>
                    <h3>Sucursales</h3>
                </div>
            </div>
            <div style={{ display: "flex", flex: 0.5, justifyContent: "end", gap: 8, marginBottom: 10 }}>
                {
                    <ButtonPermission
                        variant="outlined"
                        sx={{
                            color: '#c4af6c',
                            backgroundColor: '#33383b',
                            height: 40,
                            borderColor: 'transparent',
                            '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                        }}
                        action="edit"
                        resource="Sucursales" onClick={handleClick}>
                        Crear
                    </ButtonPermission>
                }

                <Button onClick={handleChange} variant="outline-dark">
                    {vista ? <FaTableList /> : <FaTableCellsLarge />}
                </Button>
            </div>
            <div style={{ display: "flex", flex: 1, justifyContent: "end" }}>
                {/* <Paginacion
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                /> */}
            </div>
            <div className="middleline">
                {vista ?

                    <div className="row">
                        {data.rows.map((d, index) => {
                            // prepareRow(d);
                            // const u = d.original;
                            // console.log(u);
                            // console.log(d);
                            return (
                                <div className="col-4">
                                    <Card border="secondary" style={{ textAlign: "left", width: '19rem', height: '17rem', marginBottom: '10px', cursor: 'pointer' }} onClick={() => handleClickGoToFicha(d.id)}>
                                        <Card.Body>
                                            <Card.Title className="header-card" style={{ color: "#c4af6c" }}>
                                                <h3>{d.provincia} - {d.municipio}</h3>
                                            </Card.Title>
                                            <ListGroup>
                                                <ListGroup.Item>
                                                    <span className="icons"><FaMapPin /></span>&nbsp;{d.direccion} <spam style={{ fontWeight: 'bold', fontFamily: 'Forum' }}>CP</spam>:  {d.cp}
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <span className="icons"><FaPhone /></span>&nbsp;{d.telefono}
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <span className="icons"><FaEnvelope /></span>&nbsp;{d.email}
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Card.Body>
                                    </Card>
                                </div>
                            )
                        })}
                    </div>
                    :
                    <DataTable
                        data={data}
                        fetcher={fetchData}
                        headers={dynamicTableHeader({ headerArrayData: dataCustomNames, showJustThis: ["Provincia", "Municipio", "Dirección", "Teléfono", "CP", "Email"], addNewCustomHeader: iconColumn,userPermision:user,permissionResourceName:"Sucursales" })}
                    />
                }
            </div>
            <div style={{ display: "flex", flex: 1, justifyContent: "end" }}>
                {/* <Paginacion
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                /> */}
            </div>
        </div>
    );
}

export default Sucursales;