import axios from "axios";

const urlPath = "languages";

export const listLanguages = async ({ offset, limit }) => {
  try {
    const response = await axios.get(urlPath, { offset, limit });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const languageDetails = async (id) => {
  try {
    const response = await axios.get(`${urlPath}/${id}`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const languageCreate = async (data) => {
  try {
    const response = await axios.post(urlPath, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const languageUpdate = async (id, data) => {
  try {
    const response = await axios.put(`${urlPath}/${id}`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const languageDelete = async (id) => {
  try {
    const response = await axios.delete(`${urlPath}/${id}`);
    return response;
  } catch (error) {
    console.log(error);
  }
};
