import React from "react";
// import { jwtDecode } from "jwt-decode";
import jwt_decode from "jwt-decode";

import {refreshToken} from './api';
// import {User} from './Classes/User';
let token = localStorage.getItem("token");
let state = localStorage.getItem("state");
let expires = localStorage.getItem("expires");
let decoded = "";
let usuario = {};

// let user = new User();

if(token!==null){
    // setInterval(()=>{
    //     refreshToken().then(res=>{
    //         token = res;
    //         localStorage.setItem("token",res);
    //     });
    //     // console.log(`context ${token}`);
    //     // console.log(user)
    // },100000);
}

if (state && token!==null && expires!==null){
    decoded = jwt_decode(token);
    usuario = {
        exp: decoded.exp,
        iat: decoded.iat,
        nombre: decoded.nombre,
        rol: decoded.rol,
    }
}else{
    usuario = {};
}

export const usuarioContext = React.createContext(
    usuario
)