import { useEffect, useState } from "react";
import {
    TextField,
    InputAdornment,
} from '@mui/material';

const HojaImporteForm = ({
    propiedad, 
    setImporte, 
    importe, 
    handleChange, 
    inputStyles,
    comision, 
    tipoCom,
    formatNumbers, 
    formData}) => {
    
    const [formattedComision, setFormattedComision] = useState(comision);
    
    const handleComisionBlur = () => {
        const formattedValue = comision.toLocaleString('de-DE', { });
        setFormattedComision(formattedValue);
    };
    const handleComisionFocus = () => {

        setFormattedComision('');
    };
    return (
        
        <>
            <TextField
                onChange={handleChange}
                label="Total"
                size="small"
                name="total"
                value={formatNumbers(importe)}
                sx={{ ...inputStyles, maxWidth: '30%' }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">€</InputAdornment>
                    )
                }}
            />

            <TextField
                onChange={handleChange}
                label="Tipo Com."
                size="small"
                name="tipoCom"
                value={tipoCom ?? ''}
                sx={{ ...inputStyles, maxWidth: '20%' }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">%</InputAdornment>
                    )
                }}
            />
            <TextField
                onChange={handleChange}
                onBlur={handleComisionBlur}
                onFocus={handleComisionFocus}
                label="Comisión"
                size="small"
                name="comision"
                value={formattedComision || comision}
                sx={{ ...inputStyles, maxWidth: '20%' }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">€</InputAdornment>
                    )
                }}
            />
        </>
    );
};

export default HojaImporteForm;