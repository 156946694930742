import axios from "axios";
const urlPath = 'users';


export const listRoles = async () => {
    try {
        const response = await axios.get(`${urlPath}/rol/list`);
    
        return response.data;
    } catch (error) {
        return error;
    }
}