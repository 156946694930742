import {
    Box,
    TextField,
    InputAdornment,
    Paper,
    Typography,
    Grid
} from '@mui/material';

const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
};
const paperStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
    marginBottom: '10px',
    paddingLeft: '10px'
};

const HojaRepartosForm = ({ handleChange, formData }) => {
    const numberOptions =  { minimumFractionDigits: 2, maximumFractionDigits: 2};
    
    return (
        <>
            <Grid item xs={8}>
                <Paper elevation={0} sx={{ ...paperStyles, maxWidth: '100%', marginTop: '2%' }}>
                    <Box sx={{ width: '100%' }}>
                        <Typography variant="h4" sx={{ textAlign: 'left', color: '#33383b', marginBottom: '16px' }}>
                            Reparto
                        </Typography>
                        <Box sx={{ width: '100%' }} >
                            {/* COMISION COORDINADOR */}
                            <TextField
                                onChange={handleChange}
                                label="Coordinador"
                                size="small"
                                name="porCoor"
                                inputProps={{ readOnly: true }}
                                value={formData.porCoor ?? ''}
                                sx={{ ...inputStyles, maxWidth: '40%', marginTop: '10px', marginLeft: '10px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">%</InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                onChange={handleChange}
                                label="Coordinador"
                                size="small"
                                type='text'
                                name="totalCoor"
                                inputProps={{ readOnly: true }}
                                value={formData?.totalCoor?.toLocaleString('es-ES', numberOptions) ?? ''}
                                sx={{ ...inputStyles, maxWidth: '40%', marginTop: '10px', marginLeft: '10px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">€</InputAdornment>
                                    )
                                }}
                            />
                            {/* COMISION RESPONSABLE*/}
                            <TextField
                                onChange={handleChange}
                                label="Responsable"
                                size="small"
                                name="porRespo"
                                inputProps={{ readOnly: true }}
                                value={formData.porRespo ?? ''}
                                sx={{ ...inputStyles, maxWidth: '40%', marginTop: '10px', marginLeft: '10px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">%</InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                onChange={handleChange}
                                label="Responsable"
                                size="small"
                                type='text'
                                name="totalRespo"
                                inputProps={{ readOnly: true }}
                                value={formData?.totalRespo?.toLocaleString('es-ES', numberOptions) ?? ''}
                                sx={{ ...inputStyles, maxWidth: '40%', marginTop: '10px', marginLeft: '10px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">€</InputAdornment>
                                    )
                                }}
                            />
                            {/* COMMISION AAFF */}
                            <TextField
                                onChange={handleChange}
                                size="small"
                                label="AAFF"
                                name="porAaff"
                                inputProps={{ readOnly: true }}
                                value={formData?.aaff[0]?.porAaff ?? ''}
                                sx={{ ...inputStyles, maxWidth: '40%', marginTop: '10px', marginLeft: '10px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">%</InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                onChange={handleChange}
                                label="AAFF"
                                size="small"
                                name="totalAaff"
                                inputProps={{ readOnly: true }}
                                value={formData?.aaff[0]?.totalAaff?.toLocaleString('es-ES', numberOptions) ?? ''}
                                sx={{ ...inputStyles, maxWidth: '40%', marginTop: '10px', marginLeft: '10px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">€</InputAdornment>
                                    )
                                }}
                            />

                            {/* COMISION CAPTADOR */}
                            <TextField
                                onChange={handleChange}
                                label="Captador"
                                size="small"
                                name="porCaptador"
                                inputProps={{ readOnly: true }}
                                value={formData.porCaptador ?? ''}
                                sx={{ ...inputStyles, maxWidth: '40%', marginTop: '10px', marginLeft: '10px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">%</InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                onChange={handleChange}
                                label="Captador"
                                size="small"
                                type='text'
                                name="totalCaptador"
                                inputProps={{ readOnly: true }}
                                value={formData.totalCaptador?.toLocaleString('es-ES', numberOptions) ?? ''}
                                sx={{ ...inputStyles, maxWidth: '40%', marginTop: '10px', marginLeft: '10px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">€</InputAdornment>
                                    )
                                }}
                            />
                           
                            {/* COMMISION VENDEDOR */}
                            <TextField
                                onChange={handleChange}
                                label="Vendedor"
                                size="small"
                                name="porVend"
                                inputProps={{ readOnly: true }}
                                value={formData.porVendedor  ?? ''}
                                sx={{ ...inputStyles, maxWidth: '40%', marginTop: '10px', marginLeft: '10px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">%</InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                onChange={handleChange}
                                label="Vendedor"
                                size="small"
                                name="totalVendedor"
                                inputProps={{ readOnly: true }}
                                value={formData.totalVendedor?.toLocaleString('es-ES', numberOptions)  ?? ''}
                                sx={{ ...inputStyles, maxWidth: '40%', marginTop: '10px', marginLeft: '10px' }}
                                InputProps={{   
                                    endAdornment: (
                                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">€</InputAdornment>
                                    )
                                }}
                            />
                            {/* COMISION COLABORADOR */}
                            <TextField
                                onChange={handleChange}
                                label="Colaborador"
                                size="small"
                                name="porCola"
                                inputProps={{ readOnly: true }}
                                value={formData?.colaboradores[0]?.porCola ?? ''}
                                sx={{ ...inputStyles, maxWidth: '40%', marginTop: '10px', marginLeft: '10px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">%</InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                onChange={handleChange}
                                label="Colaborador"
                                size="small"
                                name="totalCola"
                                inputProps={{ readOnly: true }}
                                value={formData?.colaboradores[0]?.totalCol?.toLocaleString('es-ES', numberOptions) ??''}
                                sx={{ ...inputStyles, maxWidth: '40%', marginTop: '10px', marginLeft: '10px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ paddingLeft: '1%' }} position="end">€</InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                    </Box>
                </Paper>
            </Grid>
        </>
    );
}

export default HojaRepartosForm;