import React, { useState } from "react";
import { useEffect } from "react";
import { Table, TableBody, TableRow, TableCell, tableCellClasses, styled } from '@mui/material';

const DatosPropiedad = ({property}) => {
    
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    // const [property, setProp]= useState({});
    // useEffect(()=>{
    //     setProp(props.property);
    // },[]);
    // console.log(property);
    // console.log(props);
    return ( 
    <>
        
        <div className="info-cliente">
            <Table>
                <TableBody>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                            <b>Referencia</b>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                            {property?.referencia}
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                            <b>Provincia</b>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                            {property?.provincia}
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                            <b>Ciudad</b>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                            {property?.ciudad}
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                            <b>Zona</b>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                            {property?.zona}
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                            <b>Código Postal</b>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                            {property?.cp}
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                            <b>Agente</b>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                            {property?.agente}
                        </StyledTableCell>
                    </StyledTableRow>
                   
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                            <b>Antiguedad</b>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                            {property?.antiguedad == 0 || property?.antiguedad == null ? '' : property?.antiguedad}
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                            <b>Precio Inmobiliaria</b>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                            {property?.precioinmo == 0 || property?.precioinmo == null ? '' : property?.precioinmo.toLocaleString('es-ES')+"€"}
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                            <b>MLS</b>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                            {property?.from_mls}
                        </StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        </div>
    </> );
}
 
export default DatosPropiedad;