import React, { useEffect, useState } from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import useGraficos from "../../hooks/useGraficos";


export default function QuesoGrafico({ preciosGraficos,total }) {
    const [highlightedItem, setHighlightedItem] = useState(null);
    useEffect(()=>{
        // console.log(preciosGraficos);
    },[ preciosGraficos])
    const size = {
        width: 500,
        height: 400,
    };
    return (
        // <div  style={{ overflowX: 'auto', maxWidth: '100%' }}>
        <div >
            {/* <h3>Total: {total?.toLocaleString('es-ES')} €</h3> */}
            {preciosGraficos?.length > 0 ? (
                <PieChart
                    // margin={{ top: 20, right: 20, bottom: 20, left: 0 }}
                    series={[
                        {
                            arcLabel: (item) => `${item.total}`,
                            arcLabelMinAngle: 20,
                            data: preciosGraficos,
                            highlightScope: { faded: 'global', highlighted: 'item' },
                            highlighted: highlightedItem !== null ? { dataIndex: highlightedItem } : {},
                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                        },
                    ]}
                    margin={{ top: 0, bottom: 0, left: 90, right: 0 }}
                    slotProps={{
                        legend: {

                            direction: 'column',
                            position: { vertical: 'middle', horizontal: 'right' },
                            padding: -120,
                            labelStyle: {
                                // cursor:"pointer",
                                // width:"auto",
                                // border:"1px solid red"
                            },
                            hidden: true
                        },
                    }}
                    sx={{
                        [`& .${pieArcLabelClasses.root}`]: {
                            fill: 'white',
                            fontWeight: 'bold',
                        },

                    }}
                    {...size}
                />

            ) : (
                <div>loading</div>
            )}
        </div>

    );
}
